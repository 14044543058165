.recipes-container {
    background-color: $secondary_01;
    padding-top: 150px;
    h2,
    h3,
    h4,
    span,
    p,
    a {
        color: $white;
    }
    input[type='checkbox'] {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        margin: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $white;
        border-radius: 50%;
        position: relative;
    }

    input[type='checkbox']::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: scale(0) translate(-50%, -50%);
        background-color: $white;
        transform-origin: bottom left;
        -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    input[type='checkbox']:checked {
        background-color: $black;
        border: 2px solid $black;
    }
    input[type='checkbox']:checked::before {
        transform: scale(1) translate(-50%, -50%);
    }
    input[type='checkbox']:disabled {
        border-color: $product_gray;
        cursor: not-allowed;
    }
    .recipes-hero-container {
        display: none;
        margin: 0 0 clamp(70px, 15.625vw, 300px);
        padding: 0 clamp(20px, 5.2083vw, 100px);
        .recipes-hero-wrapper {
            max-width: 1370px;
            width: 100%;
            margin: 0 auto;
            justify-content: space-between;
            align-items: center;
            .left {
                max-width: 530px;
                width: 100%;
                .info {
                    max-width: 380px;
                    margin: 0 0 80px;
                    .big_title {
                        display: inline-block;
                        margin: 0 0 30px;
                    }
                }
                .see-recipe-btn {
                    margin-right: clamp(45px, 3.3854vw, 65px);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 124px;
                    height: 124px;
                    border: 1px solid $white;
                    border-radius: 50%;
                }
                .link {
                    align-items: center;
                    .cooking-tip {
                        h4 {
                            margin: 0 0 20px;
                        }
                        max-width: 270px;
                        width: 100%;
                    }
                }
            }
            .mobile {
                display: none;
                &.see-recipe-btn {
                    display: none;
                }
            }
        }
    }
    .details {
        align-items: center;
        .time {
            margin: 0 20px;
        }
        span {
            display: flex;
            align-items: center;
            font-family: $main_font;
            svg {
                display: inline-block;
                margin: 0 10px 0 0;
            }
        }
    }
    .recipes-grid-container {
        padding: 0 clamp(20px, 5.2083vw, 100px);

        .recipes-grid-wrapper {
            max-width: 1370px;
            width: 100%;
            margin: 0 auto;
            justify-content: space-between;
            .blog_category_recipes_wrapper {
                scroll-margin-top: 100px;
            }
            .filters-container {
                max-width: 255px;
                width: 100%;
                margin-right: 50px;
                .filters {
                    border: 1px solid $white;
                    border-radius: 5px;
                    margin-bottom: 50px;
                    .title {
                        padding: 25px 40px;
                        background-color: $white;
                        p {
                            margin: 0;
                            color: $secondary_01;
                        }
                    }
                    .accordion-container {
                        .ac {
                            padding: 15px 30px;
                            margin: 0;
                            border: none;
                            background-color: unset;
                            box-sizing: border-box;
                            transition: background-color 0.4s ease;
                            &.is-active {
                                border-top: 1px solid $white;
                                border-bottom: 1px solid $white;
                                background-color: $secondary_01_dark;
                                .ac-header .ac-trigger::after {
                                    content: '\2013';
                                }
                            }
                            .ac-trigger {
                                font-family: neue-haas-grotesk-display, sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 2.2rem;
                                line-height: 145%;
                                padding: 0;
                                color: $white;
                                &:focus {
                                    color: $white;
                                }
                                &::after {
                                    content: '+';
                                    text-align: center;
                                    width: 15px;
                                    -webkit-transform: translate(0, -50%);
                                    transform: translate(0, -50%);
                                    position: unset;
                                    right: unset;
                                    top: unset;
                                    margin-left: 5px;
                                }
                            }
                            .ac-panel {
                                max-height: 370px;
                                overflow-y: auto;
                                &::-webkit-scrollbar {
                                    width: 6px;
                                }
                                &::-webkit-scrollbar-track {
                                    border-radius: 44px;
                                }
                                &::-webkit-scrollbar-thumb {
                                    background: #ffffff30;
                                    border-radius: 44px;
                                }
                                &::-webkit-scrollbar-thumb:hover {
                                    background: #ffffff60;
                                }
                                li {
                                    margin: 8px 0;
                                    display: flex;
                                    align-items: center;
                                    color: $white;
                                    label {
                                        margin: 0 0 0 10px;
                                        flex-basis: calc(100% - 30px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .sticky-grid {
                max-width: 800px;
                width: 100%;
            }
            .grid {
                justify-content: space-between;
                margin-left: 15px;
                .left,
                .right {
                    max-width: 310px;
                }
                .left {
                    margin-right: 15px;
                }
                .right {
                    padding-top: clamp(200px, 17.7083vw, 340px);
                    margin-left: 15px;
                }
                .recipe-slide {
                    max-width: 310px;
                    margin: 0 0 clamp(70px, 7.2917vw, 140px);
                }
            }
            .pagination {
                width: 100%;
            }
        }
    }
    .recipe-filters-btn {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 99;
        transition: transform 0.4s ease;
        #recipes-filter-btn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            background-color: $white;
            border-radius: 50%;
        }
        &.active {
            transform: scale(25);
            #recipes-filter-btn {
                path {
                    fill: none;
                }
            }
        }
    }
    .recipes-mobile-filters {
        position: fixed;
        top: 0;
        right: -130%;
        bottom: 0;
        z-index: 9999;
        height: 100vh;
        opacity: 0;
        transition: opacity 0.3s ease 0.2s;
        background-color: $secondary_01_dark;
        &.active {
            left: 0;
            opacity: 1;
            right: 0;
        }
        .header {
            padding: 25px 20px;
            background-color: $secondary_01;
            align-items: center;
            justify-content: space-between;
            #close-recipes-filter-btn {
                display: block;
            }
        }
        .filters-list {
            height: 80vh;
            overflow-y: scroll;
            padding-bottom: 40px;
        }
        .filter {
            padding: 0 20px;
            margin: 0 0 40px;
            input[type='checkbox'] {
                width: 30px;
                height: 30px;
                border: 1px solid $white;
            }
            input[type='checkbox']:checked {
                background-color: $black;
                border: 1px solid $black;
            }
            .title {
                padding: 10px 0;
                border-bottom: 1px solid $white;
                margin: 0 0 25px;
            }
            li {
                margin: 0 0 20px;
                display: flex;
                align-items: center;
                color: $white;
                label {
                    margin: 0 0 0 10px;
                }
            }
        }
        .submit-container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $white;
            padding: 25px;
            a {
                color: $black;
            }
        }
    }
}

@media (max-width: 991px) {
    .recipes-container {
        padding-top: 50px;
        .recipes-hero-container {
            padding: 0;
            .recipes-hero-wrapper {
                flex-direction: column-reverse;
                .left {
                    padding: 0 20px;
                    .info {
                        display: none;
                        &.mobile {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            max-width: unset;
                            .details {
                                span {
                                    margin: 0 0 15px;
                                }
                            }
                        }
                    }
                    .link {
                        .see-recipe-btn {
                            display: none;
                        }
                        .cooking-tip {
                            max-width: unset;
                        }
                    }
                }
                .right {
                    position: relative;
                    margin: 0 0 30px;
                    .mobile {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                        padding: 120px 20px 30px;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }
                }
                .mobile {
                    display: block;
                }
            }
        }
        .recipes-grid-container {
            .recipes-grid-wrapper {
                .filters-container {
                    display: none;
                }
                .grid,
                .sticky-grid {
                    margin: 0 auto;
                }
            }
        }
        .recipe-filters-btn {
            &.mobile {
                display: block;
            }
        }
    }
}
@media (max-width: 660px) {
    .recipes-container {
        .recipes-grid-container {
            .recipes-grid-wrapper {
                .grid {
                    align-items: center;
                    flex-direction: column;
                    .left {
                        margin: 0;
                    }
                    .right {
                        margin: 0;
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
