.section_cta {
    display: inline-flex;
    align-items: center;
    &::after {
        display: inline-block;
        content: '';
        background-image: url('../../../icons/arrow-right.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 55px;
        height: 55px;
        margin-left: 15px;
    }
    &.white {
        color: $white;
        &::after {
            background-image: url('../../../icons/arrow-right-white.svg');
        }
    }
}

.hide-overflow {
    overflow: hidden;
}
.slick-arrow {
    position: absolute;
    z-index: 9;
    top: 50%;
    cursor: pointer;
}
.prev-arrow {
    left: 30px;
}
.next-arrow {
    right: 30px;
}
.homepage-hero-container {
    margin: 0 0 clamp(85px, 9.1146vw, 175px);
    padding: 0 0 clamp(70px, 4.1667vw, 80px);
    position: relative;
    transition: background-color 1s ease;
    height: 100vh;
    overflow-x: hidden;
    .homepage-hero-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        .reveal-img {
            transition: opacity 1s ease;
            opacity: 1;
        }
        z-index: 2;
        &.deactivated {
            z-index: 1;
            .reveal-img {
                opacity: 0;
            }
            .text-container {
                opacity: 0 !important;
                width: 0;
            }
        }
    }
    .left {
        max-width: 805px;
        width: 100%;
        overflow: hidden;
    }
    .right {
        margin-left: -90px;
        justify-content: space-between;
        align-items: center;
        max-width: 1020px;
        width: 100%;
        padding: 0px 30px;
        .small-img {
            max-width: 365px;
            width: 100%;
            margin-right: 15px;
            overflow: hidden;
        }
        .text-container {
            max-width: 460px;
            width: 100%;
            // transition: opacity 0.5s ease-in-out;
            // opacity: 1;
            margin-left: 15px;
            h1 {
                margin: 0 0 clamp(20px, 2.0833vw, 40px);
            }
            p {
                margin: 0 0 20px;
                font-size: 1.8rem;
                font-weight: 500;
                line-height: 189%;
            }
        }
    }
    .homepage-hero-arrow {
        position: absolute;
        right: 135px;
        bottom: 145px;
        max-width: 125px;
        z-index: 2;
        a,
        svg {
            display: inline-block;
            max-width: 125px;
            width: 100%;
        }
    }
}

.homepage-discover-container {
    margin: 0 0 clamp(105px, 11.9792vw, 230px);
    padding: 0 30px;
    .homepage-discover-wrapper {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
    }
    .section-title {
        margin: 0 0 45px;
        h2,
        h3 {
            margin: 0;
        }
    }
    .brands {
        justify-content: space-between;
        align-items: center;
        position: relative;
        .discover-link-container {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            p {
                display: none;
                &.active {
                    display: inline;
                }
            }
        }
        .seperator {
            display: block;
            width: 1px;
            height: 255px;
            background-color: $black;
            margin: 0 clamp(50px, 7.0313vw, 135px);
        }
        a {
            display: block;
            position: relative;
            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                transition: all 0.4s ease;
            }
            img {
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.4s ease;
            }
            &:hover {
                img {
                    opacity: 1;
                    visibility: visible;
                }
            }
            &.discover-trea {
                g path {
                    fill: $black;
                }
                rect {
                    fill: none;
                }
                &:hover {
                    g path {
                        fill: $white;
                    }
                    rect {
                        fill: $black;
                    }
                }
            }
            &.discover-navarino {
                svg path {
                    fill: #675A4D;
                }
                &:hover {
                    svg path {
                        fill: $white;
                    }
                }
            }
        }
    }
}

.homepage-gifts-container {
    margin: 0 0 clamp(120px, 9.375vw, 180px);
    padding: 0 30px;
    .homepage-gifts-wrapper {
        align-items: center;
        justify-content: space-between;
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        .left {
            max-width: 390px;
            width: 100%;
            h2 {
                margin: 0 0 clamp(40px, 2.8646vw, 55px);
            }
            h3 {
                margin: 0;
            }
            p {
                margin: 0 0 40px;
            }
        }
        .right {
            .mobile {
                display: none;
            }
        }
    }
}

.homepage-explore-container {
    margin: 0 0 clamp(60px, 4.6875vw, 90px);
    padding: 0 30px;
    .homepage-explore-wrapper {
        max-width: 530px;
        width: 100%;
        margin: 0 auto;
        a {
            max-width: 280px;
            width: 100%;
            margin: 0 auto;
            display: block;
            svg {
                overflow: visible;
                display: block;
                width: 100%;
                margin: 0 0 clamp(80px, 6.25vw, 120px);
                #explore-ring {
                    -webkit-transform-origin: center;
                    transform-origin: center;
                    -webkit-animation: rotate-center 20s linear infinite;
                    animation: rotate-center 20s linear infinite;
                }
            }
        }
    }
}

.product-slide {
    padding: 25px 45px;
    border-right: 1px solid $product_gray;
    .top {
        align-items: center;
        justify-content: space-between;
        margin: 0 0 clamp(0px, 2.6042vw, 50px);

        div:first-child {
            flex-basis: 50%;
        }
        .main_button {
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 125px;
            height: 125px;
            border-radius: 50%;
            border: 1px solid $product_gray;
            background-color: $product_gray;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            @media (max-width: 375px) {
                width: 100%;
                max-width: 110px;
                height: 110px;
            }
            &:hover {
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
                background-color: $main;
                border-color: $main;
            }
        }
    }
    .thumb {
        a {
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            img {
                margin: 0 auto;
                mix-blend-mode: darken;
                object-fit: contain;
                min-height: 350px;
                max-height: 350px;
                height: auto;
            }
            &:hover {
                opacity: 0.8;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
            }
        }
    }
}

.products-slider-container {
    margin: 0 0 clamp(80px, 6.25vw, 120px);
    .products-slider {
        margin: 0 0 clamp(80px, 5.2083vw, 100px);
    }
    .products-slider-discover-more {
        justify-content: center;
    }
}

.homepage-black-marquee {
    background-color: $black;
    padding: 30px 0 40px;
    color: $white;
    overflow: hidden;
}

.homepage-logo-marquee {
    overflow: hidden;
    padding: 30px 0;
    .logo-marquee {
        align-items: center;
        gap: 60px;
        .logo-item {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }
}

.homepage-recipes-container {
    margin: 0 0 clamp(80px, 9.375vw, 180px);
    padding: clamp(80px, 9.375vw, 180px) 0 clamp(80px, 6.25vw, 120px);
    background-color: $secondary_01;
    color: $white;
    .top {
        max-width: 590px;
        width: 100%;
        margin: 0 auto clamp(30px, 7.2917vw, 140px);
        text-align: center;
        padding: 0 30px;
        h2 {
            color: $white;
            margin: 0 0 55px;
        }
    }
    .recipes-slider {
        margin: 0 0 clamp(80px, 6.25vw, 120px);
        @media (max-width: 767px) {
            .prev-arrow, .next-arrow {
                svg {
                    width: 75px;
                }
            }
            .prev-arrow {
                left: 0;
            }
            .next-arrow {
                right: 0;
            }
        }
        .slick-list {
            padding-top: 30px;
        }
        .recipe-slide {
            margin: 0 clamp(40px, 3.125vw, 60px);
            transition: margin 0.4s ease;
            &:hover {
                margin-top: -30px;
            }
            .thumb {
                overflow: hidden;
                &.active {
                    img {
                        transition: transform 0.4s ease;
                        transform: scale(1.1);
                    }
                }
                img {
                    transition: transform 0.4s ease;
                }
                a {
                    display: block;
                }
                margin: 0 0 25px;
            }
            .info {
                h3,
                a {
                    color: $white;
                    font-family: $main_font;
                    font-size: 2.2rem;
                    line-height: 32px;
                    .section_serif_title {
                        font-size: 2.2rem;
                        line-height: 32px;
                    }
                }
                .details {
                    align-items: center;

                    .time {
                        margin: 0 20px;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        font-family: $main_font;
                        font-size: 1.4rem;
                        line-height: 26px;
                        svg {
                            display: inline-block;
                            margin: 0 10px 0 0;
                        }
                    }
                }
            }
        }
    }
    .homepage-recipes-discover-more {
        justify-content: center;
        a {
            color: $white;
        }
    }
}

.homepage-awards-container {
    margin: 0 0 clamp(80px, 9.375vw, 180px);
    padding: 0 30px;
    .homepage-awards-wrapper {
        max-width: 1350px;
        margin: 0 auto;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .left {
            text-align: center;
            max-width: 490px;
            width: 100%;
            margin-right: 15px;
            h2 {
                margin: 0 0 clamp(40px, 3.125vw, 60px);
            }
            p {
                margin: 0 0 40px;
            }
        }
        .right {
            max-width: 670px;
            width: 100%;
            margin-left: 15px;
            .awards-item {
                align-items: center;
                justify-content: space-between;
                padding: 30px 45px;
                border-top: 1px solid $black;
                transition: background-color 0.4s ease;
                &:hover {
                    background-color: $main;
                    border-top: 1px solid $main;
                    .awards-item-right {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                .awards-item-left {
                    flex-basis: 80%;
                    h3 {
                        margin: 0;
                    }
                }
                .awards-item-right {
                    flex-basis: 20%;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity 0.4s ease;
                    img {
                        margin-left: auto;
                    }
                }
            }
            .awards-item:hover + .awards-item {
                border-top: 1px solid $main;
            }
        }
    }
    .mobile {
        display: none;
    }
}
.slick-dots {
    position: absolute;
    bottom: -50px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}
.slick-dots li button:before {
    font-size: 50px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
// responsive
@media (max-width: 1440px) {
    .homepage-hero-container {
        .left {
            max-width: 600px;
        }
        .right {
            margin-left: -60px;
            max-width: 700px;
        }
        .homepage-hero-arrow {
            right: 100px;
            bottom: 100px;
            max-width: 100px;
            a,
            svg {
                max-width: 100px;
            }
        }
    }
    .homepage-hero-container {
        .homepage-hero-wrapper {
            .right {
                .small-img {
                    max-width: 300px;
                }
            }
        }
    }
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
    .homepage-hero-container {
        .left {
            max-width: 560px;
        }
        .right {
            margin-left: -60px;
            .small-img {
                max-width: 280px;
            }
        }
        .homepage-hero-arrow {
            right: 80px;
            bottom: 100px;
            max-width: 100px;
            a,
            svg {
                max-width: 100px;
            }
        }
    }

    .homepage-discover-container {
        .homepage-discover-wrapper {
            max-width: 870px;
        }
        .brands {
            .discover-link-container {
                bottom: -20px;
            }
            a {
                max-width: 345px;
            }
        }
    }

    .homepage-gifts-container {
        .homepage-gifts-wrapper {
            max-width: 960px;
            .left {
                max-width: 350px;
            }
        }
    }

    .homepage-explore-container {
        .homepage-explore-wrapper {
            max-width: 350px;
        }
    }
    .homepage-awards-container {
        .homepage-awards-wrapper {
            max-width: 1050px;
            .left {
                max-width: 350px;
            }
            .right {
                max-width: 540px;
            }
        }
    }
}
@media (max-width: 1140px) {
}
@media (max-width: 1024px) {
}
@media (max-width: 991px) {
    .homepage-hero-container {
        .homepage-hero-wrapper {
            flex-direction: column;
            .right {
                padding: 0 20px;
                margin: -60px 0 0;
                flex-direction: column;
                .small-img {
                    margin: 0 0 35px auto;
                }
                .text-container {
                    .text-right {
                        text-align: left;
                    }
                }
            }
        }
        .homepage-hero-arrow {
            svg {
                circle {
                    display: none;
                }
            }
        }
    }

    .homepage-discover-container {
        padding: 0 20px;
        .homepage-discover-wrapper {
            .section-title {
                .text-center {
                    text-align: left;
                }
            }
            .brands {
                flex-direction: column;
                .left {
                    margin-bottom: 40px;
                }
                .seperator {
                    display: none;
                }
                a {
                    img {
                        opacity: 1;
                        visibility: visible;
                    }
                    &.discover-trea {
                        g path {
                            fill: $white;
                        }
                        rect {
                            fill: $black;
                        }
                    }
                    &.discover-navarino {
                        svg path {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }

    .homepage-gifts-container {
        padding: 0;
        .homepage-gifts-wrapper {
            flex-direction: column-reverse;
            .left {
                padding: 0 20px;
                max-width: unset;
            }
            .right {
                margin-bottom: 40px;
                .desktop {
                    display: none;
                }
                .mobile {
                    display: block;
                }
            }
        }
    }

    .homepage-explore-container {
        padding: 0 20px;
        .text-center {
            text-align: left;
        }
    }

    .homepage-recipes-container {
        .top {
            max-width: unset;
            text-align: left;
            padding: 0 20px;
        }
        .recipes-slider {
            .slick-list {
                padding-left: 20px;
            }
        }
    }

    .homepage-awards-container {
        padding: 0 20px;
        .homepage-awards-wrapper {
            flex-direction: column;
            .left {
                text-align: center;
                margin: 0 0 30px;
                .section_cta {
                    display: none;
                }
            }
            .right {
                max-width: 670px;
                margin: 0 0 30px;
                .awards-item {
                    padding: 40px 0px;
                    &:hover {
                        background-color: $main;
                        border-bottom: 1px solid $main;
                    }
                    .awards-item-right {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            .mobile {
                display: inline-flex;
            }
        }
    }
}
@media (max-width: 767px) {
    .homepage-discover-container {
        .brands {
            a.discover-trea {
                svg {
                    width: 160px;
                }
            }
            a.discover-navarino {
                svg {
                    width: 100px;
                }
            }
        }
    }

    .products-slider-container {
        .product-slide:nth-child(1n + 5) {
            display: none;
        }
    }

    .homepage-recipes-container {
        .recipes-slider {
            cursor: default;
        }
    }

    .homepage-awards-container {
        .homepage-awards-wrapper {
            .left {
                max-width: unset;
                text-align: left;
            }
            .right {
                .awards-item {
                    .awards-item-left {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
}
@media (max-width: 660px) {
    .homepage-hero-container {
        .homepage-hero-wrapper {
            .right {
                .small-img {
                    max-width: 160px;
                }
            }
        }
        .homepage-hero-arrow {
            right: 10px;
            bottom: 20px;
        }
    }
    .homepage-explore-container {
        .homepage-explore-wrapper {
            a {
                max-width: 220px;
            }
        }
    }

    .homepage-discover-container {
        .brands {
            padding: 0 50px;
        }
    }

}
@media (max-width: 480px) {
    .homepage-recipes-container {
        .recipes-slider {
            .recipe-slide {
                margin: 0 30px 0;
                .info {
                    .details {
                        span {
                            font-size: 1.2rem;
                        }
                        .time {
                            margin: 0 10px;
                        }
                    }
                }
            }
        }
    }
}

// keyframes
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
