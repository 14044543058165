.discover_section{
    .logo_container{
        bottom:50%;
        left:200px;
        transform: translateY(50%);
    }
    .logo_container:nth-of-type(2){
        bottom:51%;
        left:500px;
        transform: translateY(50%);
    }
    .button_container{
        bottom:50%;
        transform: translateY(50%);
        color:white;
        right:200px;
    }
}
@media(max-width:$tablet){
    .discover_section{
        height:400px;
        .logo_container{
            left:20px;
            bottom:30px;
            transform: translateY(0);
            svg{
                height:65px;
            }
        }
        .logo_container:nth-of-type(2){
            left:20px;
            bottom:125px;
            transform: translateY(0);
            svg{
                height:65px;
            }
        }
        .button_container{
            right:20px;
            bottom:30px;
            transform: translateY(0);
            .button_text{
                display: none;
            }
        }
    }
}