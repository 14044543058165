.quote_section{
    margin: 0 auto;
    padding-top:100px;
    margin-bottom:200px;
    text-align: center;
}
@media(max-width:$tablet){
    .quote_section{
        margin-bottom:100px;
        padding-top:50px;
    }
}

@media(max-width:$tablet-small){
    .quote_section{
        padding-top:0;
    }
}