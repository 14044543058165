.footer-container {
    position: relative;
    background-color: $dark_gray;
    .footer-wrapper {
        width: 100%;
        .main_button {
            color: $white;
            margin: 0 0 30px;
        }
        .left {
            padding: 65px clamp(20px, 5.2083vw, 100px);
            background-color: $light_gray;
        }
        .right {
            padding: 70px 30px 45px clamp(20px, 7.2917vw, 140px);
            .main_text {
                color: $white;
            }

            .footer-menu-container {
                margin: 0 0 clamp(40px, 4.6875vw, 90px);
                .footer-menu {
                    margin: 0 20px clamp(30px, 2.8646vw, 55px);
                    h4 {
                        margin: 0 0 25px;
                    }
                }
                .footer-information {
                    margin: 0 20px clamp(30px, 2.8646vw, 55px);
                }
                .footer-links {
                    max-width: 160px;
                    width: 100%;
                    a {
                        color: $footer_link;
                        &:hover {
                            color: $white;
                        }
                    }
                }
                .footer-social {
                    .footer-links {
                        align-items: center;
                        gap: 30px;
                    }
                }
            }
            .footer-icons {
                margin: 0 0 clamp(40px, 3.9063vw, 75px);
                .badges {
                    margin: 0 0 35px;
                    filter: grayscale(1);
                }
                .badges,
                .payment-logos {
                    max-width: 400px;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 30px;
                }
            }
        }
        .footer-links {
            padding: 0;
            list-style: none;
        }
        .footer-copyrights {
            p {
                margin: 0 20px 0 0;
            }
            .company_name {
                font-family: Georgia, serif;
                fill: $white;
            }
        }
    }
    .back-to-top {
        position: absolute;
        right: clamp(20px, 7.2917vw, 140px);
        bottom: clamp(45px, 2.8646vw, 55px);
        a {
            display: block;
        }
    }
}
.lity {
    z-index: 9999;
}
.lity-container {
    width: 90%;
    max-width: 530px;
}
.newsletter-popup {
    font-family: "Fedra Serif B Pro", sans-serif;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    background-color: #fff;
    text-align: center;
    &__image {
        object-fit: cover;
        object-position: top;
        height: 200px;
        width: 100%;
    }
    &__wrapper {
        padding: 20px 7px 7px 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    &__title {
        color: rgba(129, 129, 129, 0.5);
        margin: 0 0 15px;
        font-size: 20px;
        letter-spacing: 0.1em;
    }
    &__subtitle {
        color: #000;
        font-size: 24px;
        margin: 0;
        letter-spacing: 0.05em;
        max-width: 400px;
    }
    &__promo {
        color: #818181;
        font-size: 18px;
        margin: 0 0 25px;
        line-height: 30px;
    }
    &__separator {
        display: flex;
        align-items: center;
        margin: 25px 0;
        &__icon {
            display: flex;
            width: 20px;
            height: 20px;
            margin: 0 10px;
            svg {
                display: flex;
                width: 20px;
                height: 20px;
            }
        }
        &:after , &:before {
            content: '';
            display: flex;
            height: 1.5px;
            width: 40px;
            background-color: #81818180;
        }
    }
    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        label {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.025em;
        }
        input[type='text'] {
            border: none;
            border-bottom: 2px solid #81818180;
            border-radius: 0;
            margin: 10px 0 25px;
            font-size: 1.3rem;
            padding: 5px;
        }
        .newsletter-subscribe-btn {
            background: transparent;
            border: 1px solid #000;
            color: #000;
            padding: 10px 20px;
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: 600;
            cursor: pointer;
            transition: all .3s ease;
            &:hover {
                background-color: #000;
                color: #fff;
            }
        }
    }
    #subscribe_result {
        text-align: center;
        margin: 5px 0 0;
        span {
            background-color: transparent;
        }
        .alert-success {
            color: $black;
        }
    }
}

@media (max-width: 991px) {
    .footer-container {
        .footer-wrapper {
            flex-direction: column;
            .footer-newsletter {
                .text-center {
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #newsletter-popup img {
        max-width: 100%;
    }
}

@media (max-width: 660px) {
    .footer-container {
        .footer-wrapper {
            .footer-newsletter {
                .newsletter-submit-arrow svg,
                .newsletter-submit-arrow {
                    width: 55px;
                }
            }
            .right {
                .footer-menu-container {
                    margin: 0;
                    flex-direction: column;
                    .footer-links {
                        margin: 0 0 30px;
                    }
                }
                .footer-copyrights {
                    flex-direction: column;
                }
            }
        }
    }
}
.newsletter-heading {
    display: flex;
}
.newsletter-h3 {
    padding-top: 12px;
}
@media (max-width: 1280px){
.newsletter-h3 {
padding-top: 4px;
  }
}