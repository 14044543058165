.photo_text_section{
    margin-top:150px;
    margin-bottom:150px;
    max-width:1400px;
    display:flex;
    gap: 125px;
    align-items: center;
    div{
        display:flex;
        flex:1;
        justify-content: center;
        //align-items: center;
        flex-direction: column;
        .secondary_title{
            //text-align: left;
            max-width: 450px;
            align-self: center;
            margin-bottom:25px;
        }
        &.container-img {
            overflow: hidden;
            img {
                position: relative;
                transform: scale(1);
                top: 0;
            }
        }
    }
}

@media(max-width:$laptop){
    .photo_text_section{
        gap:125px;
    }
}

@media(max-width:$tablet){
    .photo_text_section{
        gap:50px;
        margin-top: 70px;
        margin-bottom:70px;
    }
}

@media(max-width:$tablet-small){
    .photo_text_section{
        flex-direction: column;
        div{
            .secondary_title{
                max-width: unset;
            }
        }
    }
}

.section-paragraph {
    text-align: left;

    &_title {
        margin-bottom: 30px;
        justify-self: unset;
    }
    &_text {
        font-weight: normal;
        p {
            font-weight: inherit;
            justify-self: unset;
            font-size: inherit;
            font-family: inherit;
            line-height: inherit;
        }
    }
}