.product-container {
  padding: 20px 0 70px;
  .product-row {
    align-items: center;
    padding: 0 clamp(0px, 17.188vw, 330px) 0;
    .left-col {
      flex-basis: 40%;
      margin-right: 15px;
      .prd-info {
        &.mobile-only {
          display: none;
        }
      }
    }
    .right-col {
      flex-basis: 60%;
      margin-left: 15px;
      .right-col-wrapper {
        max-width: 500px;
        .prd-info {
          &.desktop-only {
            display: block;
            .big_title {
              margin: 0 0 15px;
            }
            .secondary_title {
              margin-bottom: 0;
            }
          }
        }
        #product {
          .quantity-input-field {
            padding: 40px 0;
            .quantity {
              position: relative;
              max-width: 130px;
              display: flex;
              align-items: baseline;
              input[type="text"] {
                padding: 15px 10px;
                position: relative;
                //border: none;
                text-align: center;
                font-family: $main_font;
                font-style: normal;
                font-weight: 600;
                font-size: 2.2rem;
                line-height: 145%;
                -moz-box-shadow: none;
                -goog-ms-box-shadow: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                order: 2;
              }
                .quantity-button {
                  &.quantity-down {
                    padding: 15px 10px;
                    order: 1;
                  }
                  &.quantity-up {
                    padding: 15px 10px;
                    order: 3;
                  }
                  &:hover {
                    color: #B3B3B3;
                  }
              }
            }
          }
          .prd-buy {
            align-items: center;
            justify-content: space-between;
            .form-group {
              margin-bottom: 0;
              flex-basis: 35%;
              margin-right: 15px;
              #button-cart {
                width: 125px;
                height: 125px;
                border: 1px solid $product_gray;
                border-radius: 50%;
                background-color: $product_gray;
                &:hover {
                  border-color: $main;
                  background-color: $main;
                }
              }
            }
            .price-list {
              flex-basis: 35%;
              li {
                display: block;
                .product-price {
                  display: block;
                  &.old {
                    text-decoration: line-through;
                    font-size: 1.6rem;
                  }
                }
              }
            }
            .product-info-links {
              flex-basis: 35%;
              li {
                margin-bottom: 15px;
                a {
                  display: block;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

  }
  .awards-marquee {
    width: 100%;
    overflow: hidden;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: 20px 0;
    margin: 75px 0;
    .js-marquee {
      margin-right: 0!important;
      .awards-marquee-items {
        .awards-marquee-item {
          align-items: center;
          max-width: 225px;
          margin: 0 50px;
          .image {
            margin-right: 15px;
          }
        }
      }
    }
  }
  .awards-section {
    align-items: center;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: 20px 0;
    margin: 75px 0;
    justify-content: center;
    .awards-marquee-items {
      .awards-marquee-item {
        align-items: center;
        max-width: 225px;
        margin: 0 20px;
        .image {
          margin-right: 15px;
        }
      }
    }
  }
  .separator-line {
    display: inline-block;
    content: '';
    background-color: #000;
    width: 100%;
    height: 1px;
    margin: 30px 0 50px;
  }
  .tabs-row {
    padding: 0 clamp(0px, 17.188vw, 330px) 0;
    justify-content: flex-end;
    #product-tabs {
      flex-basis: 60%;
      margin-left: 15px;
      .product-tabs-headings {
        padding: 5px 0 45px;
        .tab-heading {
          position: relative;
          &.active {
            &:after {
              display: inline-block;
              content: '';
              background-color: $black;
              height: 1px;
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
          &+ .tab-heading {
            margin-left: 50px;
          }
        }
      }
      .tab-content {
        display: none;
        &.active {
          display: block;
          max-width: 530px;
        }
      }
    }
  }
}
.related-products {
  margin: 0;
  padding: 100px 0 180px;
  .big_title {
    text-align: center;
    margin: 0 0 100px;
  }
  .product-item {
    max-width: 405px;
    border-right: 1px solid $product_gray;
    .product-thumb {
      border: none;
      max-width: 345px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;

      .top {
          align-items: center;
          justify-content: space-between;
          padding: 30px 0;
          .info {
            width: 100%;
            max-width: 170px;
            margin-right: 5px;
            .secondary_title {
              display: block;
              min-height: 100px;
              margin-bottom: 10px;
            }
            .italic_text {
              margin: 0;
            }
          }
          .main_button {
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 125px;
            height: 125px;
            border-radius: 50%;
            border: 1px solid $product_gray;
            background-color: $product_gray;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            @media (max-width: 375px) {
              width: 100%;
              max-width: 110px;
              height: 110px;
            }
            &:hover {
              -webkit-transition: all .3s ease;
              -moz-transition: all .3s ease;
              -o-transition: all .3s ease;
              transition: all .3s ease;
              background-color: $main;
              border-color: $main;
            }
          }
        }


    }
  }
}
.popup-alert{
  &.alert {
    &.alert-success {
      position: fixed;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      margin-bottom: 0;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      animation: note 6500ms forwards;
      font-weight: 600;
      padding: 20px 30px;
      font-size: 1.3rem;
      z-index: 999;
      a {
        color: $gold;
      }
      &.alert-dismissible {
        .close{
          position: absolute;
          top: 5px;
          right: 8px;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .product-container {
    .product-row {
      padding: 20px 55px 0;
    }
  }
  .related-products {
    .product-item {
      .product-thumb {
        .product-link {
          .top {
            .info {
              .secondary_title {
                min-height: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .product-container {
    .product-row {
      padding: 0 clamp(0px, 5.208vw, 100px) 0;
    }
    .tabs-row {
      padding: 0 clamp(0px, 5.208vw, 100px) 0;
    }
  }
}

@media (max-width: 767px) {
  .product-container {
    .product-row {
      padding: 0 20px 0;
      .right-col {
        .right-col-wrapper {
          #product {
            .prd-buy {
              justify-content: unset;
              .form-group {
                margin-right: 15px;
              }
              .product-info-links {
                display: none;
              }
            }
          }
        }
      }
    }
    .awards-section {
      display: inline-block;
      justify-content: unset;
      align-items: unset;
      width: 100%;
      overflow: hidden;
      border-top: 1px solid $black;
      border-bottom: 1px solid $black;
      padding: 20px 0;
      margin: 70px 0;
      .js-marquee {
        margin-right: 0!important;
        .awards-marquee-items {
          .awards-marquee-item {
            align-items: center;
            max-width: 225px;
            margin: 0 20px;
            .image {
              margin-right: 15px;
            }
          }
        }
      }
    }
    .tabs-row {
      padding: 0 20px 0;
    }
  }
  .popup-alert {
    &.alert {
      &.alert-success {
        font-size: 1.4rem;
        width: 70%;
      }
    }
  }
}

@media (max-width: 630px) {
  .product-container {
    padding: 50px 0 70px;
    .product-row {
      flex-direction: column;
      .left-col {
        width: 100%;
        flex-basis: unset;
        margin-right: 0;
        .prd-info {
          &.mobile-only {
            display: block;
            text-align: center;
            h1.big_title {
              margin-top: 0;
            }
          }
        }
        .product-thumbnails {
          max-width: 420px;
          margin: 0 auto;
        }
      }
      .right-col {
        width: 100%;
        flex-basis: unset;
        margin-left: 0;
        .right-col-wrapper {
          max-width: 100%;
          .prd-info {
            &.desktop-only {
              display: none;
            }
          }
          #product {
            .prd-buy {
              max-width: 250px;
              margin: 20px auto 0;
              .price-list {
                flex-basis: 50%;
                order: 1;
              }
              .form-group {
                order: 2;
                display: flex;
                justify-content: flex-end;
                flex-basis: 50%;
                margin-right: 0;
                margin-left: 30px;
              }
              .product-info-links {
                display: none;
              }
            }
            .quantity-input-field {
              padding: 0;
              .quantity {
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
    .awards-marquee {
      margin: 70px 0;
      .js-marquee {
        .awards-marquee-items {
          .awards-marquee-item {
            margin: 0 20px;
          }
        }
      }
    }
    .separator-line {
      margin: 50px 0;
    }
    .tabs-row {
      #product-tabs {
        flex-basis: 100%;
        margin-left: 0;
        .product-tabs-headings {
          .tab-heading {
            &+.tab-heading {
              margin-left: 35px;
            }
          }
        }
      }
    }
  }
  .related-products {
    margin: 0;
    padding: 0 20px 70px;
    .big_title {
      text-align: left;
      padding: 0 0 80px;
      margin: 0;
    }
    .product-item {
      margin: 0 auto;
      border-right: none;
      .product-thumb {
        padding: 0;
        .product-link {
          .image {
            padding-bottom: 0;
          }
          .top {
            .info {
              .secondary_title {
                min-height: unset;
              }
            }
          }
        }
      }
    }
  }
}