.product_section{
    background-color: $white;
    padding-top:400px;
    padding-bottom: 125px;
    .product_container{
        .sidebar{
            flex:25%;
            justify-content: end;
            margin-left:250px;
            ul li{
                position:relative;
                display:block;
                width: fit-content;
                &::after{
                    content:" ";
                    background-size: cover;
                    width: 21px;
                    height: 24px;
                    position:absolute;
                    right:-30px;
                    transform:translateY(50%);
                    background-image: url('../../../icons/arrow-no-circle.svg');
                    opacity: 0;
                    transition: opacity .5s ease-out;
                }
                &:hover::after{
                    opacity: 1;
                }
            }
            .big_text{
                margin-bottom:25px;
            }
           
        }
    
           
        .product_list{
            position:relative;
            margin-left:150px;
            flex:75%;
            .product_list_title{
                position:absolute;
                top:-150px;
                left:50px;
                transform: translateY(-100%);
                .section_serif_title, .big_title{
                    line-height: 100%;
                }
            }
            .products{
                max-width:1000px;
                flex-wrap: wrap;
                .product-slide{
                    flex:50%;
                    padding: 0 50px 50px 50px;
                    margin-top:-25px;
                    &:nth-child(even){
                        border:none;
                    }
                    .price{
                        display:none;
                    }
                }
            }
            
        }
    }
    

    @media(max-width:$laptop)  {
        .product_container{
            .sidebar{
                margin-left:100px;
            }
            .product_list{
                margin-left: 100px;
            }
        }
    } 
    @media(max-width:$laptop-small)  {
        .product_container{
            .sidebar{
                margin-left:50px;
            }
            .product_list{
                margin-left: 50px;
            }
        }
    }
    @media(max-width:$tablet)  {
        .product_container{
            .product_list{
                .product_list_title{
                    left: 25px;
                }
                .products{
                    .product-slide{
                        flex:100%;
                        padding: 25px;
                        border:none;
                    }
                }
                
            }
        }
    }
    @media(max-width:$tablet-small)  {
        padding-top:75px;
        .product_list_title{
            margin-left:25px;
            .section_serif_title, .big_title{
                line-height: 65%;
            }
        }
        .product_container{
            flex-direction: column;
            .sidebar{
                border-top:1px solid #EAEAEA;
                border-bottom:1px solid #EAEAEA;
                padding:40px 0;
                margin:50px 25px 0 25px;
                flex:1;
                ul {
                    display:flex;
                    white-space: nowrap;
                    overflow-x: auto;
                    gap:30px;
                    li::after{
                        display:none;
                    }
                }
            }
            .product_list{
                margin-left: 0;
                flex:1;
                .products{
                    margin:25px;
                    .product-slide{
                        padding: 0px;
                        padding-top:50px;
                        
                    }
                }
            }
        }
        
    }
}