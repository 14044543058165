.page_who_we_are{
    background-color: $secondary_01;
    #canvas1 {
        width: 100%;
    }
    .full_width_img_section {
        position: relative;

        &>.container-img {
            &.overlay {
                &:before {
                    content:"";
                    background-color: rgba(34,34,34 ,0.5);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                }
            }
        }
        &.cursor-section {
            position: relative;
            .main_wrapper {
                position: absolute;
                top: 10%;
                left: 5%;
                width: 50%;
                .golden_letters {
                    position: unset;
                    top: unset;
                    left: unset;
                    width: unset;
                }
                .bubble-cursor {
                    position: absolute;
                    top: 0;
                    left: 0;
                    .mask-circle {
                        width: 100%;
                        max-width: 430px;
                        height: 430px;
                        svg {
                            width: 100%;
                            max-width: 430px;
                            height: 430px;
                            .st0 {
                                fill: $white;
                                stroke: $white;
                                stroke-miterlimit: 10;
                            }
                        }
                    }
                }
            }
            @media(max-width:1024px){
                .main_wrapper {
                    width: 95%;
                }
            }
            @media(max-width:767px){
                .main_wrapper {
                    position: relative;
                    background: #fff;
                    width: 100%;
                    left: 0;
                    top: 0;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    .golden_letters {
                        font-size: 1.8rem;
                        line-height: 2.6rem;
                    }
                }
            }
        }

    }

    .section-paragraph {
        text-align: left;

        &_title {
            margin-bottom: 30px;
        }
        &_text {
            font-weight: normal;
        }
    }
}
