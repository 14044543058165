.page_information {
  .container {
    padding: 7.552vw 20px;
    &.medium-container {
      max-width: 1090px;
      margin: 0 auto;
      h1.big_title {
        margin-bottom: 5.729vw;
        text-align: center!important;
      }
      p {
        margin-bottom: 15px;
      }
      h2, h3, h4 {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 17px;
        margin: 30px 0;
      }
    }
  }
  .financial_link {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-style: normal;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 630px) {
  .page_information {
    .container {
      padding: 50px 20px;
      #content {
        text-align: justify;
        &.medium-container {
          h1.big_title {
            margin-bottom: 35px;
          }
        }
      }
    }
  }
}