@charset "UTF-8";
.big_title {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 5.5rem;
  line-height: 120%;
}

.section_serif_title {
  font-family: "Fedra Serif B Pro", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 4rem;
  line-height: 132%;
}

.italic_text, .shipping-duties > *, .free-shipping-calculator > * {
  font-family: "Fedra Serif B Pro", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 189%;
}

.secondary_title {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 145%;
}

.main_button {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 103%;
}

.big_text {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 146%;
}

.details {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 172.5%;
}

.main_text,
p {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 172.5%;
}

.page_who_we_are .welcome_section p.main_text, .page_navarino .welcome_section p.main_text, .page_trea .welcome_section p.main_text {
  font-size: 1.8rem;
}

.golden_letters {
  font-family: "Fedra Serif B Pro", sans-serif;
  font-size: 2.6rem;
  line-height: 4.9rem;
  font-weight: 400;
  font-style: italic;
  color: #D3B675;
}

.golden_sans {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 165%;
  text-align: center;
  color: #D3B675;
}

.quote_text {
  font-family: Fedra Serif B Pro;
  font-style: italic;
  font-weight: normal;
  font-size: 7rem;
  line-height: normal;
}

.filter_txt {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 47px;
}

@media (max-width: 1280px) {
  .main_text {
    font-size: 1.3rem;
  }

  .big_title {
    font-size: 3.5rem;
  }

  .section_serif_title {
    font-size: 3rem;
  }

  .secondary_title {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  .quote_text {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 401px) {
  html {
    overflow-x: hidden;
  }
}

.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.center_abs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.reverse_row {
  flex-direction: row-reverse;
}

.main_wrapper {
  margin: 0 auto;
}

.flexy {
  display: flex;
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 1500px) {
  .main_wrapper {
    padding-left: 85px;
    padding-right: 85px;
  }
}
@media (max-width: 1024px) {
  .main_wrapper {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 768px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
body.menu-is-open {
  overflow: hidden;
}
body.menu-is-open #main-header {
  background-color: transparent;
}
body.menu-is-open #main-header .burger-menu {
  order: 1;
}
body.menu-is-open #main-header .burger-menu .burger-icon a .icon-bar {
  background-color: #000;
}
body.menu-is-open #main-header .account-items {
  order: 2;
  visibility: hidden;
  opacity: 0;
}
body.menu-is-open #main-header .logo {
  order: 3;
}
body.menu-is-open #main-header .logo .header-logo {
  justify-content: flex-end;
}
body.menu-is-open #main-header .logo .header-logo a .logo-icon {
  width: 162px;
  height: 19px;
}
body.menu-is-open #main-header .logo .header-logo a .logo-icon svg {
  width: 162px;
  height: 19px;
}
body.menu-is-open #main-header .logo .header-logo a .logo-icon svg .st0 {
  fill: #000;
}

#main-header {
  padding: 34px 135px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}
#main-header .burger-menu {
  flex-basis: 33.3333%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
#main-header .burger-menu .burger-icon {
  display: block;
  width: 100%;
  max-width: 65px;
  margin-right: 20px;
}
#main-header .burger-menu .burger-icon a .icon-bar {
  content: "";
  position: relative;
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: 4px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#main-header .burger-menu .burger-icon a .icon-bar.bottom {
  width: 50%;
}
#main-header .burger-menu .burger-icon.active a {
  display: block;
}
#main-header .burger-menu .burger-icon.active a .icon-bar.top {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  top: 8px;
}
#main-header .burger-menu .burger-icon.active a .icon-bar.middle {
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#main-header .burger-menu .burger-icon.active a .icon-bar.bottom {
  width: 100%;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  bottom: 2px;
}
#main-header .burger-menu .header-search {
  display: none;
}
@media (max-width: 991px) {
  #main-header .burger-menu .header-search.mobile-search {
    display: block;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-bar {
    position: relative;
    z-index: 99;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .search-icon {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .search-icon svg {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
}
@media (max-width: 991px) and (max-width: 421px) {
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .search-icon svg {
    width: 19px;
    height: 19px;
  }
}
@media (max-width: 991px) {
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .search-icon svg .st0 {
    fill: #fff;
  }
}
@media (max-width: 991px) {
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .close-search {
    display: none;
    width: 45px;
    height: 45px;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .close-search svg {
    display: inline-block;
    width: 45px;
    height: 45px;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-bar .close-search svg .st0 {
    fill: none;
    stroke: #000;
  }
}
@media (max-width: 991px) {
  #main-header .burger-menu .header-search.mobile-search .open-search-bar.active .search-icon {
    display: none;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-bar.active .close-search {
    display: inline-block;
  }
}
@media (max-width: 991px) {
  #main-header .burger-menu .header-search.mobile-search .open-search-input {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100%;
    top: 0;
    right: -100%;
    padding: 55px;
    background: #fff;
    z-index: 9;
    -webkit-transition: right 0.4s ease, opacity 0.3s ease;
    -moz-transition: right 0.4s ease, opacity 0.3s ease;
    -o-transition: right 0.4s ease, opacity 0.3s ease;
    transition: right 0.4s ease, opacity 0.3s ease;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active {
    visibility: visible;
    opacity: 1;
    right: 0;
    -webkit-transition: left 0.4s ease, opacity 0.3s ease;
    -moz-transition: left 0.4s ease, opacity 0.3s ease;
    -o-transition: left 0.4s ease, opacity 0.3s ease;
    transition: left 0.4s ease, opacity 0.3s ease;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border-bottom: 1px solid #000;
    opacity: 0.1;
    min-height: 50px;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search input[type=text] {
    border: none;
    box-shadow: none;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 36px;
    color: #000;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search input[type=text]::placeholder {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 36px;
    color: #000;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search input[type=text]:focus::placeholder {
    color: transparent;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results {
    width: 100%;
    margin-top: 50px;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .block-text {
    display: none;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link:hover {
    background-color: #F2F2F2;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link div {
    display: inline-block;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
    line-height: 145%;
    vertical-align: middle;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link div img {
    border: none;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link div .forkeydon {
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 145%;
    color: #000;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .no-results {
    display: block;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
    line-height: 145%;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search.active {
    opacity: 1;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search .search-btn {
    border: none;
    background-color: transparent;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search .search-submit {
    display: block;
    width: 47px;
    height: 24px;
  }
  #main-header .burger-menu .header-search.mobile-search .open-search-input.active #search .search-submit svg {
    display: block;
    width: 47px;
    height: 24px;
  }
}
#main-header .logo {
  flex-basis: 33.3333%;
}
#main-header .logo .header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
#main-header .logo .header-logo a {
  display: block;
}
#main-header .logo .header-logo a .logo-icon {
  display: block;
  width: 195px;
  height: 22px;
}
@media (max-width: 1200px) {
  #main-header .logo .header-logo a .logo-icon {
    width: 130px;
    height: 15px;
  }
}
#main-header .logo .header-logo a .logo-icon svg {
  display: inline-block;
  width: 195px;
  height: 22px;
}
@media (max-width: 1200px) {
  #main-header .logo .header-logo a .logo-icon svg {
    width: 130px;
    height: 15px;
  }
}
#main-header .logo .header-logo a .logo-icon svg .st0 {
  fill: #fff;
}
#main-header .account-items {
  flex-basis: 33.3333%;
}
#main-header .account-items .account-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#main-header .account-items .account-list li {
  margin-left: 25px;
}
#main-header .account-items .account-list li a {
  display: inline-block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 17px;
  color: #fff;
}
@media (max-width: 1200px) {
  #main-header .account-items .account-list li a {
    font-size: 1.3rem;
  }
}
#main-header .account-items .account-list li a.shop-online-icon {
  display: none;
}
#main-header .account-items .account-list li a.shop-online {
  border: 1px solid;
  padding: 10px;
  margin-bottom: 6px;
}
@media (max-width: 1200px) {
  #main-header .account-items .account-list li a.shop-online {
    padding: 5px;
  }
}
#main-header .account-items .account-list li a .account-icon-1 {
  display: inline-block;
  width: 24px;
  height: 23px;
}
#main-header .account-items .account-list li a .account-icon-1 svg {
  display: inline-block;
  width: 24px;
  height: 23px;
}
#main-header .account-items .account-list li a .account-icon-1 svg .cls-1 {
  fill: #fff;
}
#main-header .account-items .account-list li a .account-icon {
  display: inline-block;
  width: 19px;
  height: 23px;
}
#main-header .account-items .account-list li a .account-icon svg {
  display: inline-block;
  width: 19px;
  height: 23px;
}
#main-header .account-items .account-list li a .account-icon svg .st0 {
  fill: #fff;
}
#main-header .account-items .account-list li a.open-search-bar {
  position: relative;
  z-index: 99;
}
#main-header .account-items .account-list li a.open-search-bar .search-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
}
#main-header .account-items .account-list li a.open-search-bar .search-icon svg {
  display: inline-block;
  width: 22px;
  height: 22px;
}
#main-header .account-items .account-list li a.open-search-bar .search-icon svg .st0 {
  fill: #fff;
}
#main-header .account-items .account-list li a.open-search-bar .close-search {
  display: none;
  width: 45px;
  height: 45px;
}
#main-header .account-items .account-list li a.open-search-bar .close-search svg {
  display: inline-block;
  width: 45px;
  height: 45px;
}
#main-header .account-items .account-list li a.open-search-bar .close-search svg .st0 {
  fill: none;
  stroke: #000;
}
#main-header .account-items .account-list li a.open-search-bar.active .search-icon {
  display: none;
}
#main-header .account-items .account-list li a.open-search-bar.active .close-search {
  display: inline-block;
}
#main-header .account-items .account-list li .open-search-input {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  width: 100%;
  top: 0;
  right: -100%;
  padding: 55px;
  background: #fff;
  z-index: 9;
  -webkit-transition: right 0.4s ease, opacity 0.3s ease;
  -moz-transition: right 0.4s ease, opacity 0.3s ease;
  -o-transition: right 0.4s ease, opacity 0.3s ease;
  transition: right 0.4s ease, opacity 0.3s ease;
}
#main-header .account-items .account-list li .open-search-input.active {
  visibility: visible;
  opacity: 1;
  right: 0;
  -webkit-transition: left 0.4s ease, opacity 0.3s ease;
  -moz-transition: left 0.4s ease, opacity 0.3s ease;
  -o-transition: left 0.4s ease, opacity 0.3s ease;
  transition: left 0.4s ease, opacity 0.3s ease;
}
#main-header .account-items .account-list li .open-search-input.active #search {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  border-bottom: 1px solid #000;
  opacity: 0.1;
  min-height: 50px;
}
#main-header .account-items .account-list li .open-search-input.active #search input[type=text] {
  border: none;
  box-shadow: none;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 36px;
  color: #000;
}
#main-header .account-items .account-list li .open-search-input.active #search input[type=text]::placeholder {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 36px;
  color: #000;
}
#main-header .account-items .account-list li .open-search-input.active #search input[type=text]:focus::placeholder {
  color: transparent;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results {
  width: 100%;
  margin-top: 50px;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .block-text {
  display: none;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link:hover {
  background-color: #F2F2F2;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link div {
  display: inline-block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #000;
  line-height: 145%;
  vertical-align: middle;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link div img {
  border: none;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .result_block .result-link div .forkeydon {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 145%;
  color: #000;
}
#main-header .account-items .account-list li .open-search-input.active #search #d_ajax_search_results #d_ajax_search_results_body .no-results {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #000;
  line-height: 145%;
}
#main-header .account-items .account-list li .open-search-input.active #search.active {
  opacity: 1;
}
#main-header .account-items .account-list li .open-search-input.active #search .search-btn {
  border: none;
  background-color: transparent;
}
#main-header .account-items .account-list li .open-search-input.active #search .search-submit {
  display: block;
  width: 47px;
  height: 24px;
}
#main-header .account-items .account-list li .open-search-input.active #search .search-submit svg {
  display: block;
  width: 47px;
  height: 24px;
}
#main-header .account-items .account-list li #cart {
  margin-bottom: 0;
}
#main-header .account-items .account-list li #cart .dropdown-backdrop {
  z-index: 99;
}
#main-header .account-items .account-list li #cart .cart-btn {
  background: transparent;
  border: none;
  position: relative;
  padding: 0;
  box-shadow: none;
}
#main-header .account-items .account-list li #cart .cart-btn .cart-icon {
  display: block;
  width: 38px;
  position: relative;
}
#main-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
  display: block;
  width: 38px;
  fill: #fff;
  stroke: #fff;
  stroke-width: 0.2;
}
#main-header .account-items .account-list li #cart .cart-btn .cart-icon svg .st0 {
  fill: none;
  stroke: #fff;
}
#main-header .account-items .account-list li #cart .cart-btn .cart-icon svg .st1 {
  fill: #fff;
}
#main-header .account-items .account-list li #cart .cart-btn #cart-total {
  position: absolute;
  right: -8px;
  top: -10px;
  color: #fff;
}
#main-header .account-items .account-list li #cart .dropdown-menu {
  position: fixed;
  right: -100%;
  top: 0;
  z-index: 99;
  width: 100%;
  max-width: 500px;
  min-width: auto;
  height: 100%;
  background: #F2F2F2;
  border-radius: 0;
  margin: 0;
  display: block;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  border: 0;
  -webkit-transition: right 0.3s ease;
  -moz-transition: right 0.3s ease;
  -o-transition: right 0.3s ease;
  transition: right 0.3s ease;
}
#main-header .account-items .account-list li #cart .dropdown-menu .minicart-heading {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  padding: 55px 20px 55px 0;
}
#main-header .account-items .account-list li #cart .dropdown-menu .minicart-heading .main_button {
  font-size: 1.8rem;
  margin: 0;
  text-transform: uppercase;
}
#main-header .account-items .account-list li #cart .dropdown-menu .minicart-heading span.close-btn-icon {
  display: inline-block;
  margin-left: 15px;
  width: 15px;
  height: 15px;
}
#main-header .account-items .account-list li #cart .dropdown-menu .minicart-heading span.close-btn-icon svg {
  display: inline-block;
  width: 15px;
  height: 15px;
}
#main-header .account-items .account-list li #cart .dropdown-menu .minicart-heading span.close-btn-icon svg .st0 {
  fill: none;
  stroke: #000;
  stroke-width: 3;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products {
  max-height: calc(100vh - 385px);
  overflow-y: auto;
  padding: 0 30px;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products .section_serif_title {
  font-size: 2rem;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li {
  margin-left: 0;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products {
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-img {
  flex-basis: 100%;
  max-width: 125px;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-img img.img-thumbnail {
  border: none;
  background: #F2F2F2;
  mix-blend-mode: darken;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item {
  padding: 0 0 0 33px;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item a.prd-name {
  display: inline-block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 145%;
  color: #000;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item p.prd-price {
  font-family: "Fedra Serif B Pro", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 189%;
  margin: 0;
  color: #000;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item button.remove-cart-item {
  border: none;
  padding: 0;
  background: transparent;
  text-decoration: underline;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #F2F2F2;
  padding-top: 50px;
  z-index: 999999;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .table-of-totals {
  padding: 0 75px;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .table-of-totals .mini-cart-total {
  width: 100%;
  margin: 0 0 30px 0;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .table-of-totals .mini-cart-total td {
  color: #000;
  text-transform: uppercase;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .table-of-totals .mini-cart-total td .right {
  float: right;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .table-of-totals .mini-cart-total tbody tr:nth-child(2) {
  display: none;
}
#main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .mobile-bottom-cart .mini-cart-checkout a {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  background: #000;
  padding: 50px 15px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: #fff;
}
#main-header .account-items .account-list li #cart.open .dropdown-menu {
  right: 0;
  opacity: 1;
  visibility: visible;
}
#main-header.dark-header .burger-menu .burger-icon a .icon-bar {
  background-color: #fff;
}
#main-header.dark-header .logo a .logo-icon svg .st0 {
  fill: #fff;
}
#main-header.dark-header .account-items .account-list li a {
  color: #fff;
}
#main-header.dark-header .account-items .account-list li a .search-icon svg .st0 {
  fill: #fff;
}
#main-header.dark-header .account-items .account-list li a .account-icon svg .st0 {
  fill: #fff;
}
#main-header.dark-header .account-items .account-list li a .account-icon-1 svg .cls-1 {
  fill: #fff;
}
#main-header.dark-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
  fill: #fff;
  stroke: #fff;
  stroke-width: 0.2;
}
#main-header.dark-header .account-items .account-list li #cart .cart-btn .cart-icon svg .st0 {
  stroke: #fff;
}
#main-header.dark-header .account-items .account-list li #cart .cart-btn #cart-total {
  color: #fff;
}
#main-header.sticky-header {
  position: fixed;
  padding: 20px 135px;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
#main-header.sticky-header .burger-menu .burger-icon a .icon-bar {
  background-color: #fff;
}
#main-header.sticky-header .logo a .logo-icon svg .st0 {
  fill: #fff;
}
#main-header.sticky-header .account-items .account-list li a {
  color: #fff;
}
#main-header.sticky-header .account-items .account-list li a .account-icon svg .st0 {
  fill: #fff;
}
#main-header.sticky-header .account-items .account-list li a .account-icon-1 svg .cls-1 {
  fill: #fff !important;
}
#main-header.sticky-header .account-items .account-list li #cart .cart-btn .cart-icon svg .st0 {
  stroke: #fff;
}
#main-header.sticky-header .account-items .account-list li #cart .cart-btn #cart-total {
  color: #fff;
}
#main-header.sticky-header .account-items .account-list li.header-search .search-icon svg .st0 {
  fill: #fff;
}

.custom_menu {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: -100%;
  z-index: 99;
  top: 0;
  -webkit-transition: left 0.3s ease;
  -moz-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  transition: left 0.3s ease;
}
.custom_menu.active {
  opacity: 1;
  visibility: visible;
  display: block;
  left: 0;
  z-index: 999;
}
.custom_menu .custom_menu_body {
  height: 100%;
}
.custom_menu .custom_menu_body .left {
  flex-basis: 50%;
  background-color: #FFECC3;
  padding: 136px 35px;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_menu .custom_menu_body .left .close-menu-icon {
  display: inline-block;
  width: 46px;
  height: 46px;
  cursor: pointer;
}
.custom_menu .custom_menu_body .left .close-menu-icon svg {
  display: inline-block;
  width: 46px;
  height: 46px;
}
.custom_menu .custom_menu_body .left .close-menu-icon svg .st0 {
  fill: none;
  stroke: #000;
}
.custom_menu .custom_menu_body .left .custom_menu_items {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul {
  text-align: center;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li {
  margin: 15px 0;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .shop-all {
  display: inline-block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 51px;
  text-align: center;
  color: #000;
  margin-top: 30px;
  position: relative;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .shop-all:after {
  display: inline-block;
  content: "";
  height: 2px;
  width: 100%;
  background-color: #000;
  position: absolute;
  bottom: 1px;
  left: 0;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item {
  display: inline-block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 51px;
  text-align: center;
  color: #000;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  position: relative;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item.active {
  font-family: "Fedra Serif B Pro", sans-serif;
  color: #D3B675;
  font-weight: 500;
  font-style: italic;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item.active:before {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item.active span.menu-arrow {
  font-family: neue-haas-grotesk-display, sans-serif;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item.active span.menu-arrow svg .st0 {
  fill: #D3B675;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item:before {
  display: inline-block;
  width: 21px;
  height: 24px;
  content: "";
  background-image: url("../../../icons/menu-arrow.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 20px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  top: 50%;
  left: -40px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item:hover:before {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item:hover span.menu-arrow svg .st0 {
  fill: #D3B675;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item span.menu-arrow svg {
  vertical-align: middle;
  width: 12px;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item span.menu-arrow svg .st0 {
  fill: #000;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .sub-menu {
  display: none;
  transition: opacity 200ms, display 200ms;
  opacity: 0;
  padding-left: 100px;
  text-align: left;
  margin: 15px 0;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .sub-menu li {
  margin: 0;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .sub-menu li .custom_menu_item {
  font-size: 3rem;
}
.custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item.active-sub-menu + .sub-menu {
  display: block;
  opacity: 1;
}
.custom_menu .custom_menu_body .right {
  flex-basis: 50%;
  background-color: #fff;
  padding: 136px 35px;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items {
  width: 100%;
  max-width: 560px;
  margin: 40px auto 0;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block {
  display: none;
  text-align: center;
  position: relative;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern {
  position: relative;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern .menu_item_pattern {
  display: block;
  width: 100%;
  max-width: 560px;
  position: relative;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern .menu_item_pattern svg {
  display: block;
  width: 100%;
  max-width: 560px;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern .menu_item_pattern svg .st0 {
  fill: transparent;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern .menu_item_pattern.enable svg .st0 {
  fill: #F2F2F2;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern p.secondary_title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 410px;
  margin-bottom: 0;
  text-align: justify;
}
.custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block.active {
  display: block;
}

@media (min-width: 767px) {
  .page_checkout #main-header {
    background-color: #fff;
  }
  .page_checkout #main-header .burger-menu .burger-icon a .icon-bar {
    background-color: #000;
  }
  .page_checkout #main-header .logo a .logo-icon svg .st0 {
    fill: #000;
  }
  .page_checkout #main-header .account-items .account-list li a {
    color: #000;
  }
  .page_checkout #main-header .account-items .account-list li a .account-icon svg .st0 {
    fill: #000;
  }
  .page_checkout #main-header .account-items .account-list li a .search-icon svg .st0 {
    fill: #000;
  }
  .page_checkout #main-header .account-items .account-list li #cart .cart-btn #cart-total {
    color: #000;
  }
  .page_checkout #main-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
    fill: #000;
    stroke: #000;
  }
  .page_checkout #main-header.sticky-header {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .page_checkout #main-header.sticky-header .burger-menu .burger-icon a .icon-bar {
    background-color: #fff;
  }
  .page_checkout #main-header.sticky-header .logo a .logo-icon svg .st0 {
    fill: #fff;
  }
  .page_checkout #main-header.sticky-header .account-items .account-list li a {
    color: #fff;
  }
  .page_checkout #main-header.sticky-header .account-items .account-list li a .account-icon svg .st0 {
    fill: #fff;
  }
  .page_checkout #main-header.sticky-header .account-items .account-list li a .search-icon svg .st0 {
    fill: #fff;
  }
  .page_checkout #main-header.sticky-header .account-items .account-list li #cart .cart-btn #cart-total {
    color: #fff;
  }
  .page_checkout #main-header.sticky-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
    fill: #fff;
    stroke: #fff;
  }
}
@media (max-width: 1366px) {
  #main-header {
    padding: 32px 85px;
  }
  #main-header.sticky-header {
    padding: 20px 85px;
  }
}
@media (max-width: 1280px) {
  #main-header {
    padding: 30px 55px;
  }
  #main-header.sticky-header {
    padding-left: 55px;
    padding-right: 55px;
  }
}
@media (max-width: 1140px) {
  #main-header {
    padding: 35px;
  }
  #main-header.sticky-header {
    padding: 20px 35px;
  }
}
@media (max-width: 1024px) {
  .custom_menu .custom_menu_body .left .close-menu-icon svg {
    width: 36px;
    height: 36px;
  }
  .custom_menu .custom_menu_body .left .custom_menu_items ul li {
    margin: 5px 0;
  }
  .custom_menu .custom_menu_body .left .custom_menu_items ul li .custom_menu_item {
    font-size: 3rem;
    line-height: 50px;
  }
  .custom_menu .custom_menu_body .left .custom_menu_items ul li .sub-menu {
    margin: 5px 0;
  }
  .custom_menu .custom_menu_body .left .custom_menu_items ul li .sub-menu li {
    margin: 0;
  }
  .custom_menu .custom_menu_body .left .custom_menu_items ul li .sub-menu li .custom_menu_item {
    font-size: 2.5rem;
  }
  .custom_menu .custom_menu_body .left .custom_menu_items ul li .shop-all {
    font-size: 2.8rem;
    line-height: 45px;
    margin-top: 25px;
  }
  .custom_menu .custom_menu_body .right .custom_menu_block_items .custom_menu_block .menu_with_pattern p.secondary_title {
    max-width: 310px;
    font-size: 1.8rem;
  }
}
@media (max-width: 991px) {
  #main-header .account-items .account-list li {
    margin-left: 20px;
  }
  #main-header .account-items .account-list li.header-search, #main-header .account-items .account-list li.header-sustainability {
    display: none;
  }
  #main-header .account-items .account-list li a.shop-online-icon {
    display: block;
  }
  #main-header .account-items .account-list li a.shop-online {
    display: none;
  }
}
@media (max-width: 767px) {
  body.menu-is-open #main-header .logo {
    order: 2;
  }
  body.menu-is-open #main-header .logo .header-logo {
    justify-content: center;
  }
  body.menu-is-open #main-header .logo .header-logo a .logo-icon {
    display: block;
    width: 130px;
    height: 15px;
  }
  body.menu-is-open #main-header .logo .header-logo a .logo-icon svg {
    display: block;
    width: 130px;
    height: 15px;
  }
  body.menu-is-open #main-header .account-items {
    order: 3;
  }

  #main-header {
    padding: 30px 20px;
  }
  #main-header.sticky-header {
    padding: 20px;
  }
  #main-header .burger-menu .burger-icon {
    max-width: 44px;
    margin-right: 10px;
  }
  #main-header .account-items .account-list li {
    margin-left: 15px;
  }
  #main-header .account-items .account-list li a.open-search-bar .close-search {
    width: 32px;
    height: 32px;
  }
  #main-header .account-items .account-list li a.open-search-bar .close-search svg {
    width: 32px;
    height: 32px;
  }
  #main-header .account-items .account-list li .open-search-input.active {
    padding: 130px 30px;
  }
  #main-header .account-items .account-list li .open-search-input.active #search input[type=text] {
    font-size: 1.8rem;
  }
  #main-header .account-items .account-list li .open-search-input.active #search input[type=text]::placeholder {
    font-size: 1.8rem;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon {
    width: 30px;
    height: 30px;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
    width: 30px;
    height: 30px;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon svg .st0 {
    fill: none;
    stroke: #000;
  }
  #main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products {
    padding: 0 10px;
  }
  #main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .product-img {
    max-width: 100px;
  }
  #main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item {
    padding: 0 0 0 15px;
  }
  #main-header .account-items .account-list li #cart .dropdown-menu .all-products-w-button .scrollable-products li .mini-cart-products .prd-item a.prd-name {
    font-size: 1.8rem;
  }

  .custom_menu .custom_menu_body .left {
    flex-basis: 100%;
  }
  .custom_menu .custom_menu_body .right {
    display: none;
  }
}
@media (max-width: 421px) {
  #main-header .account-items .account-list li {
    margin-left: 10px;
    position: relative;
    top: 2px;
  }
  #main-header .account-items .account-list li a.open-search-bar .search-icon {
    width: 18px;
    height: 18px;
  }
  #main-header .account-items .account-list li a.open-search-bar .search-icon svg {
    width: 18px;
    height: 18px;
  }
  #main-header .account-items .account-list li a.shop-online-icon span {
    width: 19px;
    height: 24px;
  }
  #main-header .account-items .account-list li a.shop-online-icon span svg {
    width: 19px;
    height: 24px;
  }
  #main-header .account-items .account-list li a .account-icon {
    width: 15px;
    height: 19px;
  }
  #main-header .account-items .account-list li a .account-icon svg {
    width: 15px;
    height: 19px;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon {
    width: 26px;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
    width: 26px;
  }

  .logo .header-logo a .logo-icon {
    width: 120px;
    height: 15px;
  }
  .logo .header-logo a .logo-icon svg {
    width: 120px;
    height: 15px;
  }

  .custom_menu .custom_menu_body .left .close-menu-icon {
    width: 32px;
    height: 32px;
  }
  .custom_menu .custom_menu_body .left .close-menu-icon svg {
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 320px) {
  #main-header .burger-menu a .burger-icon {
    width: 45px;
  }
  #main-header .burger-menu a .burger-icon svg {
    width: 45px;
  }
  #main-header .account-items .account-list li {
    margin-left: 10px;
  }
  #main-header .account-items .account-list li a .search-icon {
    width: 19px;
    height: 19px;
  }
  #main-header .account-items .account-list li a .search-icon svg {
    width: 19px;
    height: 19px;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon {
    width: 25px;
    height: 25px;
  }
  #main-header .account-items .account-list li #cart .cart-btn .cart-icon svg {
    width: 25px;
    height: 25px;
  }
  #main-header .logo .header-logo a .logo-icon {
    width: 110px;
  }
  #main-header .logo .header-logo a .logo-icon svg {
    width: 110px;
  }
}
.discount-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px 15px;
  background-color: #fff;
  color: #000;
  font-size: 1.3rem;
  line-height: 1.3;
  z-index: 10000;
}

body.has-promo-text {
  margin-top: 36px;
}
body.has-promo-text #main-header {
  top: 36px;
}
body.menu-is-open {
  margin-top: 0;
}
body.menu-is-open #main-header {
  top: 0;
}
body.menu-is-open .discount-bar {
  opacity: 0;
  pointer-events: none;
}

.free-shipping-calculator {
  background-color: #DFDFDF;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 15px 5px;
  text-align: center;
}
.free-shipping-calculator > * {
  font-size: 1.2rem;
  color: #000;
  margin: 0;
}

.shipping-duties {
  background-color: #DFDFDF;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 15px 10px;
  text-align: left;
}
.shipping-duties > * {
  font-size: 1.2rem;
  color: #000;
  margin: 0;
}

#sustainability .secondary_title, #product_lines .secondary_title {
  text-align: center;
}

.page_category_usa .li-shop-online {
  display: none;
}

.footer-container {
  position: relative;
  background-color: #1B1B1B;
}
.footer-container .footer-wrapper {
  width: 100%;
}
.footer-container .footer-wrapper .main_button {
  color: #fff;
  margin: 0 0 30px;
}
.footer-container .footer-wrapper .left {
  padding: 65px clamp(20px, 5.2083vw, 100px);
  background-color: #F2F2F2;
}
.footer-container .footer-wrapper .right {
  padding: 70px 30px 45px clamp(20px, 7.2917vw, 140px);
}
.footer-container .footer-wrapper .right .main_text {
  color: #fff;
}
.footer-container .footer-wrapper .right .footer-menu-container {
  margin: 0 0 clamp(40px, 4.6875vw, 90px);
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-menu {
  margin: 0 20px clamp(30px, 2.8646vw, 55px);
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-menu h4 {
  margin: 0 0 25px;
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-information {
  margin: 0 20px clamp(30px, 2.8646vw, 55px);
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-links {
  max-width: 160px;
  width: 100%;
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-links a {
  color: #818181;
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-links a:hover {
  color: #fff;
}
.footer-container .footer-wrapper .right .footer-menu-container .footer-social .footer-links {
  align-items: center;
  gap: 30px;
}
.footer-container .footer-wrapper .right .footer-icons {
  margin: 0 0 clamp(40px, 3.9063vw, 75px);
}
.footer-container .footer-wrapper .right .footer-icons .badges {
  margin: 0 0 35px;
  filter: grayscale(1);
}
.footer-container .footer-wrapper .right .footer-icons .badges,
.footer-container .footer-wrapper .right .footer-icons .payment-logos {
  max-width: 400px;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
}
.footer-container .footer-wrapper .footer-links {
  padding: 0;
  list-style: none;
}
.footer-container .footer-wrapper .footer-copyrights p {
  margin: 0 20px 0 0;
}
.footer-container .footer-wrapper .footer-copyrights .company_name {
  font-family: Georgia, serif;
  fill: #fff;
}
.footer-container .back-to-top {
  position: absolute;
  right: clamp(20px, 7.2917vw, 140px);
  bottom: clamp(45px, 2.8646vw, 55px);
}
.footer-container .back-to-top a {
  display: block;
}

.lity {
  z-index: 9999;
}

.lity-container {
  width: 90%;
  max-width: 530px;
}

.newsletter-popup {
  font-family: "Fedra Serif B Pro", sans-serif;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background-color: #fff;
  text-align: center;
}
.newsletter-popup__image {
  object-fit: cover;
  object-position: top;
  height: 200px;
  width: 100%;
}
.newsletter-popup__wrapper {
  padding: 20px 7px 7px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.newsletter-popup__title {
  color: rgba(129, 129, 129, 0.5);
  margin: 0 0 15px;
  font-size: 20px;
  letter-spacing: 0.1em;
}
.newsletter-popup__subtitle {
  color: #000;
  font-size: 24px;
  margin: 0;
  letter-spacing: 0.05em;
  max-width: 400px;
}
.newsletter-popup__promo {
  color: #818181;
  font-size: 18px;
  margin: 0 0 25px;
  line-height: 30px;
}
.newsletter-popup__separator {
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.newsletter-popup__separator__icon {
  display: flex;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}
.newsletter-popup__separator__icon svg {
  display: flex;
  width: 20px;
  height: 20px;
}
.newsletter-popup__separator:after, .newsletter-popup__separator:before {
  content: "";
  display: flex;
  height: 1.5px;
  width: 40px;
  background-color: #81818180;
}
.newsletter-popup__form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.newsletter-popup__form label {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.025em;
}
.newsletter-popup__form input[type=text] {
  border: none;
  border-bottom: 2px solid #81818180;
  border-radius: 0;
  margin: 10px 0 25px;
  font-size: 1.3rem;
  padding: 5px;
}
.newsletter-popup__form .newsletter-subscribe-btn {
  background: transparent;
  border: 1px solid #000;
  color: #000;
  padding: 10px 20px;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}
.newsletter-popup__form .newsletter-subscribe-btn:hover {
  background-color: #000;
  color: #fff;
}
.newsletter-popup #subscribe_result {
  text-align: center;
  margin: 5px 0 0;
}
.newsletter-popup #subscribe_result span {
  background-color: transparent;
}
.newsletter-popup #subscribe_result .alert-success {
  color: #000;
}

@media (max-width: 991px) {
  .footer-container .footer-wrapper {
    flex-direction: column;
  }
  .footer-container .footer-wrapper .footer-newsletter .text-center {
    text-align: left;
  }
}
@media (max-width: 767px) {
  #newsletter-popup img {
    max-width: 100%;
  }
}
@media (max-width: 660px) {
  .footer-container .footer-wrapper .footer-newsletter .newsletter-submit-arrow svg,
.footer-container .footer-wrapper .footer-newsletter .newsletter-submit-arrow {
    width: 55px;
  }
  .footer-container .footer-wrapper .right .footer-menu-container {
    margin: 0;
    flex-direction: column;
  }
  .footer-container .footer-wrapper .right .footer-menu-container .footer-links {
    margin: 0 0 30px;
  }
  .footer-container .footer-wrapper .right .footer-copyrights {
    flex-direction: column;
  }
}
.newsletter-heading {
  display: flex;
}

.newsletter-h3 {
  padding-top: 12px;
}

@media (max-width: 1280px) {
  .newsletter-h3 {
    padding-top: 4px;
  }
}
.photo_text_section {
  margin-top: 150px;
  margin-bottom: 150px;
  max-width: 1400px;
  display: flex;
  gap: 125px;
  align-items: center;
}
.photo_text_section div {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.photo_text_section div .secondary_title {
  max-width: 450px;
  align-self: center;
  margin-bottom: 25px;
}
.photo_text_section div.container-img {
  overflow: hidden;
}
.photo_text_section div.container-img img {
  position: relative;
  transform: scale(1);
  top: 0;
}

@media (max-width: 1500px) {
  .photo_text_section {
    gap: 125px;
  }
}
@media (max-width: 1024px) {
  .photo_text_section {
    gap: 50px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
@media (max-width: 768px) {
  .photo_text_section {
    flex-direction: column;
  }
  .photo_text_section div .secondary_title {
    max-width: unset;
  }
}
.section-paragraph {
  text-align: left;
}
.section-paragraph_title {
  margin-bottom: 30px;
  justify-self: unset;
}
.section-paragraph_text {
  font-weight: normal;
}
.section-paragraph_text p {
  font-weight: inherit;
  justify-self: unset;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.full_width_img_section {
  position: relative;
}
.full_width_img_section .golden_letters {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 50%;
}

@media (max-width: 768px) {
  .full_width_img_section {
    background-color: #fff;
  }
  .full_width_img_section div {
    height: auto;
  }
  .full_width_img_section .golden_letters {
    position: static;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
  }
}
.welcome_section {
  max-width: 800px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
  margin: 0 auto;
}
.welcome_section .big_title {
  margin: unset;
  margin-bottom: 35px;
}
.welcome_section .main_text {
  margin: 0 auto;
  text-align: justify;
}

@media (max-width: 768px) {
  .welcome_section {
    padding-left: unset;
    padding-right: unset;
    margin-left: 25px;
    margin-right: 25px;
  }
  .welcome_section .big_title svg {
    height: 30px;
  }
}
.our_values_section {
  text-align: center;
  margin-bottom: 200px;
}
.our_values_section svg {
  height: fit-content;
  max-width: 720px;
}
.our_values_section .center_abs .small_border {
  width: 75px;
  height: 1px;
  background-color: #000;
  margin: 20px auto;
  border: none;
}
.our_values_section .center_abs p {
  margin: unset;
}
.our_values_section .center_abs .italic_text, .our_values_section .center_abs .free-shipping-calculator > *, .our_values_section .center_abs .shipping-duties > * {
  max-width: 350px;
  margin: 0 auto;
}
.our_values_section .golden_sans {
  display: none;
  margin-top: 20px;
  word-spacing: 30px;
}

@media (max-width: 1500px) {
  .our_values_section {
    margin-bottom: 100px;
    padding-top: 50px;
  }
}
@media (max-width: 768px) {
  .our_values_section svg {
    display: none;
  }
  .our_values_section .center_abs {
    position: static;
    transform: translate(0, 0);
  }
  .our_values_section .golden_sans {
    display: block;
    line-height: normal;
  }
}
.marquee {
  user-select: none;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.marquee--draggable {
  cursor: grab;
}
.marquee--dragging {
  cursor: grabbing;
}
.marquee__slide {
  flex-shrink: 0;
  position: relative;
  will-change: left;
  display: flex;
  align-items: center;
}
.marquee__item {
  flex-shrink: 0;
}

.header-slider {
  width: 100%;
}

.header-slider-section {
  margin-bottom: 150px;
}
.header-slider-section .marquee__item {
  /* Default values for below 360px */
  width: 100vw;
  margin-right: 90px;
}
@media screen and (min-width: 360px) {
  .header-slider-section .marquee__item {
    width: 50vw;
    margin-right: 90px;
    /* This stays the same */
  }
}
@media screen and (min-width: 768px) {
  .header-slider-section .marquee__item {
    width: 33.33vw;
    margin-right: 100px;
    /* Updated value */
  }
}
@media screen and (min-width: 1280px) {
  .header-slider-section .marquee__item {
    width: 25vw;
    margin-right: 130px;
    /* Updated value */
  }
}
@media screen and (min-width: 1500px) {
  .header-slider-section .marquee__item {
    width: 20vw;
    margin-right: 150px;
    /* Updated value */
  }
}
.header-slider-section .marquee__item {
  overflow: hidden;
  width: 100% !important;
}
.header-slider-section .marquee__item:nth-child(odd) {
  max-width: 260px;
  margin-top: 200px;
}
.header-slider-section .marquee__item:nth-child(even) {
  max-width: 330px;
  margin-bottom: auto;
}
@media (max-width: 1280px) {
  .header-slider-section .marquee__item:nth-child(odd) {
    max-width: 180px;
    margin-top: 150px;
  }
  .header-slider-section .marquee__item:nth-child(even) {
    max-width: 230px;
  }
}

@media (max-width: 1024px) {
  .header-slider-section {
    margin-bottom: 100px;
  }
}
.discover_section .logo_container {
  bottom: 50%;
  left: 200px;
  transform: translateY(50%);
}
.discover_section .logo_container:nth-of-type(2) {
  bottom: 51%;
  left: 500px;
  transform: translateY(50%);
}
.discover_section .button_container {
  bottom: 50%;
  transform: translateY(50%);
  color: white;
  right: 200px;
}

@media (max-width: 1024px) {
  .discover_section {
    height: 400px;
  }
  .discover_section .logo_container {
    left: 20px;
    bottom: 30px;
    transform: translateY(0);
  }
  .discover_section .logo_container svg {
    height: 65px;
  }
  .discover_section .logo_container:nth-of-type(2) {
    left: 20px;
    bottom: 125px;
    transform: translateY(0);
  }
  .discover_section .logo_container:nth-of-type(2) svg {
    height: 65px;
  }
  .discover_section .button_container {
    right: 20px;
    bottom: 30px;
    transform: translateY(0);
  }
  .discover_section .button_container .button_text {
    display: none;
  }
}
.quote_section {
  margin: 0 auto;
  padding-top: 100px;
  margin-bottom: 200px;
  text-align: center;
}

@media (max-width: 1024px) {
  .quote_section {
    margin-bottom: 100px;
    padding-top: 50px;
  }
}
@media (max-width: 768px) {
  .quote_section {
    padding-top: 0;
  }
}
.product_section {
  background-color: #fff;
  padding-top: 400px;
  padding-bottom: 125px;
}
.product_section .product_container .sidebar {
  flex: 25%;
  justify-content: end;
  margin-left: 250px;
}
.product_section .product_container .sidebar ul li {
  position: relative;
  display: block;
  width: fit-content;
}
.product_section .product_container .sidebar ul li::after {
  content: " ";
  background-size: cover;
  width: 21px;
  height: 24px;
  position: absolute;
  right: -30px;
  transform: translateY(50%);
  background-image: url("../../../icons/arrow-no-circle.svg");
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.product_section .product_container .sidebar ul li:hover::after {
  opacity: 1;
}
.product_section .product_container .sidebar .big_text {
  margin-bottom: 25px;
}
.product_section .product_container .product_list {
  position: relative;
  margin-left: 150px;
  flex: 75%;
}
.product_section .product_container .product_list .product_list_title {
  position: absolute;
  top: -150px;
  left: 50px;
  transform: translateY(-100%);
}
.product_section .product_container .product_list .product_list_title .section_serif_title, .product_section .product_container .product_list .product_list_title .big_title {
  line-height: 100%;
}
.product_section .product_container .product_list .products {
  max-width: 1000px;
  flex-wrap: wrap;
}
.product_section .product_container .product_list .products .product-slide {
  flex: 50%;
  padding: 0 50px 50px 50px;
  margin-top: -25px;
}
.product_section .product_container .product_list .products .product-slide:nth-child(even) {
  border: none;
}
.product_section .product_container .product_list .products .product-slide .price {
  display: none;
}
@media (max-width: 1500px) {
  .product_section .product_container .sidebar {
    margin-left: 100px;
  }
  .product_section .product_container .product_list {
    margin-left: 100px;
  }
}
@media (max-width: 1200px) {
  .product_section .product_container .sidebar {
    margin-left: 50px;
  }
  .product_section .product_container .product_list {
    margin-left: 50px;
  }
}
@media (max-width: 1024px) {
  .product_section .product_container .product_list .product_list_title {
    left: 25px;
  }
  .product_section .product_container .product_list .products .product-slide {
    flex: 100%;
    padding: 25px;
    border: none;
  }
}
@media (max-width: 768px) {
  .product_section {
    padding-top: 75px;
  }
  .product_section .product_list_title {
    margin-left: 25px;
  }
  .product_section .product_list_title .section_serif_title, .product_section .product_list_title .big_title {
    line-height: 65%;
  }
  .product_section .product_container {
    flex-direction: column;
  }
  .product_section .product_container .sidebar {
    border-top: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
    padding: 40px 0;
    margin: 50px 25px 0 25px;
    flex: 1;
  }
  .product_section .product_container .sidebar ul {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    gap: 30px;
  }
  .product_section .product_container .sidebar ul li::after {
    display: none;
  }
  .product_section .product_container .product_list {
    margin-left: 0;
    flex: 1;
  }
  .product_section .product_container .product_list .products {
    margin: 25px;
  }
  .product_section .product_container .product_list .products .product-slide {
    padding: 0px;
    padding-top: 50px;
  }
}

.section_cta {
  display: inline-flex;
  align-items: center;
}
.section_cta::after {
  display: inline-block;
  content: "";
  background-image: url("../../../icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 55px;
  height: 55px;
  margin-left: 15px;
}
.section_cta.white {
  color: #fff;
}
.section_cta.white::after {
  background-image: url("../../../icons/arrow-right-white.svg");
}

.hide-overflow {
  overflow: hidden;
}

.slick-arrow {
  position: absolute;
  z-index: 9;
  top: 50%;
  cursor: pointer;
}

.prev-arrow {
  left: 30px;
}

.next-arrow {
  right: 30px;
}

.homepage-hero-container {
  margin: 0 0 clamp(85px, 9.1146vw, 175px);
  padding: 0 0 clamp(70px, 4.1667vw, 80px);
  position: relative;
  transition: background-color 1s ease;
  height: 100vh;
  overflow-x: hidden;
}
.homepage-hero-container .homepage-hero-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 2;
}
.homepage-hero-container .homepage-hero-wrapper .reveal-img {
  transition: opacity 1s ease;
  opacity: 1;
}
.homepage-hero-container .homepage-hero-wrapper.deactivated {
  z-index: 1;
}
.homepage-hero-container .homepage-hero-wrapper.deactivated .reveal-img {
  opacity: 0;
}
.homepage-hero-container .homepage-hero-wrapper.deactivated .text-container {
  opacity: 0 !important;
  width: 0;
}
.homepage-hero-container .left {
  max-width: 805px;
  width: 100%;
  overflow: hidden;
}
.homepage-hero-container .right {
  margin-left: -90px;
  justify-content: space-between;
  align-items: center;
  max-width: 1020px;
  width: 100%;
  padding: 0px 30px;
}
.homepage-hero-container .right .small-img {
  max-width: 365px;
  width: 100%;
  margin-right: 15px;
  overflow: hidden;
}
.homepage-hero-container .right .text-container {
  max-width: 460px;
  width: 100%;
  margin-left: 15px;
}
.homepage-hero-container .right .text-container h1 {
  margin: 0 0 clamp(20px, 2.0833vw, 40px);
}
.homepage-hero-container .right .text-container p {
  margin: 0 0 20px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 189%;
}
.homepage-hero-container .homepage-hero-arrow {
  position: absolute;
  right: 135px;
  bottom: 145px;
  max-width: 125px;
  z-index: 2;
}
.homepage-hero-container .homepage-hero-arrow a,
.homepage-hero-container .homepage-hero-arrow svg {
  display: inline-block;
  max-width: 125px;
  width: 100%;
}

.homepage-discover-container {
  margin: 0 0 clamp(105px, 11.9792vw, 230px);
  padding: 0 30px;
}
.homepage-discover-container .homepage-discover-wrapper {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}
.homepage-discover-container .section-title {
  margin: 0 0 45px;
}
.homepage-discover-container .section-title h2,
.homepage-discover-container .section-title h3 {
  margin: 0;
}
.homepage-discover-container .brands {
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.homepage-discover-container .brands .discover-link-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.homepage-discover-container .brands .discover-link-container p {
  display: none;
}
.homepage-discover-container .brands .discover-link-container p.active {
  display: inline;
}
.homepage-discover-container .brands .seperator {
  display: block;
  width: 1px;
  height: 255px;
  background-color: #000;
  margin: 0 clamp(50px, 7.0313vw, 135px);
}
.homepage-discover-container .brands a {
  display: block;
  position: relative;
}
.homepage-discover-container .brands a svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: all 0.4s ease;
}
.homepage-discover-container .brands a img {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease;
}
.homepage-discover-container .brands a:hover img {
  opacity: 1;
  visibility: visible;
}
.homepage-discover-container .brands a.discover-trea g path {
  fill: #000;
}
.homepage-discover-container .brands a.discover-trea rect {
  fill: none;
}
.homepage-discover-container .brands a.discover-trea:hover g path {
  fill: #fff;
}
.homepage-discover-container .brands a.discover-trea:hover rect {
  fill: #000;
}
.homepage-discover-container .brands a.discover-navarino svg path {
  fill: #675A4D;
}
.homepage-discover-container .brands a.discover-navarino:hover svg path {
  fill: #fff;
}

.homepage-gifts-container {
  margin: 0 0 clamp(120px, 9.375vw, 180px);
  padding: 0 30px;
}
.homepage-gifts-container .homepage-gifts-wrapper {
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}
.homepage-gifts-container .homepage-gifts-wrapper .left {
  max-width: 390px;
  width: 100%;
}
.homepage-gifts-container .homepage-gifts-wrapper .left h2 {
  margin: 0 0 clamp(40px, 2.8646vw, 55px);
}
.homepage-gifts-container .homepage-gifts-wrapper .left h3 {
  margin: 0;
}
.homepage-gifts-container .homepage-gifts-wrapper .left p {
  margin: 0 0 40px;
}
.homepage-gifts-container .homepage-gifts-wrapper .right .mobile {
  display: none;
}

.homepage-explore-container {
  margin: 0 0 clamp(60px, 4.6875vw, 90px);
  padding: 0 30px;
}
.homepage-explore-container .homepage-explore-wrapper {
  max-width: 530px;
  width: 100%;
  margin: 0 auto;
}
.homepage-explore-container .homepage-explore-wrapper a {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.homepage-explore-container .homepage-explore-wrapper a svg {
  overflow: visible;
  display: block;
  width: 100%;
  margin: 0 0 clamp(80px, 6.25vw, 120px);
}
.homepage-explore-container .homepage-explore-wrapper a svg #explore-ring {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: rotate-center 20s linear infinite;
  animation: rotate-center 20s linear infinite;
}

.product-slide {
  padding: 25px 45px;
  border-right: 1px solid #EAEAEA;
}
.product-slide .top {
  align-items: center;
  justify-content: space-between;
  margin: 0 0 clamp(0px, 2.6042vw, 50px);
}
.product-slide .top div:first-child {
  flex-basis: 50%;
}
.product-slide .top .main_button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #EAEAEA;
  background-color: #EAEAEA;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 375px) {
  .product-slide .top .main_button {
    width: 100%;
    max-width: 110px;
    height: 110px;
  }
}
.product-slide .top .main_button:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #FFECC3;
  border-color: #FFECC3;
}
.product-slide .thumb a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-slide .thumb a img {
  margin: 0 auto;
  mix-blend-mode: darken;
  object-fit: contain;
  min-height: 350px;
  max-height: 350px;
  height: auto;
}
.product-slide .thumb a:hover {
  opacity: 0.8;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.products-slider-container {
  margin: 0 0 clamp(80px, 6.25vw, 120px);
}
.products-slider-container .products-slider {
  margin: 0 0 clamp(80px, 5.2083vw, 100px);
}
.products-slider-container .products-slider-discover-more {
  justify-content: center;
}

.homepage-black-marquee {
  background-color: #000;
  padding: 30px 0 40px;
  color: #fff;
  overflow: hidden;
}

.homepage-logo-marquee {
  overflow: hidden;
  padding: 30px 0;
}
.homepage-logo-marquee .logo-marquee {
  align-items: center;
  gap: 60px;
}
.homepage-logo-marquee .logo-marquee .logo-item {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.homepage-recipes-container {
  margin: 0 0 clamp(80px, 9.375vw, 180px);
  padding: clamp(80px, 9.375vw, 180px) 0 clamp(80px, 6.25vw, 120px);
  background-color: #9B775D;
  color: #fff;
}
.homepage-recipes-container .top {
  max-width: 590px;
  width: 100%;
  margin: 0 auto clamp(30px, 7.2917vw, 140px);
  text-align: center;
  padding: 0 30px;
}
.homepage-recipes-container .top h2 {
  color: #fff;
  margin: 0 0 55px;
}
.homepage-recipes-container .recipes-slider {
  margin: 0 0 clamp(80px, 6.25vw, 120px);
}
@media (max-width: 767px) {
  .homepage-recipes-container .recipes-slider .prev-arrow svg, .homepage-recipes-container .recipes-slider .next-arrow svg {
    width: 75px;
  }
  .homepage-recipes-container .recipes-slider .prev-arrow {
    left: 0;
  }
  .homepage-recipes-container .recipes-slider .next-arrow {
    right: 0;
  }
}
.homepage-recipes-container .recipes-slider .slick-list {
  padding-top: 30px;
}
.homepage-recipes-container .recipes-slider .recipe-slide {
  margin: 0 clamp(40px, 3.125vw, 60px);
  transition: margin 0.4s ease;
}
.homepage-recipes-container .recipes-slider .recipe-slide:hover {
  margin-top: -30px;
}
.homepage-recipes-container .recipes-slider .recipe-slide .thumb {
  overflow: hidden;
  margin: 0 0 25px;
}
.homepage-recipes-container .recipes-slider .recipe-slide .thumb.active img {
  transition: transform 0.4s ease;
  transform: scale(1.1);
}
.homepage-recipes-container .recipes-slider .recipe-slide .thumb img {
  transition: transform 0.4s ease;
}
.homepage-recipes-container .recipes-slider .recipe-slide .thumb a {
  display: block;
}
.homepage-recipes-container .recipes-slider .recipe-slide .info h3,
.homepage-recipes-container .recipes-slider .recipe-slide .info a {
  color: #fff;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 2.2rem;
  line-height: 32px;
}
.homepage-recipes-container .recipes-slider .recipe-slide .info h3 .section_serif_title,
.homepage-recipes-container .recipes-slider .recipe-slide .info a .section_serif_title {
  font-size: 2.2rem;
  line-height: 32px;
}
.homepage-recipes-container .recipes-slider .recipe-slide .info .details {
  align-items: center;
}
.homepage-recipes-container .recipes-slider .recipe-slide .info .details .time {
  margin: 0 20px;
}
.homepage-recipes-container .recipes-slider .recipe-slide .info .details span {
  display: flex;
  align-items: center;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 1.4rem;
  line-height: 26px;
}
.homepage-recipes-container .recipes-slider .recipe-slide .info .details span svg {
  display: inline-block;
  margin: 0 10px 0 0;
}
.homepage-recipes-container .homepage-recipes-discover-more {
  justify-content: center;
}
.homepage-recipes-container .homepage-recipes-discover-more a {
  color: #fff;
}

.homepage-awards-container {
  margin: 0 0 clamp(80px, 9.375vw, 180px);
  padding: 0 30px;
}
.homepage-awards-container .homepage-awards-wrapper {
  max-width: 1350px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.homepage-awards-container .homepage-awards-wrapper .left {
  text-align: center;
  max-width: 490px;
  width: 100%;
  margin-right: 15px;
}
.homepage-awards-container .homepage-awards-wrapper .left h2 {
  margin: 0 0 clamp(40px, 3.125vw, 60px);
}
.homepage-awards-container .homepage-awards-wrapper .left p {
  margin: 0 0 40px;
}
.homepage-awards-container .homepage-awards-wrapper .right {
  max-width: 670px;
  width: 100%;
  margin-left: 15px;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item {
  align-items: center;
  justify-content: space-between;
  padding: 30px 45px;
  border-top: 1px solid #000;
  transition: background-color 0.4s ease;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item:hover {
  background-color: #FFECC3;
  border-top: 1px solid #FFECC3;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item:hover .awards-item-right {
  opacity: 1;
  visibility: visible;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item .awards-item-left {
  flex-basis: 80%;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item .awards-item-left h3 {
  margin: 0;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item .awards-item-right {
  flex-basis: 20%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item .awards-item-right img {
  margin-left: auto;
}
.homepage-awards-container .homepage-awards-wrapper .right .awards-item:hover + .awards-item {
  border-top: 1px solid #FFECC3;
}
.homepage-awards-container .mobile {
  display: none;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slick-dots li button:before {
  font-size: 50px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1440px) {
  .homepage-hero-container .left {
    max-width: 600px;
  }
  .homepage-hero-container .right {
    margin-left: -60px;
    max-width: 700px;
  }
  .homepage-hero-container .homepage-hero-arrow {
    right: 100px;
    bottom: 100px;
    max-width: 100px;
  }
  .homepage-hero-container .homepage-hero-arrow a,
.homepage-hero-container .homepage-hero-arrow svg {
    max-width: 100px;
  }

  .homepage-hero-container .homepage-hero-wrapper .right .small-img {
    max-width: 300px;
  }
}
@media (max-width: 1280px) {
  .homepage-hero-container .left {
    max-width: 560px;
  }
  .homepage-hero-container .right {
    margin-left: -60px;
  }
  .homepage-hero-container .right .small-img {
    max-width: 280px;
  }
  .homepage-hero-container .homepage-hero-arrow {
    right: 80px;
    bottom: 100px;
    max-width: 100px;
  }
  .homepage-hero-container .homepage-hero-arrow a,
.homepage-hero-container .homepage-hero-arrow svg {
    max-width: 100px;
  }

  .homepage-discover-container .homepage-discover-wrapper {
    max-width: 870px;
  }
  .homepage-discover-container .brands .discover-link-container {
    bottom: -20px;
  }
  .homepage-discover-container .brands a {
    max-width: 345px;
  }

  .homepage-gifts-container .homepage-gifts-wrapper {
    max-width: 960px;
  }
  .homepage-gifts-container .homepage-gifts-wrapper .left {
    max-width: 350px;
  }

  .homepage-explore-container .homepage-explore-wrapper {
    max-width: 350px;
  }

  .homepage-awards-container .homepage-awards-wrapper {
    max-width: 1050px;
  }
  .homepage-awards-container .homepage-awards-wrapper .left {
    max-width: 350px;
  }
  .homepage-awards-container .homepage-awards-wrapper .right {
    max-width: 540px;
  }
}
@media (max-width: 991px) {
  .homepage-hero-container .homepage-hero-wrapper {
    flex-direction: column;
  }
  .homepage-hero-container .homepage-hero-wrapper .right {
    padding: 0 20px;
    margin: -60px 0 0;
    flex-direction: column;
  }
  .homepage-hero-container .homepage-hero-wrapper .right .small-img {
    margin: 0 0 35px auto;
  }
  .homepage-hero-container .homepage-hero-wrapper .right .text-container .text-right {
    text-align: left;
  }
  .homepage-hero-container .homepage-hero-arrow svg circle {
    display: none;
  }

  .homepage-discover-container {
    padding: 0 20px;
  }
  .homepage-discover-container .homepage-discover-wrapper .section-title .text-center {
    text-align: left;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands {
    flex-direction: column;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands .left {
    margin-bottom: 40px;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands .seperator {
    display: none;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands a img {
    opacity: 1;
    visibility: visible;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands a.discover-trea g path {
    fill: #fff;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands a.discover-trea rect {
    fill: #000;
  }
  .homepage-discover-container .homepage-discover-wrapper .brands a.discover-navarino svg path {
    fill: #fff;
  }

  .homepage-gifts-container {
    padding: 0;
  }
  .homepage-gifts-container .homepage-gifts-wrapper {
    flex-direction: column-reverse;
  }
  .homepage-gifts-container .homepage-gifts-wrapper .left {
    padding: 0 20px;
    max-width: unset;
  }
  .homepage-gifts-container .homepage-gifts-wrapper .right {
    margin-bottom: 40px;
  }
  .homepage-gifts-container .homepage-gifts-wrapper .right .desktop {
    display: none;
  }
  .homepage-gifts-container .homepage-gifts-wrapper .right .mobile {
    display: block;
  }

  .homepage-explore-container {
    padding: 0 20px;
  }
  .homepage-explore-container .text-center {
    text-align: left;
  }

  .homepage-recipes-container .top {
    max-width: unset;
    text-align: left;
    padding: 0 20px;
  }
  .homepage-recipes-container .recipes-slider .slick-list {
    padding-left: 20px;
  }

  .homepage-awards-container {
    padding: 0 20px;
  }
  .homepage-awards-container .homepage-awards-wrapper {
    flex-direction: column;
  }
  .homepage-awards-container .homepage-awards-wrapper .left {
    text-align: center;
    margin: 0 0 30px;
  }
  .homepage-awards-container .homepage-awards-wrapper .left .section_cta {
    display: none;
  }
  .homepage-awards-container .homepage-awards-wrapper .right {
    max-width: 670px;
    margin: 0 0 30px;
  }
  .homepage-awards-container .homepage-awards-wrapper .right .awards-item {
    padding: 40px 0px;
  }
  .homepage-awards-container .homepage-awards-wrapper .right .awards-item:hover {
    background-color: #FFECC3;
    border-bottom: 1px solid #FFECC3;
  }
  .homepage-awards-container .homepage-awards-wrapper .right .awards-item .awards-item-right {
    opacity: 1;
    visibility: visible;
  }
  .homepage-awards-container .homepage-awards-wrapper .mobile {
    display: inline-flex;
  }
}
@media (max-width: 767px) {
  .homepage-discover-container .brands a.discover-trea svg {
    width: 160px;
  }
  .homepage-discover-container .brands a.discover-navarino svg {
    width: 100px;
  }

  .products-slider-container .product-slide:nth-child(1n+5) {
    display: none;
  }

  .homepage-recipes-container .recipes-slider {
    cursor: default;
  }

  .homepage-awards-container .homepage-awards-wrapper .left {
    max-width: unset;
    text-align: left;
  }
  .homepage-awards-container .homepage-awards-wrapper .right .awards-item .awards-item-left {
    padding-right: 15px;
  }
}
@media (max-width: 660px) {
  .homepage-hero-container .homepage-hero-wrapper .right .small-img {
    max-width: 160px;
  }
  .homepage-hero-container .homepage-hero-arrow {
    right: 10px;
    bottom: 20px;
  }

  .homepage-explore-container .homepage-explore-wrapper a {
    max-width: 220px;
  }

  .homepage-discover-container .brands {
    padding: 0 50px;
  }
}
@media (max-width: 480px) {
  .homepage-recipes-container .recipes-slider .recipe-slide {
    margin: 0 30px 0;
  }
  .homepage-recipes-container .recipes-slider .recipe-slide .info .details span {
    font-size: 1.2rem;
  }
  .homepage-recipes-container .recipes-slider .recipe-slide .info .details .time {
    margin: 0 10px;
  }
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.page_awards p {
  margin: 0;
  padding: 0;
}
.page_awards footer {
  margin: 0;
  padding: 0;
}
.page_awards .awards_first_content .big_title {
  padding-top: 213px;
  padding-bottom: 40px;
  max-width: 290px;
  margin: 0 auto;
  text-align: center;
}
.page_awards .awards_first_content .main_text {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}
.page_awards .awards_detailed {
  text-align: left;
  width: 100%;
  max-width: 1090px;
  margin: 0 auto;
  padding: 0 70px 180px;
}
.page_awards .awards_images {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 120px 20px 70px;
  text-align: center;
  max-width: 730px;
  margin: 0 auto;
}
.page_awards .awards_images img {
  max-width: 64px;
  margin: 5px 25px;
}
.page_awards .mobile_awards {
  opacity: 0;
  visibility: hidden;
}
.page_awards .first_gridy_item {
  border-top: 1px solid #000;
}
.page_awards .gridy_item {
  display: grid;
  grid-template-columns: 5fr 1fr;
  border-bottom: 1px solid #000;
}
.page_awards .gridy_item:hover {
  border-bottom: none;
}
.page_awards .first_gridy_item:hover {
  border-top: none;
}
.page_awards .img_great_taste {
  opacity: 0;
  visibility: hidden;
  padding-left: 10px;
  padding-top: 20px;
}
.page_awards .gridy_item:hover .img_great_taste {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease;
}
.page_awards .gridy_item:hover {
  background-color: #FFECC3;
  transition: 0.3s ease;
  padding: 0;
  margin: 0;
  border-bottom: none;
}
.page_awards .hovered_awards .secondary_title {
  padding-top: 40px;
  padding-left: 75px;
}
.page_awards .hovered_awards .italic_text, .page_awards .hovered_awards .free-shipping-calculator > *, .page_awards .hovered_awards .shipping-duties > * {
  padding-bottom: 40px;
  padding-left: 75px;
}
.page_awards .hovered_awards_imgs img {
  max-width: 90px;
}
.page_awards .hovered_awards_imgs .mobile_awards {
  display: none;
}
@media only screen and (max-width: 767px) {
  .page_awards .hovered_awards {
    margin-right: 10px;
  }
  .page_awards .hovered_awards_imgs .mobile_awards {
    display: block;
    opacity: 1;
    visibility: visible;
    max-width: 68px;
  }
  .page_awards .hovered_awards_imgs .img_great_taste {
    display: none;
  }
  .page_awards .awards_first_content {
    max-width: 375px;
    margin: 0 auto;
  }
  .page_awards .awards_images {
    padding: 70px 20px;
  }
  .page_awards .awards_detailed {
    padding: 0 20px 100px;
  }
  .page_awards .hovered_awards .secondary_title, .page_awards .italic_text, .page_awards .free-shipping-calculator > *, .page_awards .shipping-duties > * {
    padding-left: 0;
  }
  .page_awards .awards_first_content .big_title {
    padding-top: 90px;
    padding-bottom: 55px;
    max-width: 190px;
  }
  .page_awards .awards_first_content .main_text {
    padding: 0 15px;
    /*sto figma eixe 28 alla den fainotan to idio*/
  }
  .page_awards .img_great_taste {
    display: none;
  }
  .page_awards .mobile_awards {
    padding-top: 45px;
  }
  .page_awards .hovered_awards .italic_text, .page_awards .hovered_awards .free-shipping-calculator > *, .page_awards .hovered_awards .shipping-duties > * {
    font-size: 1.48rem;
    padding-left: 0;
  }
  .page_awards .gridy_item:hover .mobile_awards {
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease;
  }
  .page_awards .gridy_item:hover {
    background-color: #fff;
  }
  .page_awards .centered_grid_five {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .page_awards .centered_grid_six {
    grid-column-start: 3;
    grid-row-start: 2;
    grid-row-end: 2;
  }
}

@media (max-width: 767px) {
  .page_awards .awards_images img {
    margin: 5px 10px;
  }
}
.category-container {
  padding: 120px 20px 100px;
}
.category-container .category-info {
  max-width: 525px;
  margin: 0 auto;
  text-align: center;
}
.category-container .category-info h1 {
  margin: 0 0 40px;
  line-height: 53px;
}
.category-container .category-wrapper .products-filters {
  padding: 50px 0;
}
.category-container .category-wrapper .products-filters form.bf-with-counts {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block {
  width: 100%;
  max-width: 270px;
  position: relative;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block .bf-attr-header {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  padding: 20px 15px;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0 5px 5px 0;
  -webkit-font-smoothing: antialiased;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block .bf-attr-header .bf-arrow {
  display: inline-block;
  font-size: unset;
  padding: 0;
  float: unset;
  background-image: none !important;
  background-position: unset;
  vertical-align: bottom;
  width: 22px;
  height: 22px;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block .bf-attr-header .bf-arrow svg {
  display: inline-block;
  width: 22px;
  height: 22px;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block .bf-attr-header .bf-arrow svg .st0 {
  fill: #000;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block .bf-attr-header .bf-arrow svg .st1 {
  fill: #000;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block .bf-sliding-cont .bf-sliding {
  display: none;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block:first-of-type .bf-attr-header {
  border-radius: 5px 0 0 5px;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open:first-of-type .bf-attr-header {
  border-radius: 5px 0 0 0;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-attr-header {
  border-radius: 0 5px 0 0;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont {
  position: absolute;
  width: 100%;
  border: 1px solid #000;
  border-bottom: none;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding {
  display: none;
  height: 100% !important;
  color: #9D9D9D;
  font-weight: 400;
  z-index: 9;
  position: relative;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded {
  display: block;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont {
  padding: 0;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter {
  display: block;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #000;
  padding: 5px 15px;
  position: relative;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter .bf-cell {
  display: inline-block;
  border-bottom: none;
  vertical-align: unset;
  padding: 0;
  width: 100%;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter .bf-cell label {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 21px;
  color: #000;
  padding: 20px 40px;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter .bf-cell span.bf-cell.bf-c-2.bf-cascade-0 {
  height: unset;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter .bf-cell.bf-c-1 {
  display: none;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter .bf-cell.bf-c-3 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 30px;
  right: 15px;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter .bf-cell.bf-c-3 .bf-cross {
  display: inline-block;
  content: "";
  background-image: url("../../../icons/remove-filter.svg");
  background-repeat: no-repeat;
  background-size: cover !important;
  width: 12px;
  height: 12px;
  padding: 0;
  background-position: center;
  vertical-align: unset;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter:hover {
  background-color: #000;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter:hover .bf-cell.bf-c-3 .bf-cross {
  background-image: url("../../../icons/remove-filter-hover.svg");
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter:hover label {
  color: #fff;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.has-selected-item .bf-attr-header {
  color: #fff;
  background-color: #000;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.has-selected-item .bf-attr-header .bf-arrow svg .st0 {
  fill: #fff;
  display: none;
}
.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.has-selected-item .bf-attr-header .bf-arrow svg .st1 {
  fill: #fff;
}
.category-container .category-wrapper .products-filters form .bf-buttonclear-box {
  display: none;
}
.category-container .category-wrapper .categories-choices {
  display: none;
}
.category-container .category-pagination {
  justify-content: center;
}
.category-container .category-pagination .products-loader {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}
.category-container .category-pagination .products-loader a.main_button {
  border: 1px solid #000;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  position: relative;
}
.category-container .category-pagination .products-loader a.main_button span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.category-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding: 55px clamp(0px, 7.813vw, 150px) 0;
}
.category-grid .product-col {
  flex-basis: 33.333%;
  border-left: 1px solid #EAEAEA;
}
.category-grid .product-col .product-thumb {
  border: none;
  max-width: 345px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.category-grid .product-col .product-thumb .top {
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}
.category-grid .product-col .product-thumb .top .info {
  width: 100%;
  max-width: 170px;
  margin-right: 5px;
}
.category-grid .product-col .product-thumb .top .info .secondary_title {
  display: block;
  min-height: 100px;
  margin-bottom: 10px;
}
.category-grid .product-col .product-thumb .top .info .italic_text, .category-grid .product-col .product-thumb .top .info .free-shipping-calculator > *, .category-grid .product-col .product-thumb .top .info .shipping-duties > * {
  margin: 0;
}
.category-grid .product-col .product-thumb .top .info .price {
  color: #000;
}
.category-grid .product-col .product-thumb .top .info .price .price-new {
  font-weight: 500;
}
.category-grid .product-col .product-thumb .top .info .price .price-old {
  color: #000;
}
.category-grid .product-col .product-thumb .top .main_button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #EAEAEA;
  background-color: #EAEAEA;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 375px) {
  .category-grid .product-col .product-thumb .top .main_button {
    width: 100%;
    max-width: 110px;
    height: 110px;
  }
}
.category-grid .product-col .product-thumb .top .main_button:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #FFECC3;
  border-color: #FFECC3;
}
.category-grid .product-col .product-thumb .image {
  padding-bottom: 115px;
}
.category-grid .product-col:nth-child(3n+1) {
  border-left: none;
}

@media (max-width: 1366px) {
  .category-grid {
    padding-left: clamp(0px, 3.646vw, 70px);
    padding-right: clamp(0px, 3.646vw, 70px);
  }
}
@media (max-width: 1280px) {
  .category-container {
    padding-top: 4.688vw;
  }
  .category-container .category-info h1 {
    line-height: 43px;
  }

  .category-grid .product-col .product-thumb .product-link .top .info {
    max-width: 140px;
    margin-right: 5px;
  }
  .category-grid .product-col .product-thumb .product-link .top .info .secondary_title {
    min-height: 80px;
  }

  .third-provider-p {
    margin-top: 30px;
  }

  #product-category-usa.category-container {
    padding: 130px 20px !important;
  }
}
@media (max-width: 1024px) {
  .category-grid .product-col {
    flex-basis: 50%;
  }
  .category-grid .product-col:nth-child(3n+1) {
    border-left: 1px solid #EAEAEA;
  }
  .category-grid .product-col:nth-child(2n+1) {
    border-left: none;
  }
}
@media (max-width: 767px) {
  .category-grid {
    padding: 125px 0 0;
  }

  .third-provider-p {
    margin-top: 30px;
  }

  #product-category-usa.category-container {
    padding: 130px 20px !important;
  }
}
@media (max-width: 630px) {
  .category-container {
    padding: 50px 20px;
  }
  .category-container .category-info h1 {
    line-height: 40px;
  }

  .category-grid {
    padding: 20px 0 0;
  }
  .category-grid .product-col {
    flex-basis: 100%;
    border-left: none;
  }
  .category-grid .product-col .product-thumb {
    padding: 0;
  }
  .category-grid .product-col .product-thumb .product-link .top .info .secondary_title {
    min-height: unset;
  }
  .category-grid .product-col .product-thumb .product-link .image {
    padding-bottom: 20px;
  }
  .category-grid .product-col:nth-child(3n+1) {
    border-left: none;
  }
}
@media (max-width: 320px) {
  .category-grid .product-col .product-thumb .top .main_button {
    width: 95px;
    height: 95px;
  }
}
.clear-all-filters {
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  padding: 6px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.clear-all-filters > .fa {
  margin-left: 8px;
}
.clear-all-filters:hover {
  background-color: #000;
  color: #fff;
}

.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter.filter-checked {
  background-color: #000;
  border-bottom: 1px solid;
}

.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter.filter-checked label {
  color: #fff;
}

.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter.filter-checked .bf-cross {
  background-image: url("../../../icons/remove-filter-hover.svg") !important;
}

.disabled_button {
  pointer-events: none;
  color: white;
  background-color: black !important;
}

.product-container {
  padding: 20px 0 70px;
}
.product-container .product-row {
  align-items: center;
  padding: 0 clamp(0px, 17.188vw, 330px) 0;
}
.product-container .product-row .left-col {
  flex-basis: 40%;
  margin-right: 15px;
}
.product-container .product-row .left-col .prd-info.mobile-only {
  display: none;
}
.product-container .product-row .right-col {
  flex-basis: 60%;
  margin-left: 15px;
}
.product-container .product-row .right-col .right-col-wrapper {
  max-width: 500px;
}
.product-container .product-row .right-col .right-col-wrapper .prd-info.desktop-only {
  display: block;
}
.product-container .product-row .right-col .right-col-wrapper .prd-info.desktop-only .big_title {
  margin: 0 0 15px;
}
.product-container .product-row .right-col .right-col-wrapper .prd-info.desktop-only .secondary_title {
  margin-bottom: 0;
}
.product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field {
  padding: 40px 0;
}
.product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field .quantity {
  position: relative;
  max-width: 130px;
  display: flex;
  align-items: baseline;
}
.product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field .quantity input[type=text] {
  padding: 15px 10px;
  position: relative;
  text-align: center;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 145%;
  -moz-box-shadow: none;
  -goog-ms-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  order: 2;
}
.product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field .quantity .quantity-button.quantity-down {
  padding: 15px 10px;
  order: 1;
}
.product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field .quantity .quantity-button.quantity-up {
  padding: 15px 10px;
  order: 3;
}
.product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field .quantity .quantity-button:hover {
  color: #B3B3B3;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy {
  align-items: center;
  justify-content: space-between;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .form-group {
  margin-bottom: 0;
  flex-basis: 35%;
  margin-right: 15px;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .form-group #button-cart {
  width: 125px;
  height: 125px;
  border: 1px solid #EAEAEA;
  border-radius: 50%;
  background-color: #EAEAEA;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .form-group #button-cart:hover {
  border-color: #FFECC3;
  background-color: #FFECC3;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .price-list {
  flex-basis: 35%;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .price-list li {
  display: block;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .price-list li .product-price {
  display: block;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .price-list li .product-price.old {
  text-decoration: line-through;
  font-size: 1.6rem;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .product-info-links {
  flex-basis: 35%;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .product-info-links li {
  margin-bottom: 15px;
}
.product-container .product-row .right-col .right-col-wrapper #product .prd-buy .product-info-links li a {
  display: block;
  cursor: pointer;
}
.product-container .awards-marquee {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px 0;
  margin: 75px 0;
}
.product-container .awards-marquee .js-marquee {
  margin-right: 0 !important;
}
.product-container .awards-marquee .js-marquee .awards-marquee-items .awards-marquee-item {
  align-items: center;
  max-width: 225px;
  margin: 0 50px;
}
.product-container .awards-marquee .js-marquee .awards-marquee-items .awards-marquee-item .image {
  margin-right: 15px;
}
.product-container .awards-section {
  align-items: center;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px 0;
  margin: 75px 0;
  justify-content: center;
}
.product-container .awards-section .awards-marquee-items .awards-marquee-item {
  align-items: center;
  max-width: 225px;
  margin: 0 20px;
}
.product-container .awards-section .awards-marquee-items .awards-marquee-item .image {
  margin-right: 15px;
}
.product-container .separator-line {
  display: inline-block;
  content: "";
  background-color: #000;
  width: 100%;
  height: 1px;
  margin: 30px 0 50px;
}
.product-container .tabs-row {
  padding: 0 clamp(0px, 17.188vw, 330px) 0;
  justify-content: flex-end;
}
.product-container .tabs-row #product-tabs {
  flex-basis: 60%;
  margin-left: 15px;
}
.product-container .tabs-row #product-tabs .product-tabs-headings {
  padding: 5px 0 45px;
}
.product-container .tabs-row #product-tabs .product-tabs-headings .tab-heading {
  position: relative;
}
.product-container .tabs-row #product-tabs .product-tabs-headings .tab-heading.active:after {
  display: inline-block;
  content: "";
  background-color: #000;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.product-container .tabs-row #product-tabs .product-tabs-headings .tab-heading + .tab-heading {
  margin-left: 50px;
}
.product-container .tabs-row #product-tabs .tab-content {
  display: none;
}
.product-container .tabs-row #product-tabs .tab-content.active {
  display: block;
  max-width: 530px;
}

.related-products {
  margin: 0;
  padding: 100px 0 180px;
}
.related-products .big_title {
  text-align: center;
  margin: 0 0 100px;
}
.related-products .product-item {
  max-width: 405px;
  border-right: 1px solid #EAEAEA;
}
.related-products .product-item .product-thumb {
  border: none;
  max-width: 345px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.related-products .product-item .product-thumb .top {
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}
.related-products .product-item .product-thumb .top .info {
  width: 100%;
  max-width: 170px;
  margin-right: 5px;
}
.related-products .product-item .product-thumb .top .info .secondary_title {
  display: block;
  min-height: 100px;
  margin-bottom: 10px;
}
.related-products .product-item .product-thumb .top .info .italic_text, .related-products .product-item .product-thumb .top .info .free-shipping-calculator > *, .related-products .product-item .product-thumb .top .info .shipping-duties > * {
  margin: 0;
}
.related-products .product-item .product-thumb .top .main_button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #EAEAEA;
  background-color: #EAEAEA;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (max-width: 375px) {
  .related-products .product-item .product-thumb .top .main_button {
    width: 100%;
    max-width: 110px;
    height: 110px;
  }
}
.related-products .product-item .product-thumb .top .main_button:hover {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #FFECC3;
  border-color: #FFECC3;
}

.popup-alert.alert.alert-success {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-bottom: 0;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  animation: note 6500ms forwards;
  font-weight: 600;
  padding: 20px 30px;
  font-size: 1.3rem;
  z-index: 999;
}
.popup-alert.alert.alert-success a {
  color: #D3B675;
}
.popup-alert.alert.alert-success.alert-dismissible .close {
  position: absolute;
  top: 5px;
  right: 8px;
}

@media (max-width: 1280px) {
  .product-container .product-row {
    padding: 20px 55px 0;
  }

  .related-products .product-item .product-thumb .product-link .top .info .secondary_title {
    min-height: 80px;
  }
}
@media (max-width: 1024px) {
  .product-container .product-row {
    padding: 0 clamp(0px, 5.208vw, 100px) 0;
  }
  .product-container .tabs-row {
    padding: 0 clamp(0px, 5.208vw, 100px) 0;
  }
}
@media (max-width: 767px) {
  .product-container .product-row {
    padding: 0 20px 0;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy {
    justify-content: unset;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy .form-group {
    margin-right: 15px;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy .product-info-links {
    display: none;
  }
  .product-container .awards-section {
    display: inline-block;
    justify-content: unset;
    align-items: unset;
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 20px 0;
    margin: 70px 0;
  }
  .product-container .awards-section .js-marquee {
    margin-right: 0 !important;
  }
  .product-container .awards-section .js-marquee .awards-marquee-items .awards-marquee-item {
    align-items: center;
    max-width: 225px;
    margin: 0 20px;
  }
  .product-container .awards-section .js-marquee .awards-marquee-items .awards-marquee-item .image {
    margin-right: 15px;
  }
  .product-container .tabs-row {
    padding: 0 20px 0;
  }

  .popup-alert.alert.alert-success {
    font-size: 1.4rem;
    width: 70%;
  }
}
@media (max-width: 630px) {
  .product-container {
    padding: 50px 0 70px;
  }
  .product-container .product-row {
    flex-direction: column;
  }
  .product-container .product-row .left-col {
    width: 100%;
    flex-basis: unset;
    margin-right: 0;
  }
  .product-container .product-row .left-col .prd-info.mobile-only {
    display: block;
    text-align: center;
  }
  .product-container .product-row .left-col .prd-info.mobile-only h1.big_title {
    margin-top: 0;
  }
  .product-container .product-row .left-col .product-thumbnails {
    max-width: 420px;
    margin: 0 auto;
  }
  .product-container .product-row .right-col {
    width: 100%;
    flex-basis: unset;
    margin-left: 0;
  }
  .product-container .product-row .right-col .right-col-wrapper {
    max-width: 100%;
  }
  .product-container .product-row .right-col .right-col-wrapper .prd-info.desktop-only {
    display: none;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy {
    max-width: 250px;
    margin: 20px auto 0;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy .price-list {
    flex-basis: 50%;
    order: 1;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy .form-group {
    order: 2;
    display: flex;
    justify-content: flex-end;
    flex-basis: 50%;
    margin-right: 0;
    margin-left: 30px;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .prd-buy .product-info-links {
    display: none;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field {
    padding: 0;
  }
  .product-container .product-row .right-col .right-col-wrapper #product .quantity-input-field .quantity {
    margin: 0 auto;
  }
  .product-container .awards-marquee {
    margin: 70px 0;
  }
  .product-container .awards-marquee .js-marquee .awards-marquee-items .awards-marquee-item {
    margin: 0 20px;
  }
  .product-container .separator-line {
    margin: 50px 0;
  }
  .product-container .tabs-row #product-tabs {
    flex-basis: 100%;
    margin-left: 0;
  }
  .product-container .tabs-row #product-tabs .product-tabs-headings .tab-heading + .tab-heading {
    margin-left: 35px;
  }

  .related-products {
    margin: 0;
    padding: 0 20px 70px;
  }
  .related-products .big_title {
    text-align: left;
    padding: 0 0 80px;
    margin: 0;
  }
  .related-products .product-item {
    margin: 0 auto;
    border-right: none;
  }
  .related-products .product-item .product-thumb {
    padding: 0;
  }
  .related-products .product-item .product-thumb .product-link .image {
    padding-bottom: 0;
  }
  .related-products .product-item .product-thumb .product-link .top .info .secondary_title {
    min-height: unset;
  }
}
/*========================================================*/
/*** Modals ***/
/*========================================================*/
body.modal-open {
  overflow: hidden;
}

.modal-title {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 143.3%;
  letter-spacing: 0;
  text-transform: capitalize;
}

.modal-body h2,
.modal-body h3,
.modal-body h4 {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 143.3%;
  letter-spacing: 0;
}

.modal-header {
  position: relative;
}

.modal-header .close {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
}

.modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.modal-body #remeber_password {
  display: inline-block;
  font-size: 1.4rem;
  margin: 10px 0 0;
}

/*========================================================*/
/*** Buttons ***/
/*========================================================*/
.btn-default {
  background-color: #1B1B1B;
}

.btn-default:hover {
  color: #fff;
  background-color: #1B1B1B;
  border-color: #1B1B1B;
  transition: linear 0.3s;
}

.btn-default.reverse {
  color: #fff;
  background-color: #1B1B1B;
}

.btn-default.reverse:hover {
  background-color: transparent;
  color: #000;
}

/*========================================================*/
/*** Account ***/
/*========================================================*/
.common-account #d_social_login {
  display: none;
}

.common-account,
#account-login {
  padding-top: 50px;
  padding-bottom: 50px;
}
.common-account .section_serif_title,
#account-login .section_serif_title {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 3.2rem;
}
.common-account input[type=checkbox],
.common-account input[type=radio],
#account-login input[type=checkbox],
#account-login input[type=radio] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #818181;
  border-radius: 50%;
  position: relative;
}
.common-account input[type=checkbox]::before,
.common-account input[type=radio]::before,
#account-login input[type=checkbox]::before,
#account-login input[type=radio]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: scale(0) translate(-50%, -50%);
  background-color: #fff;
  transform-origin: bottom left;
}
.common-account input[type=checkbox]::before,
#account-login input[type=checkbox]::before {
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.common-account input[type=radio]::before,
#account-login input[type=radio]::before {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
}
.common-account input[type=checkbox]:checked,
.common-account input[type=radio]:checked,
#account-login input[type=checkbox]:checked,
#account-login input[type=radio]:checked {
  background-color: #000;
  border: 2px solid #000;
}
.common-account input[type=checkbox]:checked::before,
.common-account input[type=radio]:checked::before,
#account-login input[type=checkbox]:checked::before,
#account-login input[type=radio]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}
.common-account input[type=checkbox]:disabled,
.common-account input[type=radio]:disabled,
#account-login input[type=checkbox]:disabled,
#account-login input[type=radio]:disabled {
  border-color: #EAEAEA;
  cursor: not-allowed;
}
.common-account .radio label,
.common-account .radio-inline,
#account-login .radio label,
#account-login .radio-inline {
  font-size: 1.4rem;
  display: inline-flex;
  align-items: center;
  padding: 0;
}
.common-account .radio label input,
.common-account .radio-inline input,
#account-login .radio label input,
#account-login .radio-inline input {
  margin: 0 10px 0 0;
}

#account-login .breadcrumb {
  display: none;
}

#account-login #content {
  padding-top: 80px;
}

#account-login .well {
  padding: 20px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.well.new-customer {
  text-align: center;
}

.well.new-customer-form:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

#account-login .new-customer-form h2 {
  text-align: center;
}

#account-login .well p {
  margin-bottom: 30px;
}

#account-login .register-btn {
  width: 100%;
}

#account-login .register-btn a.btn:hover {
  color: #000;
  background-color: #fff;
  transition: linear 0.3s;
}

#account-login .btn-default {
  width: 100%;
}

#account-login a.forgotten-pass {
  display: block;
  margin-top: 5px;
  color: #000;
  font-size: 1.4rem;
  font-weight: 300;
}

#account-login label {
  font-size: 1.3rem;
  line-height: 2.4rem;
  letter-spacing: 0.025em;
  font-weight: 400;
}

.common-account .breadcrumb {
  display: none;
}

.container.common-account.small-container {
  max-width: 500px;
}

.container.common-account {
  padding-bottom: 100px;
  float: none;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

#account-order {
  max-width: 900px;
}

#account-order .btn-default {
  max-width: 600px;
  margin: 0 auto;
}

#account-order thead > tr > td {
  font-size: 1.6rem;
}

#account-order tbody > tr > td {
  font-size: 1.4rem;
}

.common-account legend,
.search-container legend {
  display: none;
  font-size: 1.4rem;
  letter-spacing: 0.025em;
  font-weight: 500;
  color: #000;
  border-bottom: none;
  text-transform: uppercase;
}

.common-account ul > li > a {
  font-size: 1.6rem;
  line-height: 3rem;
  letter-spacing: 0.025em;
  font-weight: 400;
  text-decoration: none;
}

.common-account .agree-policy {
  align-items: center;
  margin: 0 0 15px;
}
.common-account .agree-policy input {
  margin: 0 10px 0 0;
}

.common-account .agree-policy > p {
  margin: 0;
}

.common-account .default-address,
.common-account .register-newsletter {
  display: block !important;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 1.6rem;
}

.common-account .btn-default {
  display: block;
  width: 100%;
}

.common-account a {
  color: #000000;
  text-decoration: underline;
}

.common-account .btn-default {
  color: #ffffff;
  text-decoration: none;
}

#account-login .main-title,
.common-account .main-title {
  margin-bottom: 20px;
}

.common-account .table-bordered,
.common-account .table-bordered > tbody > tr > td,
.common-account .table-bordered > tbody > tr > th,
.common-account .table-bordered > tfoot > tr > td,
.common-account .table-bordered > tfoot > tr > th,
.common-account .table-bordered > thead > tr > td,
.common-account .table-bordered > thead > tr > th {
  border: none;
  border-bottom: 1px solid #ddd;
  padding: 20px 15px;
}

.common-account .buttons {
  margin-top: 10px;
}

.common-account td.text-left a {
  color: #000;
  text-decoration: none;
}

.common-account td.text-right > a {
  text-decoration: none;
}

span.add-to-cart-icon svg,
span.add-to-cart-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.common-account .remove-item-icon svg .st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.common-account .remove-item-icon svg,
.common-account .remove-item-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
}

.common-account a.btn-primary {
  padding: 10px 20px;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 0;
  background-image: none;
  text-transform: uppercase;
  width: 50%;
  transition: linear 0.3s;
  min-height: 45px;
  text-decoration: none;
}

.common-account a.btn-primary:hover {
  color: #000;
  background-color: #fff;
  border-color: #000;
  transition: linear 0.3s;
}

.common-account .col-sm-2.control-label {
  width: 100%;
  text-align: left;
}

.common-account .col-sm-10 {
  display: block;
  width: 100%;
}

.common-account thead > tr > td {
  font-size: 1.4rem;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.common-account tbody > tr > td {
  font-size: 1.3rem;
  font-weight: 400;
  color: #000;
}

.common-account .table-hover > tbody > tr {
  opacity: 0.7;
  background-color: transparent;
}

.common-account .table-hover > tbody > tr:hover {
  opacity: 1;
}

.common-account td.text-right > a.edit-address-btn,
.common-account td.text-right > a.remove-address-btn,
.common-account td.text-right > a.btn-view-item {
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #000;
  border: none;
  border-radius: 0;
  background-image: none;
  text-transform: uppercase;
  text-decoration: underline;
}

.alert-success {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
  font-size: 1.3rem;
  line-height: initial;
  font-weight: 400;
  border-radius: 0;
  text-align: center;
}

.input-group.date button.btn.btn-default {
  padding: 9px 20px;
}

label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
  text-align: left;
}

.agree-text {
  margin-bottom: 20px;
}

.recipes-container {
  background-color: #9B775D;
  padding-top: 150px;
}
.recipes-container h2,
.recipes-container h3,
.recipes-container h4,
.recipes-container span,
.recipes-container p,
.recipes-container a {
  color: #fff;
}
.recipes-container input[type=checkbox] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: relative;
}
.recipes-container input[type=checkbox]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: scale(0) translate(-50%, -50%);
  background-color: #fff;
  transform-origin: bottom left;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.recipes-container input[type=checkbox]:checked {
  background-color: #000;
  border: 2px solid #000;
}
.recipes-container input[type=checkbox]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}
.recipes-container input[type=checkbox]:disabled {
  border-color: #EAEAEA;
  cursor: not-allowed;
}
.recipes-container .recipes-hero-container {
  display: none;
  margin: 0 0 clamp(70px, 15.625vw, 300px);
  padding: 0 clamp(20px, 5.2083vw, 100px);
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper {
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left {
  max-width: 530px;
  width: 100%;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left .info {
  max-width: 380px;
  margin: 0 0 80px;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left .info .big_title {
  display: inline-block;
  margin: 0 0 30px;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left .see-recipe-btn {
  margin-right: clamp(45px, 3.3854vw, 65px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left .link {
  align-items: center;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left .link .cooking-tip {
  max-width: 270px;
  width: 100%;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .left .link .cooking-tip h4 {
  margin: 0 0 20px;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .mobile {
  display: none;
}
.recipes-container .recipes-hero-container .recipes-hero-wrapper .mobile.see-recipe-btn {
  display: none;
}
.recipes-container .details {
  align-items: center;
}
.recipes-container .details .time {
  margin: 0 20px;
}
.recipes-container .details span {
  display: flex;
  align-items: center;
  font-family: neue-haas-grotesk-display, sans-serif;
}
.recipes-container .details span svg {
  display: inline-block;
  margin: 0 10px 0 0;
}
.recipes-container .recipes-grid-container {
  padding: 0 clamp(20px, 5.2083vw, 100px);
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper {
  max-width: 1370px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .blog_category_recipes_wrapper {
  scroll-margin-top: 100px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container {
  max-width: 255px;
  width: 100%;
  margin-right: 50px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters {
  border: 1px solid #fff;
  border-radius: 5px;
  margin-bottom: 50px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .title {
  padding: 25px 40px;
  background-color: #fff;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .title p {
  margin: 0;
  color: #9B775D;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac {
  padding: 15px 30px;
  margin: 0;
  border: none;
  background-color: unset;
  box-sizing: border-box;
  transition: background-color 0.4s ease;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac.is-active {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: #805D45;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac.is-active .ac-header .ac-trigger::after {
  content: "–";
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-trigger {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 145%;
  padding: 0;
  color: #fff;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-trigger:focus {
  color: #fff;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-trigger::after {
  content: "+";
  text-align: center;
  width: 15px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: unset;
  right: unset;
  top: unset;
  margin-left: 5px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel {
  max-height: 370px;
  overflow-y: auto;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel::-webkit-scrollbar {
  width: 6px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel::-webkit-scrollbar-track {
  border-radius: 44px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel::-webkit-scrollbar-thumb {
  background: #ffffff30;
  border-radius: 44px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel::-webkit-scrollbar-thumb:hover {
  background: #ffffff60;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel li {
  margin: 8px 0;
  display: flex;
  align-items: center;
  color: #fff;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container .filters .accordion-container .ac .ac-panel li label {
  margin: 0 0 0 10px;
  flex-basis: calc(100% - 30px);
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .sticky-grid {
  max-width: 800px;
  width: 100%;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .grid {
  justify-content: space-between;
  margin-left: 15px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .left,
.recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .right {
  max-width: 310px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .left {
  margin-right: 15px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .right {
  padding-top: clamp(200px, 17.7083vw, 340px);
  margin-left: 15px;
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .recipe-slide {
  max-width: 310px;
  margin: 0 0 clamp(70px, 7.2917vw, 140px);
}
.recipes-container .recipes-grid-container .recipes-grid-wrapper .pagination {
  width: 100%;
}
.recipes-container .recipe-filters-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  transition: transform 0.4s ease;
}
.recipes-container .recipe-filters-btn #recipes-filter-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: #fff;
  border-radius: 50%;
}
.recipes-container .recipe-filters-btn.active {
  transform: scale(25);
}
.recipes-container .recipe-filters-btn.active #recipes-filter-btn path {
  fill: none;
}
.recipes-container .recipes-mobile-filters {
  position: fixed;
  top: 0;
  right: -130%;
  bottom: 0;
  z-index: 9999;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.3s ease 0.2s;
  background-color: #805D45;
}
.recipes-container .recipes-mobile-filters.active {
  left: 0;
  opacity: 1;
  right: 0;
}
.recipes-container .recipes-mobile-filters .header {
  padding: 25px 20px;
  background-color: #9B775D;
  align-items: center;
  justify-content: space-between;
}
.recipes-container .recipes-mobile-filters .header #close-recipes-filter-btn {
  display: block;
}
.recipes-container .recipes-mobile-filters .filters-list {
  height: 80vh;
  overflow-y: scroll;
  padding-bottom: 40px;
}
.recipes-container .recipes-mobile-filters .filter {
  padding: 0 20px;
  margin: 0 0 40px;
}
.recipes-container .recipes-mobile-filters .filter input[type=checkbox] {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
}
.recipes-container .recipes-mobile-filters .filter input[type=checkbox]:checked {
  background-color: #000;
  border: 1px solid #000;
}
.recipes-container .recipes-mobile-filters .filter .title {
  padding: 10px 0;
  border-bottom: 1px solid #fff;
  margin: 0 0 25px;
}
.recipes-container .recipes-mobile-filters .filter li {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  color: #fff;
}
.recipes-container .recipes-mobile-filters .filter li label {
  margin: 0 0 0 10px;
}
.recipes-container .recipes-mobile-filters .submit-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 25px;
}
.recipes-container .recipes-mobile-filters .submit-container a {
  color: #000;
}

@media (max-width: 991px) {
  .recipes-container {
    padding-top: 50px;
  }
  .recipes-container .recipes-hero-container {
    padding: 0;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper {
    flex-direction: column-reverse;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .left {
    padding: 0 20px;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .left .info {
    display: none;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .left .info.mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: unset;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .left .info.mobile .details span {
    margin: 0 0 15px;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .left .link .see-recipe-btn {
    display: none;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .left .link .cooking-tip {
    max-width: unset;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .right {
    position: relative;
    margin: 0 0 30px;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .right .mobile {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 120px 20px 30px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .recipes-container .recipes-hero-container .recipes-hero-wrapper .mobile {
    display: block;
  }
  .recipes-container .recipes-grid-container .recipes-grid-wrapper .filters-container {
    display: none;
  }
  .recipes-container .recipes-grid-container .recipes-grid-wrapper .grid,
.recipes-container .recipes-grid-container .recipes-grid-wrapper .sticky-grid {
    margin: 0 auto;
  }
  .recipes-container .recipe-filters-btn.mobile {
    display: block;
  }
}
@media (max-width: 660px) {
  .recipes-container .recipes-grid-container .recipes-grid-wrapper .grid {
    align-items: center;
    flex-direction: column;
  }
  .recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .left {
    margin: 0;
  }
  .recipes-container .recipes-grid-container .recipes-grid-wrapper .grid .right {
    margin: 0;
    padding-top: 0;
  }
}
.page_terms_and_conditions .gridy_item {
  display: grid;
  text-align: center;
  max-width: 1100px;
  padding-bottom: 220px;
  margin: 0 auto;
  padding-top: 8.073vw;
}
.page_terms_and_conditions .gridy_item .big_title {
  padding-top: 225px;
  padding-bottom: 110px;
}
.page_terms_and_conditions .main_button {
  padding-bottom: 20px;
  text-align: left;
}
.page_terms_and_conditions .main_button p {
  margin: 0;
}
.page_terms_and_conditions .gridy_item .main_text {
  padding-bottom: 20px;
  text-align: left;
}
.page_terms_and_conditions .gridy_item .main_text p {
  margin: 0;
}
@media only screen and (max-width: 1280px) {
  .page_terms_and_conditions .gridy_item {
    max-width: 730px;
  }
  .page_terms_and_conditions .gridy_item.big_title {
    padding: 125px 0 110px 0;
  }
}
@media only screen and (max-width: 767px) {
  .page_terms_and_conditions .gridy_item {
    padding: 0 20px;
    padding-bottom: 80px;
    text-align: center;
  }
  .page_terms_and_conditions .gridy_item.big_title {
    padding: 80px 0 80px 0;
  }
}

.page_who_we_are {
  background-color: #9B775D;
}
.page_who_we_are #canvas1 {
  width: 100%;
}
.page_who_we_are .full_width_img_section {
  position: relative;
}
.page_who_we_are .full_width_img_section > .container-img.overlay:before {
  content: "";
  background-color: rgba(34, 34, 34, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.page_who_we_are .full_width_img_section.cursor-section {
  position: relative;
}
.page_who_we_are .full_width_img_section.cursor-section .main_wrapper {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 50%;
}
.page_who_we_are .full_width_img_section.cursor-section .main_wrapper .golden_letters {
  position: unset;
  top: unset;
  left: unset;
  width: unset;
}
.page_who_we_are .full_width_img_section.cursor-section .main_wrapper .bubble-cursor {
  position: absolute;
  top: 0;
  left: 0;
}
.page_who_we_are .full_width_img_section.cursor-section .main_wrapper .bubble-cursor .mask-circle {
  width: 100%;
  max-width: 430px;
  height: 430px;
}
.page_who_we_are .full_width_img_section.cursor-section .main_wrapper .bubble-cursor .mask-circle svg {
  width: 100%;
  max-width: 430px;
  height: 430px;
}
.page_who_we_are .full_width_img_section.cursor-section .main_wrapper .bubble-cursor .mask-circle svg .st0 {
  fill: #fff;
  stroke: #fff;
  stroke-miterlimit: 10;
}
@media (max-width: 1024px) {
  .page_who_we_are .full_width_img_section.cursor-section .main_wrapper {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .page_who_we_are .full_width_img_section.cursor-section .main_wrapper {
    position: relative;
    background: #fff;
    width: 100%;
    left: 0;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .page_who_we_are .full_width_img_section.cursor-section .main_wrapper .golden_letters {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
.page_who_we_are .section-paragraph {
  text-align: left;
}
.page_who_we_are .section-paragraph_title {
  margin-bottom: 30px;
}
.page_who_we_are .section-paragraph_text {
  font-weight: normal;
}

.contact_page {
  /*get in touch*/
  /*contact form*/
  /*========================================================*/
  /*** CONTACT ***/
  /*========================================================*/
}
.contact_page p {
  margin: 0;
  padding: 0;
}
.contact_page .gridy_contact {
  display: grid;
  grid-template-columns: 2fr 3fr;
}
.contact_page .get_in_touch {
  max-width: 800px;
  padding-top: 275px;
  padding-bottom: 375px;
  background-color: #FFECC3;
  display: grid;
  justify-content: center;
}
.contact_page .get_in_touch .main_button {
  font-size: 1.4rem;
  line-height: 2rem;
  padding-top: 80px;
}
.contact_page .get_in_touch .main_button .address_contact {
  display: block;
  padding-bottom: 30px;
  max-width: 200px;
}
.contact_page .get_in_touch .tel_contact {
  display: block;
  max-width: 375px;
}
.contact_page .get_in_touch .section_serif_title {
  font-weight: 400;
  display: block;
  max-width: 150px;
}
.contact_page .contact_form {
  max-width: 1115px;
  margin: 0 auto;
  padding-top: 270px;
  padding-bottom: 370px;
  background-color: #fff;
}
.contact_page .contact_form input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #000;
  width: 315px;
  padding-left: 25px;
  padding-bottom: 20px;
  border-radius: 0;
  box-shadow: none;
}
.contact_page .contact_form input:focus {
  border-bottom: 2px solid #000;
  margin-bottom: -1px;
}
.contact_page .contact_form textarea:focus {
  border-bottom: 2px solid #000;
  margin-bottom: -1px;
}
.contact_page .gridy_contact_form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 715px;
  grid-gap: 40px;
}
.contact_page .contact_form textarea {
  grid-column-start: 1;
  grid-column-end: 3;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #000;
  padding: 0px 0px 70px 20px;
  resize: none;
  border-radius: 0;
  box-shadow: none;
  width: 690px;
}
.contact_page ::placeholder {
  opacity: 0.5;
}
.contact_page .terms_and_submit {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  padding-top: 30px;
}
.contact_page .terms_and_submit input[type=checkbox] {
  width: 25px;
  height: 25px;
  margin-top: -1.5px;
  border-radius: 50%;
  border: 1px solid #000;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  pointer-events: auto;
}
.contact_page input[type=checkbox]:checked {
  background-color: #000;
}
.contact_page .section_cta {
  display: flex;
  justify-content: end;
  background: none;
  color: inherit;
  border: none;
  padding-top: 10px;
  padding-left: 0;
  padding-right: 15px;
  padding-bottom: 0;
}
.contact_page .terms_and_submit .submit_contact {
  padding-left: 210px;
}
.contact_page .terms_and_submit .main_text {
  margin-left: 10px;
}
.contact_page .email-underl {
  text-decoration: underline;
}
.contact_page .form-group {
  margin: 0;
}
.contact_page .gridy_form_item {
  width: 335px;
}
.contact_page .textarea_danger {
  margin-top: -145px;
}
.contact_page #information-contact .breadcrumb {
  display: none;
}
.contact_page #information-contact #content {
  padding-bottom: 70px;
}
.contact_page #information-contact h1 {
  text-align: center;
  font-size: 2.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 35px 0 30px;
}
.contact_page #information-contact h3 {
  font-size: 1.4rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 600;
  color: #000;
  margin: 0;
  text-transform: uppercase;
}
.contact_page #information-contact form h3 {
  padding-bottom: 20px;
}
.contact_page ul.contact-info > li,
.contact_page ul.contact-info > li > a {
  font-size: 1.3rem;
  line-height: 2.4rem;
  letter-spacing: 0.025em;
  font-weight: 500;
  color: #838383;
}
.contact_page ul.contact-info > li > a.email-address {
  color: #000;
  text-decoration: underline;
}
.contact_page #information-contact .stores-info {
  padding-top: 50px;
}
.contact_page #information-contact .addresses {
  padding-bottom: 40px;
}
@media only screen and (max-width: 1280px) {
  .contact_page .contact_form {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 1028px) {
  .contact_page .get_in_touch .main_button {
    padding-top: 50px;
  }
  .contact_page .gridy_contact {
    grid-template-columns: 1fr;
  }
  .contact_page .get_in_touch {
    max-width: none;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .contact_page .contact_form {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .contact_page .textarea_danger {
    margin-top: -270px;
  }
  .contact_page .contact_form input:hover {
    border-bottom: 2px solid #000;
    margin-bottom: 0;
  }
  .contact_page .contact_form textarea:hover {
    border-bottom: 2px solid #000;
    margin-bottom: 0;
  }
  .contact_page .contact_form input {
    padding-top: 20px;
  }
  .contact_page .contact_form textarea {
    padding-top: 20px;
  }
  .contact_page .terms_and_submit .main_text {
    margin-left: 30px;
    margin-top: -24px;
  }
  .contact_page .contact_form {
    padding-top: 80px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .contact_page .gridy_contact {
    grid-template-columns: 1fr;
  }
  .contact_page .get_in_touch .main_button {
    padding-top: 60px;
  }
  .contact_page .get_in_touch {
    padding: 100px 0 90px 0;
    text-align: center;
  }
  .contact_page .get_in_touch .main_button .address_contact {
    display: block;
    padding-bottom: 30px;
    max-width: 375px;
    padding-left: 85px;
    padding-right: 85px;
  }
  .contact_page .get_in_touch .section_serif_title {
    max-width: none;
  }
  .contact_page .get_in_touch .section_serif_title {
    display: inline;
  }
  .contact_page .gridy_contact_form,
.contact_page .contact_form textarea {
    grid-template-columns: 1fr;
    max-width: 315px;
    grid-gap: 0;
  }
  .contact_page .contact_form textarea {
    grid-column-start: 1;
    grid-column-end: 1;
    padding: 20px 0px 10px 20px;
  }
  .contact_page .terms_and_submit {
    grid-template-columns: 1fr;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .contact_page .terms_and_submit .radio_terms {
    text-align: left;
  }
  .contact_page .terms_and_submit .submit_contact {
    padding-left: 0px;
    text-align: center;
    padding-top: 40px;
  }
  .contact_page .section_cta {
    display: flex;
    justify-content: center;
    padding-bottom: 80px;
  }
  .contact_page .terms_and_submit input[type=checkbox] {
    padding: 0px;
    pointer-events: auto;
  }
}

.page_trea.page_category {
  background-color: #1B1B1B;
}
.page_trea .page-container {
  background-color: #1B1B1B;
}
.page_trea .page-container * {
  color: #fff;
}
.page_trea .page-container .welcome_section {
  border-color: #fff;
}
.page_trea .page-container .centered_img_section {
  max-width: 1400px;
}
.page_trea .page-container .centered_img_section .centered_img {
  overflow: hidden;
}
@media (max-width: 1024px) {
  .page_trea .page-container .centered_img_section {
    padding: 0;
  }
}
.page_trea .page-container .product_section * {
  color: #000;
}

.page_sustainability {
  background-color: #D9DEC9;
}

.page_navarino.page_category {
  background-color: #7B7161;
}
.page_navarino .page-container {
  background-color: #7B7161;
}
.page_navarino .page-container * {
  color: #fff;
}
.page_navarino .page-container .welcome_section {
  max-width: 750px;
  border-color: #fff;
}
.page_navarino .page-container .welcome_section .big_title svg {
  height: 100px;
}
.page_navarino .page-container .welcome_section .main_text {
  text-align: justify;
  max-width: none;
}
@media (max-width: 1024px) {
  .page_navarino .page-container .welcome_section .big_title svg {
    height: 70px;
  }
}
@media (max-width: 768px) {
  .page_navarino .page-container .welcome_section {
    margin-inline: 25px;
  }
}
.page_navarino .page-container .centered_img_section {
  max-width: 1400px;
}
.page_navarino .page-container .centered_img_section .centered_img {
  overflow: hidden;
}
@media (max-width: 768px) {
  .page_navarino .page-container .centered_img_section .centered_img .img-responsive .mobile {
    width: 100%;
  }
}
.page_navarino .page-container .centered_img_section .centered_img img {
  position: relative;
  transform: scale(1);
  top: 0;
}
@media (max-width: 1024px) {
  .page_navarino .page-container .centered_img_section {
    padding: 0;
  }
}
.page_navarino .page-container .product_section * {
  color: #000;
}
.page_navarino .page-container .photo_text_first_paragraph, .page_navarino .page-container .photo_text_second_paragraph {
  text-align: left;
}
.page_navarino .page-container .photo_text_first_paragraph_title, .page_navarino .page-container .photo_text_second_paragraph_title {
  margin-bottom: 30px;
}
.page_navarino .page-container .photo_text_first_paragraph_text, .page_navarino .page-container .photo_text_second_paragraph_text {
  font-weight: normal;
}

.page_recipes {
  background-color: #9B775D;
}
.page_recipes .page-container {
  max-width: 1500px;
  margin: 0 auto;
  overflow-x: hidden;
}
.page_recipes .page-container .title_section {
  margin: 0 auto;
  padding: 250px 0 400px 0;
  text-align: center;
}
.page_recipes .page-container .title_section * {
  color: #fff;
}
.page_recipes .page-container .title_section .info {
  margin: 0 auto clamp(30px, 5.2083vw, 100px);
  padding: 0 20px;
  max-width: 590px;
}
.page_recipes .page-container .title_section .info h3,
.page_recipes .page-container .title_section .info .details {
  margin-top: 40px;
  justify-content: center;
}
.page_recipes .page-container .title_section .info h3 .time,
.page_recipes .page-container .title_section .info .details .time {
  margin: 0 20px;
}
.page_recipes .page-container .title_section .info h3 span,
.page_recipes .page-container .title_section .info .details span {
  display: flex;
  align-items: center;
}
.page_recipes .page-container .title_section .info h3 span svg,
.page_recipes .page-container .title_section .info .details span svg {
  display: inline-block;
  margin: 0 10px 0 0;
}
.page_recipes .page-container .recipe_text {
  background-color: #fff;
  padding-top: 370px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.page_recipes .page-container .recipe_text .top_image {
  top: 0;
}
.page_recipes .page-container .recipe_text .text_container {
  max-width: 550px;
  margin: 50px auto;
  margin-bottom: 100px;
  text-align: left;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists {
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item {
  flex: 1;
  margin-right: 15px;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item .italic_text, .page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item .free-shipping-calculator > *, .page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item .shipping-duties > * {
  margin-bottom: 30px;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item ul li {
  margin-bottom: 15px;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item ul.product-used li a:hover {
  text-decoration: underline;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item.share {
  text-align: right;
}
.page_recipes .page-container .recipe_text .text_container .recipe_lists .flex_item.share a:after {
  transform: rotate(-45deg);
}
.page_recipes .page-container .recipe_text .text_container .italic_text, .page_recipes .page-container .recipe_text .text_container .free-shipping-calculator > *, .page_recipes .page-container .recipe_text .text_container .shipping-duties > * {
  margin-bottom: 10px;
}
.page_recipes .page-container .recipe_text .text_container .main_text {
  margin-bottom: 40px;
}
.page_recipes .page-container .recipe_text .product-slide {
  max-width: 400px;
  border: none;
  margin: 0 auto;
  padding: 20px;
}
.page_recipes .page-container .main-recipe-text h4 {
  font-family: "Fedra Serif B Pro", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 189%;
  margin-bottom: 10px;
}
.page_recipes .page-container .main-recipe-text p {
  margin-bottom: 40px;
}
.page_recipes .page-container .homepage-recipes-container {
  padding-bottom: 0;
}
.page_recipes .page-container .homepage-recipes-container .recipes-slider {
  margin: unset;
  display: flex;
  justify-content: space-between;
  cursor: auto;
  gap: 30px;
}
.page_recipes .page-container .homepage-recipes-container .recipes-slider .recipe-slide {
  margin: unset;
}
.page_recipes .products-loader.load-more {
  margin-bottom: 100px;
}
.page_recipes .recipes-slider.slick-slider {
  display: block !important;
  width: 100% !important;
}

@media (max-width: 1500px) {
  .page_single_recipe .page-container {
    margin-left: 85px;
    margin-right: 85px;
  }
  .page_single_recipe .page-container .title_section {
    padding-bottom: 350px;
  }
}
@media (max-width: 1200px) {
  .page_single_recipe .page-container {
    margin-left: 50px;
    margin-right: 50px;
  }
  .page_single_recipe .page-container .title_section {
    padding-top: 150px;
    padding-bottom: 350px;
  }
  .page_single_recipe .page-container .recipe_text {
    padding-top: 300px;
  }
}
@media (max-width: 1024px) {
  .page_single_recipe .page-container .title_section {
    padding-bottom: 250px;
  }
  .page_single_recipe .page-container .recipe_text {
    padding-top: 250px;
  }
  .page_single_recipe .page-container .homepage-recipes-container {
    overflow-x: auto;
  }
  .page_single_recipe .page-container .homepage-recipes-container .top {
    padding: 0;
  }
  .page_single_recipe .page-container .homepage-recipes-container .recipes-slider {
    width: max-content;
    overflow-x: auto;
  }
  .page_single_recipe .page-container .homepage-recipes-container .recipes-slider .recipe-slide .info .details {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .page_single_recipe .page-container .homepage-recipes-container .recipes-slider .recipe-slide .info .details .time {
    margin: 0;
  }
}
@media (max-width: 768px) {
  .page_single_recipe .page-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .page_single_recipe .page-container .title_section {
    padding-bottom: 250px;
  }
  .page_single_recipe .page-container .recipe_text {
    padding-top: 200px;
  }
  .page_single_recipe .page-container .recipe_text .text_container .recipe_lists .flex_item {
    flex: 1 0 50%;
  }
  .page_single_recipe .page-container .recipe_text .text_container .recipe_lists .flex_item.share {
    margin-top: 40px;
    text-align: left;
    flex: 0 1 100%;
  }
  .page_single_recipe .page-container .homepage-recipes-container {
    overflow-x: auto;
    padding-left: 20px;
  }
  .page_single_recipe .page-container .homepage-recipes-container .recipes-slider {
    width: max-content;
    overflow-x: scroll;
  }
  .page_single_recipe .page-container .homepage-recipes-container .recipes-slider .recipe-slide .info .details {
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
  }
}
@media (max-width: 440px) {
  .page_single_recipe .page-container .title_section {
    padding-top: 100px;
    padding-bottom: 200px;
  }
  .page_single_recipe .page-container .recipe_text {
    padding-top: 150px;
  }
  .page_single_recipe .page-container .recipe_text .top_image {
    padding: 0 20px;
    max-width: 350px;
    width: 100%;
  }
}
.search-container {
  padding: 100px 20px 100px;
}
.search-container .category-wrapper .search-block-wrapper {
  padding: 0 clamp(0px, 7.813vw, 150px) 35px;
}
.search-container .category-wrapper .search-block-wrapper .section_serif_title {
  font-size: 2rem;
}
.search-container .category-wrapper .search-block-wrapper .search-choices {
  padding: 15px 0;
}
.search-container .category-wrapper .search-block-wrapper .search-choices .left {
  flex-basis: 33.333%;
  margin-right: 30px;
}
.search-container .category-wrapper .search-block-wrapper .search-choices .right {
  flex-basis: 33.333%;
}
.search-container .category-wrapper .search-block-wrapper .search-checkboxes {
  padding: 10px 0 30px;
}
.search-container .category-wrapper .search-block-wrapper .search-checkboxes .search-checkbox-item {
  margin-right: 30px;
  align-items: center;
}
.search-container .category-wrapper .search-block-wrapper .search-checkboxes .search-checkbox-item label {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 172.5%;
  padding-left: 10px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .search-container .category-wrapper .search-block-wrapper {
    padding: 0 0 35px;
  }
  .search-container .category-wrapper .search-block-wrapper .search-choices .left {
    flex-basis: 50%;
  }
  .search-container .category-wrapper .search-block-wrapper .search-choices .right {
    flex-basis: 50%;
  }
}
@media (max-width: 767px) {
  .search-container {
    padding: 50px 20px;
  }
  .search-container .category-wrapper .search-block-wrapper .search-choices {
    flex-wrap: wrap;
  }
  .search-container .category-wrapper .search-block-wrapper .search-choices .left {
    flex-basis: 100%;
    margin-right: 0;
  }
  .search-container .category-wrapper .search-block-wrapper .search-choices .right {
    flex-basis: 100%;
  }
  .search-container .category-wrapper .search-block-wrapper .search-checkboxes {
    flex-direction: column;
  }
}
@media (max-width: 630px) {
  .search-container .category-wrapper .search-block-wrapper .search-checkboxes .search-checkbox-item {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .search-container .category-wrapper .search-block-wrapper .search-checkboxes .search-checkbox-item label {
    padding-left: 0;
  }
  .search-container .category-wrapper .btn-default.medium-button {
    max-width: 100%;
  }
}
/*========================================================*/
/*** CHECKOUT ***/
/*========================================================*/
.info-back-top {
  display: none;
}

.navbar {
  padding: 30px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  background: #000000;
  border: 1px solid #000000;
}

.tabbable .nav-tabs > li.active {
  border-bottom: 1px solid #000000;
}

.tabbable .nav-tabs > li.active > a,
.tabbable .nav-tabs > li.active > a:focus,
.tabbable .nav-tabs > li.active > a:hover,
.tabbable .nav-tabs > li > a:hover {
  color: #000000;
}

.text-muted {
  color: #000000;
}

.btn.btn-success,
.btn.btn-success-alternate {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  min-height: 45px;
  transition: linear 0.3s;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  border-radius: 0;
  padding: 10px 20px;
}

.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success-alternate:hover,
.btn.btn-success-alternate:focus {
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  transition: linear 0.3s;
}

.group .inputMaterial {
  font-size: 1.3rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 400;
  border: 1px solid #ddd;
  border-radius: 0;
  color: #000;
  padding: 10px 15px;
  min-height: 45px;
}

.group.filled input:-webkit-autofill,
.group.filled input:-webkit-autofill:hover,
.group.filled input:-webkit-autofill:focus {
  border: 1px solid #000;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.group label {
  font-size: 1.3rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 400;
  color: #a4a4a4;
}

.footer-separator a,
a.editAddress {
  color: #000;
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 300;
}

.agreeText label span,
.form-group .checkbox.is_checkbox label {
  font-size: 1.3rem;
  line-height: initial;
  color: #000;
  font-weight: 400;
}

a.agree.pointer,
.agreeText label span a.agree.pointer {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 800;
  color: #000;
}

.group.focus label {
  background: #fff;
}

.group.focus label {
  color: #000;
}

.section-heading {
  font-size: 2rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 400;
  color: #000;
}

.inlineaddress {
  background-color: #fff;
}

.inlineaddress h3.address_title {
  font-size: 2rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 400;
  color: #000;
}

.info-back-checkout {
  box-shadow: none;
  color: #000000;
  background: #ececec;
  border: 1px solid #ececec;
  border-radius: 0;
}

.cartelement i.icon.ion-trash-b {
  color: #000;
}

.checkbox.is_checkbox.gift_wrapper label {
  font-size: 13px;
  padding-left: 2px;
  color: #000;
}

.panel-address .panel-address-heading,
.container_panel .heading {
  background: #ffffff;
  color: #000000;
}

.info-back-checkout .input-checkbox:checked:after {
  color: #000;
}

.info-back-checkout .checkbox.is_checkbox input[type=checkbox].input-checkbox {
  background-color: transparent;
}

.info-back-checkout .input-checkbox:checked {
  box-shadow: none;
  background-color: transparent;
}

.panel-address {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #ddd;
}

.input-radio:checked {
  box-shadow: 0 0 0 10px #606c6f inset;
}

.selected-address {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: initial;
  color: #000;
}

.container_panel label[for="flat.flat"],
.container_panel textarea.form-control {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: initial;
  color: #000;
}

small.pull-right a {
  color: #000;
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 400;
}

div#progress-continue {
  border-radius: 0;
  min-height: 45px;
}

.progress span {
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  border: 1px solid #000;
  box-shadow: none;
}

.progress-continue-disabled {
  border-radius: 0;
  min-height: 45px;
  border: none;
  background-color: #ececec;
  -webkit-box-shadow: none;
  box-shadow: none;
}

div#step_address_panel,
div#step_payment_panel {
  padding: 50px 0 70px;
}

#coupon-panel .input-group-addon,
#voucher-panel .input-group-addon {
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: 0;
}

#coupon-panel .input-group.group input.inputMaterial,
#voucher-panel .input-group.group input.inputMaterial {
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 1.3rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 400;
  color: #000;
  padding: 10px 15px;
}

.input-group.group i.fa.fa-scissors,
.input-group.group i.fa.fa-tag {
  display: none;
}

#coupon-panel .input-group.group label,
#voucher-panel .input-group.group label {
  font-size: 1.3rem;
  line-height: initial;
  letter-spacing: 0.025em;
  font-weight: 400;
  color: #a4a4a4;
  top: 12px;
}

#xcart .panel-green1 .panel-heading {
  color: #000;
}

.editcart.xwishlist {
  color: #000000;
}

#totals {
  color: #000;
}

.panel.panel-green1 .panel-heading {
  font-size: 1.4rem;
  line-height: initial;
  font-weight: 800;
  color: #000;
  border-bottom: none;
  padding: 15px 0;
}

footer.footer.footer-checkout {
  padding-top: 50px;
}

.footercontact,
.footercontact a {
  padding-top: 2px;
  margin-top: 0 !important;
  font-size: 1.4rem;
  line-height: initial;
  color: #000;
  font-weight: 600;
}

.xcart .media-heading a {
  color: #000;
}

.input-checkbox {
  box-shadow: 0 0 0 10px #ffffff inset;
  border: 1px solid #ddd;
  border-radius: 0;
}

.input-checkbox,
.input-checkbox:hover,
.input-checkbox:checked {
  box-shadow: none;
  border: 1px solid #000;
  border-radius: 0;
}

.input-checkbox:checked:after {
  color: #000;
  right: 0;
  top: 1px;
}

#xcart .media-body a {
  color: #000;
}

.youpaytext {
  font-size: 1.4rem;
  color: #000;
  line-height: initial;
}

#paymentPage .panel-group {
  border: 1px solid #ddd;
  border-radius: 0;
}

#paymentPage .panel-group label {
  color: #000;
}

a.heading-panel[aria-expanded=true]::before {
  box-shadow: 0 0 0 10px #606c6f inset;
}

.pchange a.underline {
  color: #666;
  font-size: 1.1rem;
  line-height: initial;
  font-weight: 400;
}

.pchange a.underline:hover {
  color: #666;
  text-decoration: none;
}

#undo1.fa-edit:before,
#click2 .fa.fa-edit:before {
  display: none;
}

.panel-address-heading.address-type i.fa.fa-money,
#shipping_method i.fa.fa-truck,
#order-comment i.fa.fa-comment,
.panel-heading.xcollapsable i.fa.fa-shopping-cart,
.panel.panel-green1.couponpanel i.fa.fa-tags,
.panel.panel-green1.totalspanel i.fa.fa-money {
  display: none;
}

.checkout-logo span.logo-icon svg,
.checkout-logo span.logo-icon {
  margin: 0 auto;
  display: block;
  width: 277px;
  height: 34px;
  position: relative;
  top: 7px;
}

.info-back-checkout .input-checkbox,
.info-back-checkout .input-checkbox:checked {
  border: none;
}

#button-payment-disabled i.fa.fa-lock,
#step1 .fa-lock:before {
  display: none;
}

.shippingS {
  font-size: 1.4rem;
  font-weight: 600;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.login-panel-bg .login-section {
  border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.container_panel {
  box-shadow: none;
  border-radius: 0;
}

.container_panel .heading,
.panel-address .panel-address-body .address-list {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#addressPage .text-justified {
  border-bottom: none;
}

.container_panel .shipping-table,
.container_panel textarea {
  border-radius: 0;
}

.lborder {
  border-color: #000000;
}

.inlineaddress {
  box-shadow: none;
  border: 1px solid #ddd;
}

.panel-address .panel-address-body .address-list label.address-label.selected {
  color: #000000;
  border-left: 4px solid #000000;
}

#form_edit_address .progress-continue-disabled {
  min-height: 46px;
}

.editcart.minus,
.editcart.xwishlist {
  border-radius: 0;
}

.editcart.minus:hover,
.editcart.plus:hover,
.editcart.xremove:hover,
.editcart.xwishlist:hover {
  background: #000;
  border-color: #000;
}

#nprogress .bar {
  background: #000;
}

.checkout-page input:-webkit-autofill + label,
.checkout-page input:focus + label,
.checkout-page input:valid + label {
  color: #000;
  top: -8px !important;
  background: #fff;
}

.panel-address .panel-address-body .address-list,
.panel-address .panel-address-body .address-list label.address-label:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.checkout-left {
  border-right: 1px solid #bcbcbc;
  padding-right: clamp(30px, 4.1667vw, 80px);
}

.checkout-right {
  padding-left: clamp(30px, 4.1667vw, 80px);
}

#d_quickcheckout #d_logo .hidden {
  display: inline-block !important;
}

#d_quickcheckout #d_logo .megamenu-style-dev {
  display: none !important;
}

#d_quickcheckout {
  padding: 0px clamp(30px, 4.1667vw, 80px) clamp(50px, 5.2083vw, 100px);
  max-width: 1800px;
  width: 100%;
}

#d_quickcheckout .section-title {
  margin: 0 0 clamp(100px, 7.8125vw, 150px);
  padding: 0 20px;
}

#d_quickcheckout a {
  color: #000;
  text-decoration: underline;
}

#shipping_method_list,
#shipping_method_list span,
#d_quickcheckout label,
#d_quickcheckout label.control-label,
#d_quickcheckout input[type=text].form-control,
#d_quickcheckout input[type=password].form-control,
#d_quickcheckout input[type=email].form-control,
#d_quickcheckout input[type=tel].form-control,
#d_quickcheckout textarea.form-control,
#d_quickcheckout select.form-control,
#d_quickcheckout .alert {
  font-size: 1.6rem;
  line-height: 2.4rem;
  letter-spacing: 1px;
}

#d_quickcheckout #shipping_address_form label {
  display: none;
}

#confirm_coupon,
#qc_confirm_order {
  width: 100%;
  height: unset;
  padding: 15px;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.45em;
}

#coupon {
  width: 100%;
  height: unset;
}

#confirm_coupon {
  display: block;
  max-width: 135px;
  width: 100%;
  padding: 18px 30px;
}

#confirm_coupon:hover #qc_confirm_order:hover,
#qc_confirm_order:disabled {
  color: #232323;
  background-color: #fff;
  border-color: #232323;
  transition: linear 0.3s;
}

#d_quickcheckout .text-danger {
  margin: 0 !important;
}

#d_quickcheckout button.decrease,
#d_quickcheckout button.increase {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding: 0;
  margin: 5px;
  height: 20px;
  width: 20px;
  transition: all 0.3s ease-in-out;
}
#d_quickcheckout button.decrease .fa,
#d_quickcheckout button.increase .fa {
  font-size: 10px;
}

#d_quickcheckout button.delete {
  padding: 5px;
  border-radius: 0;
  /* color: #ffffff; */
  text-shadow: unset;
  background-color: unset;
  background-image: none;
  background-repeat: no-repeat;
  border: none;
  border-color: unset;
}

#d_quickcheckout button.delete .fa-times:before {
  content: "";
}

#d_quickcheckout button.delete span {
  display: block;
  width: 12px;
}

#d_quickcheckout .qc-product-qantity {
  max-width: 40px;
  min-height: 35px;
  padding: 0;
}

#d_quickcheckout .panel-body {
  padding: 15px 0;
}

#d_quickcheckout .panel-title,
#payment_view > h2,
#payment_address_heading_heading {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 3.2rem;
}

#d_quickcheckout .panel-default > .panel-heading {
  background-color: unset;
  border-radius: 0;
  border: none;
  padding: 10px 0;
}

#d_quickcheckout .panel {
  border-radius: 0;
  border: none;
  background: none;
}

.qc-checkout-product table.table tbody td {
  vertical-align: middle;
}

#payment_address_heading_heading > i {
  display: none;
}

#d_quickcheckout .btn {
  box-shadow: none !important;
  text-shadow: none !important;
}

/* Login / Register */
#login_view .guest-login-register {
  display: flex;
  flex-wrap: wrap;
}

#login_view .guest-login-register div .btn {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.45em;
  text-align: center;
  border: 1px solid #c5c5c5;
  padding: 20px;
  height: 60px;
  margin-bottom: clamp(40px, 3.125vw, 60px);
}

#login_view .guest-login-register div:nth-child(1),
#login_view .guest-login-register div:nth-child(2) {
  flex-basis: 50%;
  border: none;
}

#login_view .guest-login-register div:nth-child(1) .btn:hover,
#login_view .guest-login-register div:nth-child(2) .btn:hover {
  background-color: #d2b976 !important;
  color: #fff !important;
  border: none !important;
}

#login_view .guest-login-register div:nth-child(1) .btn.active,
#login_view .guest-login-register div:nth-child(2) .btn.active {
  background-color: #d2b976 !important;
  color: #fff !important;
  border: none !important;
}

#login_view .guest-login-register div:nth-child(3) {
  flex-basis: 100%;
  width: 100%;
}

#login_view .guest-login-register div:nth-child(3) .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6e6e6 !important;
  padding: clamp(40px, 3.6458vw, 70px) 20px;
  border: none;
  color: #000;
}

#login_view .guest-login-register div:nth-child(3) .btn:hover {
  color: #000 !important;
}

#login_view .guest-login-register div:nth-child(3) .btn br {
  display: none;
}

#d_quickcheckout .login-btn-group .btn {
  padding: 5px;
  font-weight: 400;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

#payment_view > p > b {
  font-weight: 500;
}

#d_quickcheckout.container #d_logo {
  padding: clamp(0px, 2.6042vw, 50px) 15px;
}

#d_quickcheckout.container #d_logo a {
  display: inline-block;
  margin: 0 auto;
}

#d_quickcheckout #d_logo {
  display: none;
}

body.checkout-page-template #main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#cart_view .qc-name a {
  text-decoration: none;
}

#cart_view .table,
#cart_view .table td {
  font-size: 1.4rem;
  border: none;
}

#cart_view .table thead {
  border-bottom: 1px solid #ddd;
}

#cart_view .qc-coupon {
  margin: 0 0 60px;
}

#cart_view .qc-coupon .col-sm-12 {
  padding: 0;
}

#cart_view #coupon {
  min-height: 35px;
}

#d_quickcheckout #cart_view .qc-totals .row {
  margin: 0;
}
#d_quickcheckout #cart_view .qc-totals .row:last-child {
  border-top: none;
}

#cart_view .qc-totals label,
#cart_view .qc-totals .text-right {
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 5px;
}

#payment_address_form,
#shipping_address_form {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

#payment_address_form label,
#confirm_form label {
  display: none;
}

#confirm_form .form-control:focus {
  border-color: #000;
  box-shadow: none;
}

#payment_address_form > div,
#shipping_address_form > div {
  flex-basis: calc(50% + 15px);
}

#payment_address_email_input,
#payment_address_email_confirm_input,
#payment_address_company_input,
#payment_address_address_1_input,
#payment_address_city_input,
#payment_address_postcode_input,
#payment_address_customer_group_id_input,
#payment_address_custom_field\.account\.1_input {
  flex-basis: calc(100% + 30px) !important;
}

#payment_address_customer_group_id_input .col-xs-12:first-child {
  display: none;
}

#payment_address_customer_group_id_input label {
  display: inline-block;
}

#payment_address_shipping_address_input label,
#payment_address_agree_input label,
#confirm_agree_input label {
  display: flex;
  align-items: center;
}

#confirm_form {
  margin-bottom: clamp(40px, 3.125vw, 60px);
}

#confirm_comment {
  min-height: 140px;
}

#confirm_agree_input label {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
}

#payment_address_form .checkbox-input.form-group,
#payment_address_heading_heading {
  flex-basis: 100% !important;
}
#payment_address_form .checkbox-input.form-group input,
#payment_address_heading_heading input {
  margin: 0 10px 0 0;
}

#payment_address_heading_heading {
  margin: 15px 0 15px;
}

#payment_address_heading_heading > hr {
  display: none;
}

#d_quickcheckout #login_model {
  top: 50%;
  height: fit-content;
  transform: translateY(-50%);
}

#payment_address_shipping_address_input label,
#payment_address_agree_input label,
#confirm_agree_input label {
  text-align: left;
}

.checkout-product {
  align-items: center;
  margin-bottom: clamp(30px, 2.0833vw, 40px);
}

.checkout-product .product-img {
  padding: 20px;
  background-color: #e6e6e6;
  max-width: 125px;
  width: 100%;
  margin-right: 30px;
}

.checkout-product .product-img img {
  mix-blend-mode: darken;
}

.order-account-product .product-img {
  background: unset;
}

.checkout-product .product-info {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.checkout-product .product-name .product-title,
.checkout-product .product-name .product-title a {
  font-family: "Blacker Pro Display";
  font-size: clamp(2rem, 1.3542vw, 2.6rem);
  font-style: italic;
  font-weight: 200;
  line-height: 143%;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 15px;
  color: #000;
  text-decoration: none !important;
}

.checkout-product .product-name > button {
  display: inline-block;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  letter-spacing: 0.45em;
  text-align: left;
  color: #898989;
  text-decoration: underline;
  padding-bottom: 10px;
  border: none;
}

.checkout-product .product-price > p {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.45em;
}

.qc-totals label {
  text-align: left !important;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  text-transform: uppercase;
}

.qc-totals .row {
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2rem;
  text-align: right;
  border-bottom: 1px solid #c4c4c4;
  padding: 20px 0;
}

.qc-totals .row:last-child {
  border: 0;
}

#login_form label {
  display: none;
}

/* Gift */
.checkout-gift-banner {
  border: 1px solid #c5c5c5;
  background-color: #fff;
  margin-bottom: clamp(30px, 2.0833vw, 40px);
}

.checkout-gift-banner .left {
  max-width: 220px;
  width: 100%;
}

.checkout-gift-banner .left img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.checkout-gift-banner .right {
  padding: 45px;
  position: relative;
}

.checkout-gift-banner .right > .main-title {
  margin: 0 0 15px;
}

.checkout-gift-banner .right > p {
  margin: 0 0 35px;
}

.checkout-gift-banner .right .gift-add-to-cart {
  text-decoration: none !important;
}

.checkout-gift-banner .right .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

#d_quickcheckout #payment_method .radio label {
  width: unset !important;
}

/* cart page */
#checkout-cart .mini-cart-total {
  font-size: 1.4rem;
  text-align: right;
}

#checkout-cart .flex-row.mini-cart-checkout a {
  padding: 20px;
  max-width: 300px;
}

/* Radio / Checkboxes */
#d_quickcheckout input[type=checkbox],
#d_quickcheckout input[type=radio] {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #818181;
  border-radius: 50%;
  position: relative;
}
#d_quickcheckout input[type=radio] {
  top: 3px;
}
#d_quickcheckout #confirm_agree_input input[type=checkbox] {
  margin: 0 10px 0 0;
}
#d_quickcheckout input[type=checkbox]::before,
#d_quickcheckout input[type=radio]::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  transform: scale(0) translate(-50%, -50%);
  background-color: #fff;
  transform-origin: bottom left;
}
#d_quickcheckout input[type=checkbox]::before {
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
#d_quickcheckout input[type=radio]::before {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
}
#d_quickcheckout input[type=checkbox]:checked,
#d_quickcheckout input[type=radio]:checked {
  background-color: #000;
  border: 2px solid #000;
}
#d_quickcheckout input[type=checkbox]:checked::before,
#d_quickcheckout input[type=radio]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}
#d_quickcheckout input[type=checkbox]:disabled,
#d_quickcheckout input[type=radio]:disabled {
  border-color: #EAEAEA;
  cursor: not-allowed;
}
#d_quickcheckout .radio label,
#d_quickcheckout .radio-inline,
#d_quickcheckout .radio-input {
  padding: 0;
}
#d_quickcheckout .radio label input,
#d_quickcheckout .radio-inline input,
#d_quickcheckout .radio-input input {
  margin: 0 10px 0 0;
}
#d_quickcheckout .radio label label,
#d_quickcheckout .radio-inline label,
#d_quickcheckout .radio-input label {
  margin: 0;
}

@media (max-width: 1440px) {
  #login_view .guest-login-register div:nth-child(3) .btn br {
    display: block;
  }
}
@media (max-width: 991px) {
  #d_quickcheckout.container #d_logo {
    padding: 15px 15px clamp(30px, 5.2083vw, 100px) 15px;
  }

  .checkout-left {
    border: none;
    padding: 0;
  }

  .checkout-right {
    padding: 0;
  }

  .checkout-product .product-img {
    padding: 10px;
    max-width: 100px;
    margin-right: 20px;
  }

  .checkout-product .product-name > h4 {
    margin: 0 0 10px;
    font-size: 2rem;
  }
}
@media (max-width: 767px) {
  #payment_address_form > div,
#shipping_address_form > div {
    flex-basis: 100%;
  }

  #payment_address_form > div > div,
#shipping_address_form > div > div,
#confirm_comment_input > div,
#confirm_agree_input > div {
    padding: 0;
  }

  .form-horizontal .form-group {
    margin-right: 0;
    margin-left: 0;
  }

  #d_quickcheckout .panel-body,
#d_quickcheckout .panel-default > .panel-heading,
#payment_view {
    padding: 10px;
  }

  .checkout-gift-banner .right .close-btn {
    top: 10px;
    right: 10px;
  }
}
@media (max-width: 660px) {
  #shipping_method_list,
#shipping_method_list span,
#d_quickcheckout label,
#d_quickcheckout label.control-label,
#d_quickcheckout input[type=text].form-control,
#d_quickcheckout input[type=password].form-control,
#d_quickcheckout input[type=email].form-control,
#d_quickcheckout input[type=tel].form-control,
#d_quickcheckout textarea.form-control,
#d_quickcheckout select.form-control,
#d_quickcheckout .alert,
#confirm_coupon,
#qc_confirm_order {
    font-size: 1.4rem;
  }

  #cart_view .table,
#cart_view .table td {
    font-size: 1.2rem;
  }

  #d_quickcheckout input[type=radio],
#d_quickcheckout input[type=checkbox] {
    width: 16px;
    height: 16px;
    max-width: 16px;
  }

  #d_quickcheckout input[type=radio]:checked::before,
#d_quickcheckout input[type=checkbox]:checked::before {
    background-size: 3px 3px;
    width: 6px;
    height: 6px;
  }

  .radio input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio],
input[type=checkbox] {
    margin: 0 10px 0 0;
  }

  #d_quickcheckout label.control-label span {
    display: block;
    flex-basis: 80%;
  }

  .checkout-gift-banner {
    flex-direction: column;
  }

  .checkout-gift-banner .left {
    max-width: unset;
  }

  .checkout-gift-banner .right {
    padding: 25px;
  }

  #login_view .guest-login-register div .btn {
    font-size: 1.4rem;
  }

  #login_view .guest-login-register div:nth-child(1),
#login_view .guest-login-register div:nth-child(2) {
    flex-basis: 50%;
  }

  #confirm_coupon {
    padding: 20px;
    height: 60px;
  }

  #coupon {
    height: 60px;
  }

  #confirm_agree_input span {
    display: inline;
  }
  #confirm_agree_input .control-label::after {
    content: "";
  }
}
.page_information .container {
  padding: 7.552vw 20px;
}
.page_information .container.medium-container {
  max-width: 1090px;
  margin: 0 auto;
}
.page_information .container.medium-container h1.big_title {
  margin-bottom: 5.729vw;
  text-align: center !important;
}
.page_information .container.medium-container p {
  margin-bottom: 15px;
}
.page_information .container.medium-container h2, .page_information .container.medium-container h3, .page_information .container.medium-container h4 {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 17px;
  margin: 30px 0;
}
.page_information .financial_link {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-style: normal;
  font-weight: 600;
}
.page_information .financial_link:hover {
  text-decoration: underline;
}

@media (max-width: 630px) {
  .page_information .container {
    padding: 50px 20px;
  }
  .page_information .container #content {
    text-align: justify;
  }
  .page_information .container #content.medium-container h1.big_title {
    margin-bottom: 35px;
  }
}
.cart-page .product-quantity .quantity input.form-control {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  font-weight: 400;
  border: 1px solid #b6b6b6;
  border-radius: 0;
  color: #000;
  padding: 10px;
  min-height: 59px;
  text-align: center;
  min-width: 75px;
  z-index: 1000;
}
.cart-page .product-quantity .quantity {
  position: relative;
  max-width: 75px;
  flex-basis: 100%;
  display: inline-flex;
}
.cart-page .btn-primary,
.cart-page .btn-primary:focus {
  background: no-repeat;
  color: #000;
  text-shadow: none;
  border: 0;
  padding: 0;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-decoration: underline;
}
.cart-page .quantity-input-field .number-input {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .cart-page .quantity-input-field .number-input {
    flex-direction: column;
  }
}
.cart-page .mini-cart-checkout a {
  align-self: flex-end;
  max-width: 250px;
  width: 100%;
  border: 1px solid #000;
  transition: all 0.3s ease;
  text-align: center;
  padding: 10px 20px;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #fff;
  background-color: #000;
  text-transform: uppercase;
}
.cart-page .mini-cart-checkout a:hover {
  background-color: transparent;
  color: #000;
  transition: all 0.3s ease;
}
.cart-page .mini-cart-total {
  margin-top: 30px;
  justify-content: flex-end;
}
.cart-page .mini-cart-checkout {
  background-color: transparent;
  justify-content: flex-end;
}
.cart-page > ul > li {
  border-bottom: 1px solid #ddd;
}
.cart-page > ul > li + li {
  margin-top: 10px;
}
.cart-page .mini-cart-products {
  justify-content: space-between;
  align-items: center;
}
.cart-page .remove-cart-item {
  background: none;
  border: none;
}
@media (max-width: 768px) {
  .cart-page .mini-cart-products {
    flex-wrap: wrap;
    position: relative;
  }
  .cart-page .mini-cart-products .product-img {
    max-width: unset;
    flex-basis: 100%;
    margin: 0 0 15px;
  }
  .cart-page .mini-cart-products .prd-item,
.cart-page .mini-cart-products .product-quantity {
    flex-basis: 50%;
  }
  .cart-page .mini-cart-products .product-remove {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
.cart-page .quantity-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.cart-page .quantity-nav .quantity-button {
  padding: 12px;
  background-color: #1B1B1B;
  color: white;
  border-radius: 30px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-page .quantity-nav .quantity-button.quantity-down {
  margin-left: -30px;
}
.cart-page .quantity-nav .quantity-button.quantity-up {
  margin-right: -30px;
}
.cart-page .quantity-nav .quantity-button:hover {
  color: #B3B3B3;
}

.out-of-stok .text-danger {
  font-size: 125%;
  font-weight: bold;
  color: #b10905;
}