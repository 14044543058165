.marquee {
  user-select: none;
  display: flex;
  align-items: center;
  overflow: hidden;

  &--draggable {
    cursor: grab;
  }

  &--dragging {
    cursor: grabbing;
  }

  &__slide {
    flex-shrink: 0;
    position: relative;
    will-change: left;
    display: flex;
    align-items: center;
  }

  &__item {
    flex-shrink: 0;
  }
}

.header-slider {
  width: 100%;
}
.header-slider-section {
  margin-bottom: 150px;

  .marquee__item {
    /* Default values for below 360px */
    width: 100vw;
    margin-right: 90px;
  }

  @media screen and (min-width: 360px) {
    .marquee__item {
      width: 50vw;
      margin-right: 90px; /* This stays the same */
    }
  }

  @media screen and (min-width: 768px) {
    .marquee__item {
      width: 33.33vw;
      margin-right: 100px; /* Updated value */
    }
  }

  @media screen and (min-width: 1280px) {
    .marquee__item {
      width: 25vw;
      margin-right: 130px; /* Updated value */
    }
  }

  @media screen and (min-width: 1500px) {
    .marquee__item {
      width: 20vw;
      margin-right: 150px; /* Updated value */
    }
  }


  .marquee__item {
    overflow: hidden;
    width: 100% !important;

    &:nth-child(odd) {
      max-width: 260px;
      margin-top: 200px;
    }

    &:nth-child(even) {
      max-width: 330px;
      margin-bottom: auto;
    }
  }

  @media (max-width: 1280px) {

    .marquee__item {
      &:nth-child(odd) {
        max-width: 180px;
        margin-top: 150px;
      }

      &:nth-child(even) {
        max-width: 230px;
      }
    }
  }
}

@media (max-width: $tablet) {
  .header-slider-section {
    margin-bottom: 100px;
  }
}

@media (max-width: $tablet-small) {
  //.header-slider-section{
  //    margin-top:120px;
  //}
}
