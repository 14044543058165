.page_recipes {
    background-color: $recipes_bg;
    .page-container {
        max-width: 1500px;
        margin: 0 auto;
        overflow-x: hidden;
        .title_section {
            margin: 0 auto;
            padding: 250px 0 400px 0;
            text-align: center;
            * {
                color: $white;
            }
            .info {
                margin: 0 auto clamp(30px, 5.2083vw, 100px);
                padding: 0 20px;
                max-width: 590px;
                h3,
                .details {
                    margin-top: 40px;
                    justify-content: center;
                    .time {
                        margin: 0 20px;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        svg {
                            display: inline-block;
                            margin: 0 10px 0 0;
                        }
                    }
                }
            }
        }

        .recipe_text {
            background-color: $white;
            padding-top: 370px;
            padding-bottom: 50px;
            padding-left: 20px;
            padding-right: 20px;
            .top_image {
                top: 0;
            }
            .text_container {
                max-width: 550px;
                margin: 50px auto;
                margin-bottom: 100px;
                text-align: left;
                .recipe_lists {
                    margin-bottom: 50px;
                    flex-wrap: wrap;
                    .flex_item {
                        flex: 1;
                        margin-right: 15px;
                        .italic_text {
                            margin-bottom: 30px;
                        }
                        ul {
                            li {
                                margin-bottom: 15px;
                            }
                            &.product-used {
                                li {
                                    a{
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }

                                }
                            }
                        }
                        &.share {
                            text-align: right;
                            a:after {
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }

                .italic_text {
                    margin-bottom: 10px;
                }
                .main_text {
                    margin-bottom: 40px;
                }
            }
            .product-slide {
                max-width: 400px;
                border: none;
                margin: 0 auto;
                padding: 20px;
            }
        }
        .main-recipe-text {
            h4 {
                font-family: 'Fedra Serif B Pro', sans-serif;
                font-style: italic;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 189%;
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 40px;
            }
        }
        .homepage-recipes-container {
            padding-bottom: 0;
            .recipes-slider {
                margin: unset;
                display: flex;
                justify-content: space-between;
                cursor: auto;
                gap: 30px;
                //margin:0 85px;
                .recipe-slide {
                    margin: unset;
                }
            }
        }
    }
    .products-loader.load-more {
        margin-bottom: 100px;
    }
    .recipes-slider.slick-slider {
        display: block !important;
        width: 100% !important;
    }
}

@media (max-width: $laptop) {
    .page_single_recipe {
        .page-container {
            margin-left: 85px;
            margin-right: 85px;
            .title_section {
                padding-bottom: 350px;
            }
        }
    }
}

@media (max-width: $laptop-small) {
    .page_single_recipe {
        .page-container {
            margin-left: 50px;
            margin-right: 50px;
            .title_section {
                padding-top: 150px;
                padding-bottom: 350px;
            }
            .recipe_text {
                padding-top: 300px;
            }
        }
    }
}

@media (max-width: $tablet) {
    .page_single_recipe {
        .page-container {
            .title_section {
                padding-bottom: 250px;
            }
            .recipe_text {
                padding-top: 250px;
            }
            .homepage-recipes-container {
                overflow-x: auto;
                .top {
                    padding: 0;
                }
                .recipes-slider {
                    width: max-content;
                    overflow-x: auto;
                    .recipe-slide {
                        .info {
                            .details {
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 5px;
                                .time {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-small) {
    .page_single_recipe {
        .page-container {
            margin-left: 0px;
            margin-right: 0px;
            .title_section {
                padding-bottom: 250px;
            }
            .recipe_text {
                padding-top: 200px;
                .text_container {
                    .recipe_lists {
                        .flex_item {
                            flex: 1 0 50%;
                            &.share {
                                margin-top: 40px;
                                text-align: left;
                                flex: 0 1 100%;
                            }
                        }
                    }
                }
            }
            .homepage-recipes-container {
                overflow-x: auto;
                padding-left: 20px;
                .recipes-slider {
                    width: max-content;
                    overflow-x: scroll;
                    .recipe-slide {
                        .info {
                            .details {
                                flex-direction: row;
                                align-items: flex-start;
                                gap: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile) {
    .page_single_recipe {
        .page-container {
            .title_section {
                padding-top: 100px;
                padding-bottom: 200px;
            }
            .recipe_text {
                padding-top: 150px;
                .top_image {
                    padding: 0 20px;
                    max-width: 350px;
                    width: 100%;
                }
            }
        }
    }
}
