html {
    @media screen and (max-width: 401px) {
        overflow-x: hidden;        
    }
}

.cover{
    object-fit: cover;
    width:100%;
    height:100%;
}

.abs{
    position:absolute;
}

.rel{
    position: relative;
}

.center_abs{
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.reverse_row{
    flex-direction: row-reverse;
}

.main_wrapper{
    margin:0 auto;
}

.flexy{
    display:flex;
}

.mobile{
    display:none;
}
.desktop{
    display: block;
}


@media(max-width:$laptop){
    .main_wrapper{
        padding-left: 85px;
        padding-right: 85px;
    }
}

@media(max-width:$tablet){
    .main_wrapper{
        padding-left: 25px;
        padding-right: 25px;
    }
    
}

@media(max-width:$tablet-small){
    .mobile{
        display:block;
    }
    .desktop{
        display: none;
    }
}