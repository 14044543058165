.page_terms_and_conditions {
    .gridy_item {
        display: grid;
        text-align: center;
        max-width: 1100px;
        padding-bottom: 220px;
        margin: 0 auto;
        padding-top: 8.073vw;
    }

    .gridy_item .big_title {
        padding-top: 225px;
        padding-bottom: 110px;
    }
    .main_button {
        padding-bottom: 20px;
        text-align: left;
    }
    .main_button p {
        margin: 0;
    }
    .gridy_item .main_text {
        padding-bottom: 20px;
        text-align: left;
    }
    .gridy_item .main_text p {
        margin: 0;
    }

    @media only screen and (max-width: 1280px) {
        .gridy_item {
            max-width: 730px;
        }
        .gridy_item.big_title {
            padding: 125px 0 110px 0;
        }
    }
    @media only screen and (max-width: 767px) {
        .gridy_item {
            padding: 0 20px;
            padding-bottom: 80px;
            text-align: center;
        }
        .gridy_item.big_title {
            padding: 80px 0 80px 0;
        }
    }
}

