.page_trea{
    &.page_category {
        background-color: $trea;
    }
    .page-container{
        background-color: $trea;
        *{
            color:$white;
        }
        .welcome_section{
            border-color: $white;
        }
        .centered_img_section{
            max-width: 1400px;
            .centered_img {
                overflow: hidden;
            }
            @media(max-width: $tablet){
                padding:0;
            }
        }
        .product_section{
            * { color: $black; }
        }
    }
}
.page_sustainability {
    background-color: #D9DEC9;

}