.full_width_img_section{
    position:relative;
    .golden_letters{
        position:absolute;
        top:10%;
        left:5%;
        width:50%;
    }
}

@media(max-width:$tablet-small){
    .full_width_img_section{
        background-color: $white;
        div{
            height:auto;
        }

        .golden_letters{
            position:static;
            padding-top:40px;
            padding-bottom: 40px;
            width:100%;
        }
    }
    
}