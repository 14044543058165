body {
  &.menu-is-open {
    overflow: hidden;
    #main-header {
      background-color: transparent;
      .burger-menu {
        order: 1;
        .burger-icon {
          a {
            .icon-bar {
              background-color: $black;
            }
          }
        }
      }
      .account-items {
        order: 2;
        visibility: hidden;
        opacity: 0;
      }
      .logo {
        order: 3;
        .header-logo {
          justify-content: flex-end;
          a {
            .logo-icon {
              width: 162px;
              height: 19px;
              svg {
                width: 162px;
                height: 19px;
                .st0 {
                  fill: $black;
                }
              }
            }
          }
        }
      }
    }
  }
}
#main-header {
  padding: 34px 135px;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: rgb(0 0 0 / 70%);
  .burger-menu {
    flex-basis: 33.3333%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    .burger-icon {
      display: block;
      width: 100%;
      max-width: 65px;
      margin-right: 20px;
      a {
        .icon-bar {
          content: '';
          position: relative;
          display: block;
          width: 100%;
          height: 1px;
          margin-bottom: 4px;
          background-color: $white;
          -webkit-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease;
          &.bottom {
            width: 50%;
          }
        }
      }
      &.active {
        a {
          display: block;
          .icon-bar {
            &.top {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
              -webkit-transition: all .3s ease;
              -moz-transition: all .3s ease;
              -o-transition: all .3s ease;
              transition: all .3s ease;
              top: 8px;
            }
            &.middle {
              opacity: 0;
              -webkit-transition: all .3s ease;
              -moz-transition: all .3s ease;
              -o-transition: all .3s ease;
              transition: all .3s ease;
            }
            &.bottom {
              width: 100%;
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
              -webkit-transition: all .3s ease;
              -moz-transition: all .3s ease;
              -o-transition: all .3s ease;
              transition: all .3s ease;
              bottom: 2px;
            }
          }
        }
      }
    }
    .header-search {
      display: none;
      &.mobile-search {
        @media (max-width: 991px) {
          display: block;
          .open-search-bar {
            position: relative;
            z-index: 99;
            .search-icon {
              display: inline-block;
              width: 22px;
              height: 22px;
              svg {
                display: inline-block;
                width: 22px;
                height: 22px;
                @media (max-width: 421px) {
                  width: 19px;
                  height: 19px;
                }
                .st0 {
                  fill: $white;
                }
              }
            }
            .close-search {
              display: none;
              width: 45px;
              height: 45px;
              svg {
                display: inline-block;
                width: 45px;
                height: 45px;
                .st0 {
                  fill: none;
                  stroke: $black;
                }
              }
            }
            &.active {
              .search-icon {
                display: none;
              }
              .close-search {
                display: inline-block;
              }
            }
          }
          .open-search-input {
            visibility: hidden;
            opacity: 0;
            position: fixed;
            width: 100%;
            top: 0;
            right: -100%;
            padding: 55px;
            background: $white;
            z-index: 9;
            -webkit-transition: right .4s ease, opacity .3s ease ;
            -moz-transition: right .4s ease, opacity .3s ease;
            -o-transition: right .4s ease, opacity .3s ease;
            transition: right .4s ease, opacity .3s ease;
            &.active {
              visibility: visible;
              opacity: 1;
              right: 0;
              -webkit-transition: left .4s ease, opacity .3s ease ;
              -moz-transition: left .4s ease, opacity .3s ease;
              -o-transition: left .4s ease, opacity .3s ease;
              transition: left .4s ease, opacity .3s ease;
              #search {
                width: 100%;
                max-width: 450px;
                margin: 0 auto;
                border-bottom: 1px solid $black;
                opacity: 0.1;
                min-height: 50px;
                input[type="text"] {
                  border: none;
                  box-shadow: none;
                  font-family: $main_font;
                  font-size: 2.2rem;
                  font-weight: 600;
                  line-height: 36px;
                  color: $black;
                  &::placeholder {
                    font-family: $main_font;
                    font-size: 2.2rem;
                    font-weight: 600;
                    line-height: 36px;
                    color: $black;
                  }
                  &:focus {
                    &::placeholder {
                      color: transparent;
                    }
                  }
                }
                #d_ajax_search_results {
                  width: 100%;
                  margin-top: 50px;
                  #d_ajax_search_results_body {
                    .result_block {
                      .block-text {
                        display: none;
                      }
                      .result-link {
                        &:hover {
                          background-color: $light_gray;
                        }
                        div {
                          display: inline-block;
                          font-family: $main_font;
                          font-weight: 600;
                          font-size: 1.5rem;
                          color: $black;
                          line-height: 145%;
                          vertical-align: middle;
                          img {
                            border: none;
                          }
                          .forkeydon {
                            font-family: $main_font;
                            font-weight: 600;
                            font-size: 1.5rem;
                            line-height: 145%;
                            color: $black;
                          }
                        }
                      }
                    }
                    .no-results {
                      display: block;
                      font-family: $main_font;
                      font-weight: 600;
                      font-size: 1.5rem;
                      color: $black;
                      line-height: 145%;
                    }
                  }
                }
                &.active {
                  opacity: 1;
                }
                .search-btn {
                  border: none;
                  background-color: transparent;
                }
                .search-submit {
                  display: block;
                  width: 47px;
                  height: 24px;
                  svg {
                    display: block;
                    width: 47px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .logo {
    flex-basis: 33.3333%;
    .header-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: block;
        .logo-icon {
          display: block;
          width: 195px;
          height: 22px;
          @media (max-width: $laptop-small) {
            width: 130px;
            height: 15px;
          }
          svg {
            display: inline-block;
            width: 195px;
            height: 22px;
            @media (max-width: $laptop-small) {
              width: 130px;
              height: 15px;
            }
            .st0{
              fill: $white;
            }
          }
        }
      }
    }
  }
  .account-items {
    flex-basis: 33.3333%;
    .account-list {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        margin-left: 25px;
        a {
          display: inline-block;
          font-family: $main_font;
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 17px;
          color: $white;
          @media (max-width: $laptop-small) {
            font-size: 1.3rem;
          }
          &.shop-online-icon {
            display: none;
          }
          &.shop-online {
            border: 1px solid;
            padding: 10px;
            margin-bottom: 6px;
            @media (max-width: $laptop-small) {
              padding: 5px;
            }
          }
          .account-icon-1{
            display: inline-block;
            width: 24px;
            height: 23px;
            svg {
              display: inline-block;
              width: 24px;
              height: 23px;
              .cls-1 {
                fill: $white;
              }
            }
          }
          .account-icon {
            display: inline-block;
            width: 19px;
            height: 23px;
            svg {
              display: inline-block;
              width: 19px;
              height: 23px;
              .st0 {
                fill: $white;
              }
            }
          }
          &.open-search-bar {
            position: relative;
            z-index: 99;
            .search-icon {
              display: inline-block;
              width: 22px;
              height: 22px;
              svg {
                display: inline-block;
                width: 22px;
                height: 22px;
                .st0 {
                  fill: $white;
                }
              }
            }
            .close-search {
              display: none;
              width: 45px;
              height: 45px;
              svg {
                display: inline-block;
                width: 45px;
                height: 45px;
                .st0 {
                  fill: none;
                  stroke: $black;
                }
              }
            }
            &.active {
              .search-icon {
                display: none;
              }
              .close-search {
                display: inline-block;
              }
            }
          }
        }
        .open-search-input {
          visibility: hidden;
          opacity: 0;
          position: fixed;
          width: 100%;
          top: 0;
          right: -100%;
          padding: 55px;
          background: $white;
          z-index: 9;
          -webkit-transition: right .4s ease, opacity .3s ease ;
          -moz-transition: right .4s ease, opacity .3s ease;
          -o-transition: right .4s ease, opacity .3s ease;
          transition: right .4s ease, opacity .3s ease;
          &.active {
            visibility: visible;
            opacity: 1;
            right: 0;
            -webkit-transition: left .4s ease, opacity .3s ease ;
            -moz-transition: left .4s ease, opacity .3s ease;
            -o-transition: left .4s ease, opacity .3s ease;
            transition: left .4s ease, opacity .3s ease;
            #search {
              width: 100%;
              max-width: 450px;
              margin: 0 auto;
              border-bottom: 1px solid $black;
              opacity: 0.1;
              min-height: 50px;
              input[type="text"] {
                border: none;
                box-shadow: none;
                font-family: $main_font;
                font-size: 2.2rem;
                font-weight: 600;
                line-height: 36px;
                color: $black;
                &::placeholder {
                  font-family: $main_font;
                  font-size: 2.2rem;
                  font-weight: 600;
                  line-height: 36px;
                  color: $black;
                }
                &:focus {
                  &::placeholder {
                    color: transparent;
                  }
                }
              }
              #d_ajax_search_results {
                width: 100%;
                margin-top: 50px;
                #d_ajax_search_results_body {
                  .result_block {
                    .block-text {
                      display: none;
                    }
                    .result-link {
                      &:hover {
                        background-color: $light_gray;
                      }
                      div {
                        display: inline-block;
                        font-family: $main_font;
                        font-weight: 600;
                        font-size: 1.5rem;
                        color: $black;
                        line-height: 145%;
                        vertical-align: middle;
                        img {
                          border: none;
                        }
                        .forkeydon {
                          font-family: $main_font;
                          font-weight: 600;
                          font-size: 1.5rem;
                          line-height: 145%;
                          color: $black;
                        }
                      }
                    }
                  }
                  .no-results {
                    display: block;
                    font-family: $main_font;
                    font-weight: 600;
                    font-size: 1.5rem;
                    color: $black;
                    line-height: 145%;
                  }
                }
              }
              &.active {
                opacity: 1;
              }
              .search-btn {
                border: none;
                background-color: transparent;
              }
              .search-submit {
                display: block;
                width: 47px;
                height: 24px;
                svg {
                  display: block;
                  width: 47px;
                  height: 24px;
                }
              }
            }
          }
        }
        #cart {
          margin-bottom: 0;
          .dropdown-backdrop {
            z-index: 99;
          }
          .cart-btn {
            background: transparent;
            border: none;
            position: relative;
            padding: 0;
            box-shadow: none;
            .cart-icon {
              display: block;
              width: 38px;
              position: relative;
              svg {
                display:block;
                width: 38px;
                fill : $white;
                stroke : $white;
                stroke-width : 0.2;
                .st0 {
                  fill: none;
                  stroke: $white;
                }
                .st1 {
                  fill: $white;
                }
              }
            }
            #cart-total {
              position: absolute;
              right: -8px;
              top: -10px;
              color: $white;
            }
          }

          .dropdown-menu {
            position: fixed;
            right: -100%;
            top: 0;
            z-index: 99;
            width: 100%;
            max-width: 500px;
            min-width: auto;
            height: 100%;
            background: $light_gray;
            border-radius: 0;
            margin: 0;
            display: block;
            opacity: 0;
            visibility: hidden;
            padding: 0;
            border: 0;
            -webkit-transition: right 0.3s ease;
            -moz-transition: right 0.3s ease;
            -o-transition: right 0.3s ease;
            transition: right 0.3s ease;
            .minicart-heading {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: flex-end;
              align-items: center;
              padding: 55px 20px 55px 0;
              .main_button {
                font-size: 1.8rem;
                margin: 0;
                text-transform: uppercase;
              }
              span.close-btn-icon {
                display: inline-block;
                margin-left: 15px;
                width: 15px;
                height: 15px;
                svg {
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                  .st0 {
                    fill: none;
                    stroke: $black;
                    stroke-width: 3;
                  }
                }
              }
            }
            .all-products-w-button {
              .scrollable-products {
                max-height: calc(100vh - 385px);
                overflow-y: auto;
                padding: 0 30px;
                .section_serif_title {
                  font-size: 2rem;
                }
                li {
                  margin-left: 0;
                  .mini-cart-products {
                    flex-direction: row;
                    align-content: center;
                    justify-content: flex-start;
                    align-items: center;

                    .product-img {
                      flex-basis: 100%;
                      max-width: 125px;
                      img.img-thumbnail {
                        border: none;
                        background: $light_gray;
                        mix-blend-mode: darken;
                      }
                    }
                    .prd-item {
                      padding: 0 0 0 33px;
                      a.prd-name {
                        display: inline-block;
                        font-family: $main_font;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2.2rem;
                        line-height: 145%;
                        color: $black;
                      }
                      p.prd-price {
                        font-family: "Fedra Serif B Pro", sans-serif;
                        font-style: italic;
                        font-weight: 500;
                        font-size: 1.5rem;
                        line-height: 189%;
                        margin: 0;
                        color: $black;
                      }
                      button.remove-cart-item {
                        border: none;
                        padding: 0;
                        background: transparent;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
              .mobile-bottom-cart {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                background: $light_gray;
                padding-top: 50px;
                z-index: 999999;
                .table-of-totals {
                  padding: 0 75px;
                  .mini-cart-total {
                    width: 100%;
                    margin: 0 0 30px 0;
                    td {
                      color: $black;
                      text-transform: uppercase;
                      .right {
                        float: right;
                      }
                    }
                    tbody {
                      tr {
                        &:nth-child(2) {
                          display: none;
                        }
                      }
                    }
                  }
                }
                .mini-cart-checkout {
                  a {
                    font-family: $main_font;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.8rem;
                    background: $black;
                    padding: 50px 15px;
                    text-transform: uppercase;
                    width: 100%;
                    text-align: center;
                    color: $white;
                  }
                }
              }
            }
          }
          &.open {
            .dropdown-menu {
              right: 0;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
  &.dark-header {
   .burger-menu {
      .burger-icon {
        a {
          .icon-bar {
            background-color: $white;
          }
        }
      }
    }
   .logo {
     a {
       .logo-icon {
         svg {
           .st0 {
             fill: $white;
           }
         }
       }
     }
    }
   .account-items {
     .account-list {
       li {
         a {
           color: $white;
           .search-icon {
             svg {
               .st0 {
                 fill: $white;
               }
             }
           }
           .account-icon {
             svg {
               .st0 {
                 fill: $white;
               }
             }
           }
           .account-icon-1 {
             svg {
               .cls-1 {
                 fill: $white;
               }
             }
           }
         }
         #cart {
           .cart-btn {
             .cart-icon {
               svg {
                 fill : $white;
                 stroke : $white;
                 stroke-width : 0.2;
                 .st0 {
                   stroke: $white;
                 }
               }
             }
             #cart-total {
               color: $white;
             }
           }
         }
       }
     }
    }
  }
  &.sticky-header {
    position: fixed;
    padding: 20px 135px;
    background-color: rgb(0 0 0 / 70%);
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    .burger-menu {
      .burger-icon {
        a {
          .icon-bar {
            background-color: $white;
          }
        }
      }
    }
    .logo {
      a {
        .logo-icon {
          svg {
            .st0 {
              fill: $white;
            }
          }
        }
      }
    }
    .account-items {
      .account-list {
        li {
          a {
            color: $white;
            .account-icon {
              svg {
                .st0 {
                  fill: $white;
                }
              }
            }
            .account-icon-1{
              svg{
                .cls-1{
                  fill: $white !important;
                }
              }
            }
          }
          #cart {
            .cart-btn {
              .cart-icon {
                svg {
                  .st0 {
                    stroke: $white;
                  }
                }
              }
              #cart-total {
                color: $white;
              }
            }
          }
          &.header-search {
            .search-icon {
              svg {
                .st0 {
                  fill: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom_menu {
  opacity: 0;
  visibility: hidden;
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: -100%;
  z-index: 99;
  top: 0;
  -webkit-transition: left 0.3s ease;
  -moz-transition: left 0.3s ease;
  -o-transition: left 0.3s ease;
  transition: left 0.3s ease;
  &.active {
    opacity: 1;
    visibility: visible;
    display: block;
    left: 0;
    z-index: 999;
  }
  .custom_menu_body {
    height: 100%;
    .left {
      flex-basis: 50%;
      background-color: $main;
      padding: 136px 35px;
      position: relative;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .close-menu-icon {
        display: inline-block;
        width: 46px;
        height: 46px;
        cursor: pointer;
        svg {
          display: inline-block;
          width: 46px;
          height: 46px;
          .st0 {
            fill: none;
            stroke: $black;
          }
        }
      }
      .custom_menu_items {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        ul {
          text-align: center;
          li {
            margin: 15px 0;
            .shop-all{
              display: inline-block;
              font-family: $main_font;
              font-size: 3.5rem;
              font-weight: 600;
              line-height: 51px;
              text-align: center;
              color: $black;
              margin-top: 30px;
              position: relative;
              &:after {
                display: inline-block;
                content: '';
                height: 2px;
                width: 100%;
                background-color: $black;
                position: absolute;
                bottom: 1px;
                left: 0;
              }
            }
            .custom_menu_item {
              display: inline-block;
              font-family: $main_font;
              font-size: 3.5rem;
              font-weight: 600;
              line-height: 51px;
              text-align: center;
              color: $black;
              -webkit-transition: opacity 0.3s ease;
              -moz-transition: opacity 0.3s ease;
              -o-transition: opacity 0.3s ease;
              transition: opacity 0.3s ease;
              position: relative;
              &.active {
                font-family: $secondary_font;
                color: $main_darker;
                font-weight: 500;
                font-style: italic;
                -webkit-transition: opacity 0.3s ease;
                -moz-transition: opacity 0.3s ease;
                -o-transition: opacity 0.3s ease;
                transition: opacity 0.3s ease;
                &:before {
                  visibility: visible;
                  opacity: 1;
                  -webkit-transition: opacity 0.3s;
                  -moz-transition: opacity 0.3s;
                  -o-transition: opacity 0.3s;
                  transition: opacity 0.3s;
                }
                span.menu-arrow {
                  font-family: $main_font;
                  svg {
                    .st0 {
                      fill: $gold;
                    }
                  }
                }
              }
              &:before {
                display: inline-block;
                width: 21px;
                height: 24px;
                content: '';
                background-image: url('../../../icons/menu-arrow.svg');
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 20px;
                visibility: hidden;
                opacity: 0;
                -webkit-transition: opacity 0.3s;
                -moz-transition: opacity 0.3s;
                -o-transition: opacity 0.3s;
                transition: opacity 0.3s;
                position: absolute;
                top: 50%;
                left: -40px;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
              }
              &:hover {
                &:before {
                  visibility: visible;
                  opacity: 1;
                  -webkit-transition: opacity 0.3s;
                  -moz-transition: opacity 0.3s;
                  -o-transition: opacity 0.3s;
                  transition: opacity 0.3s;
                }
                span.menu-arrow {
                  svg {
                    .st0 {
                      fill: $gold;
                    }
                  }
                }
              }
              span.menu-arrow {
                svg {
                  vertical-align: middle;
                  width: 12px;
                  .st0 {
                    fill: $black;
                  }
                }
              }
            }
            .sub-menu {
              display: none;
              transition: opacity 200ms, display 200ms;
              opacity: 0;
              padding-left: 100px;
              text-align: left;
              margin: 15px 0;
              li {
                margin: 0;
                .custom_menu_item {
                  font-size: 3rem;
                }
              }
            }
            .custom_menu_item.active-sub-menu + .sub-menu {
              display: block;
              opacity: 1;
            }
          }
        }
      }
    }
    .right {
      flex-basis: 50%;
      background-color: $white;
      padding: 136px 35px;
      position: relative;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .custom_menu_block_items {
        width: 100%;
        max-width: 560px;
        margin: 40px auto 0;
        .custom_menu_block {
          display: none;
          text-align: center;
          position: relative;
          .menu_with_pattern {
            position: relative;
            .menu_item_pattern {
              display: block;
              width: 100%;
              max-width: 560px;
              position: relative;
              svg {
                display: block;
                width: 100%;
                max-width: 560px;
                .st0 {
                  fill: transparent;
                }
              }
              &.enable {
                svg {
                  .st0 {
                    fill: #F2F2F2;
                  }
                }
              }
            }
            p.secondary_title {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              width: 100%;
              max-width: 410px;
              margin-bottom: 0;
              text-align: justify;
            }
          }
          &.active {
            display: block;
          }
        }
      }
    }
  }

}
@media (min-width: 767px) {
  .page_checkout {
    #main-header {
      background-color: $white;
      .burger-menu .burger-icon a .icon-bar {
        background-color: $black;
      }
      .logo a .logo-icon svg .st0 {
        fill: $black;
      }
      .account-items .account-list li {
        a {
          color: $black;
          .account-icon svg .st0 {
            fill: $black;
          }
          .search-icon svg .st0 {
            fill: $black;
          }
        }
        #cart .cart-btn {
          #cart-total {
            color: $black;
          }
          .cart-icon svg {
            fill: $black;
            stroke: $black;
          }
        }
      }
    }
    #main-header.sticky-header {
      background-color: rgba(0, 0, 0, 0.7);
      .burger-menu .burger-icon a .icon-bar {
        background-color: $white;
      }
      .logo a .logo-icon svg .st0 {
        fill: $white;
      }
      .account-items .account-list li {
        a {
          color: $white;
          .account-icon svg .st0 {
            fill: $white;
          }
          .search-icon svg .st0 {
            fill: $white;
          }
        }
        #cart .cart-btn {
          #cart-total {
            color: $white;
          }
          .cart-icon svg {
            fill: $white;
            stroke: $white;
          }
        }
      }
    }
  }
}




@media (max-width: 1366px) {
  #main-header {
    padding: 32px 85px;
    &.sticky-header {
      padding: 20px 85px;
    }
  }
}

@media (max-width: 1280px) {
  #main-header {
    padding: 30px 55px;
    &.sticky-header {
      padding-left: 55px;
      padding-right: 55px;
    }
  }
}

@media (max-width: 1140px) {
  #main-header {
    padding: 35px;
    &.sticky-header {
      padding: 20px 35px;
    }
  }
}

@media (max-width: 1024px) {
  .custom_menu {
    .custom_menu_body {
      .left {
        .close-menu-icon {
          svg {
            width: 36px;
            height: 36px;
          }
        }
        .custom_menu_items {
          ul {
            li {
              margin: 5px 0;
              .custom_menu_item {
                font-size: 3rem;
                line-height: 50px;
              }
              .sub-menu {
                margin: 5px 0;
                li {
                  margin: 0;
                  .custom_menu_item {
                    font-size: 2.5rem;
                  }
                }
              }
              .shop-all {
                font-size: 2.8rem;
                line-height: 45px;
                margin-top: 25px;
              }
            }
          }
        }
      }
      .right {
        .custom_menu_block_items {
          .custom_menu_block {
            .menu_with_pattern {
              p.secondary_title {
                max-width: 310px;
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #main-header {
    .account-items {
      .account-list {
        li {
          margin-left: 20px;
          &.header-search, &.header-sustainability {
            display: none;
          }
          a {
            &.shop-online-icon {
              display: block;
            }
            &.shop-online {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  body {
    &.menu-is-open {
      #main-header {
        .logo {
          order: 2;
          .header-logo {
            justify-content: center;
            a {
              .logo-icon {
                display: block;
                width: 130px;
                height: 15px;
                svg {
                  display: block;
                  width: 130px;
                  height: 15px;
                }
              }
            }
          }
        }
        .account-items {
          order: 3;
        }
      }
    }
  }

  #main-header {
    padding: 30px 20px;
    &.sticky-header {
      padding: 20px;
    }
    .burger-menu {
      .burger-icon {
        max-width: 44px;
        margin-right: 10px;
      }
    }
    .account-items {
      .account-list {
        li {
          margin-left: 15px;
          a {
            &.open-search-bar {
              .close-search {
                width: 32px;
                height: 32px;
                svg {
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
          .open-search-input {
            &.active {
              padding: 130px 30px;
              #search {
                input[type=text] {
                  font-size: 1.8rem;
                  &::placeholder {
                    font-size: 1.8rem;
                  }
                }
              }
            }
          }
          #cart {
            .cart-btn {
              .cart-icon {
                width: 30px;
                height: 30px;
                svg {
                  width: 30px;
                  height: 30px;
                  .st0{
                    fill:none;
                    stroke:$black;
                  }
                }
              }
            }
            .dropdown-menu {
              .all-products-w-button {
                .scrollable-products {
                  padding: 0 10px;
                  li {
                    .mini-cart-products {
                      .product-img {
                        max-width: 100px;
                      }
                      .prd-item {
                        padding: 0 0 0 15px;
                        a.prd-name {
                          font-size: 1.8rem;
                        }
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }

  .custom_menu {
    .custom_menu_body {
      .left {
        flex-basis: 100%;
      }
      .right {
        display: none;
      }
    }
  }
}

@media (max-width: 421px) {
  #main-header {
    .account-items {
      .account-list {
        li {
          margin-left: 10px;
          position: relative;
          top: 2px;
          a {
            &.open-search-bar {
              .search-icon {
                width: 18px;
                height: 18px;
                svg {
                  width: 18px;
                  height: 18px;
                }
              }
            }
            &.shop-online-icon {
              span {
                width: 19px;
                height: 24px;
                svg {
                  width: 19px;
                  height: 24px;
                }
              }
            }
            .account-icon {
              width: 15px;
              height: 19px;
              svg {
                width: 15px;
                height: 19px;
              }
            }
          }
          #cart {
            .cart-btn {
              .cart-icon {
                width: 26px;
                svg {
                  width: 26px;
                }
              }
            }
          }
        }
      }
    }
  }
  .logo {
    .header-logo {
      a {
        .logo-icon {
          width: 120px;
          height: 15px;
          svg {
            width: 120px;
            height: 15px;
          }
        }
      }
    }
  }
  .custom_menu {
    .custom_menu_body {
      .left {
        .close-menu-icon {
          width: 32px;
          height: 32px;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  #main-header {
    .burger-menu {
      a {
        .burger-icon {
          width: 45px;
          svg {
            width: 45px;
          }
        }
      }
    }
    .account-items {
      .account-list {
        li {
          margin-left: 10px;
          a {
            .search-icon {
              width: 19px;
              height: 19px;
              svg {
                width: 19px;
                height: 19px;
              }
            }
          }
          #cart {
            .cart-btn {
              .cart-icon {
                width: 25px;
                height: 25px;
                svg {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }
        }
      }
    }
    .logo {
      .header-logo {
        a {
          .logo-icon {
            width: 110px;
            svg {
              width: 110px;
            }
          }
        }
      }
    }
  }
}

.discount-bar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	padding: 10px 15px;
	background-color: $white;
	color: $black;
	font-size: 1.3rem;
	line-height: 1.3;
	z-index: 10000;
}

body {
	&.has-promo-text {
		margin-top: 36px;

		#main-header {
			top: 36px;
		}
	}

  &.menu-is-open {
    margin-top: 0;

    #main-header {
      top: 0;
    }
    .discount-bar {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.free-shipping-calculator {
  background-color: $gray;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 15px 5px;
  text-align: center;

  & > * {
    @extend .italic_text;
    font-size: 1.2rem;
    color: $black;
    margin: 0;
  }
}

.shipping-duties {
  background-color: $gray;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 15px 10px;
  text-align: left;

  & > * {
    @extend .italic_text;
    font-size: 1.2rem;
    color: $black;
    margin: 0;
  }
}

#sustainability, #product_lines {
  .secondary_title {
    text-align: center;
  }
}

.page_category_usa .li-shop-online{
  display: none;
}