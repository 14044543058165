.page_awards{
    p{
        margin:0;
        padding: 0;
    }
    footer{
        margin:0;
        padding:0;
    }
    .awards_first_content .big_title{
        padding-top: 213px;
        padding-bottom: 40px;
        max-width: 290px;
        margin: 0 auto;
        text-align: center;
    }

    .awards_first_content .main_text{
        text-align: center;
        max-width:500px ;
        margin:0 auto;
    }

    .awards_detailed{
        text-align: left;
        width: 100%;
        max-width: 1090px;
        margin: 0 auto;
        padding:0 70px 180px;
    }
    .awards_images{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding:120px 20px 70px;
        text-align: center;
        max-width: 730px;
        margin: 0 auto;
        img {
            max-width: 64px;
            margin: 5px 25px;
        }
    }

    .mobile_awards{
        opacity: 0;
        visibility: hidden;
    }

    .first_gridy_item{
        border-top: 1px solid $black;
        }
    .gridy_item{
        display: grid;
        grid-template-columns: 5fr 1fr;
        border-bottom: 1px solid $black;
    }
    .gridy_item:hover{
        border-bottom: none;
    }
    .first_gridy_item:hover{
        border-top: none;
    }
    .img_great_taste{
        opacity: 0;
        visibility: hidden;
        padding-left: 10px;
        padding-top:20px;
    }
    .gridy_item:hover .img_great_taste{
        opacity: 1;
        visibility: visible;
        transition: 0.3s ease;
    }

    .gridy_item:hover {
        background-color: $yellow;
        transition: 0.3s ease;
        padding: 0;
        margin:0;
        border-bottom: none;
    }

   .hovered_awards .secondary_title{
        padding-top:40px;
        padding-left: 75px;
    }
    .hovered_awards .italic_text{
        padding-bottom:40px;
        padding-left: 75px;
    }

    .hovered_awards_imgs {
        img {
            max-width: 90px;
        }
        .mobile_awards {
            display: none;
        }
    }

    @media only screen and (max-width: 767px){

        .hovered_awards {
            margin-right: 10px;
        }
        .hovered_awards_imgs {
            .mobile_awards {
                display: block;
                opacity: 1;
                visibility: visible;
                max-width: 68px;
            }
            .img_great_taste {
                display: none;
            }
        }
        .awards_first_content{
            max-width: 375px;
            margin: 0 auto;
        }

        .awards_images{
            padding:70px 20px;
        }

        .awards_detailed{
            padding: 0 20px 100px;
        }

        .hovered_awards .secondary_title,.italic_text{
            padding-left: 0;
        }

        .awards_first_content .big_title{
            padding-top:90px;
            padding-bottom:55px;
            max-width: 190px;
        }
        .awards_first_content .main_text{
            padding: 0 15px;/*sto figma eixe 28 alla den fainotan to idio*/
        }

        .img_great_taste{
            display: none;
        }

        .mobile_awards{
            padding-top: 45px;
        }
        .hovered_awards .italic_text {
            font-size: 1.48rem;
            padding-left: 0;
        }

        .gridy_item:hover .mobile_awards{
            opacity: 1;
            visibility: visible;
            transition: 0.3s ease;
        }
        .gridy_item:hover{
            background-color: $white;
        }
        .centered_grid_five{
            grid-column-start: 2;
            grid-row-start: 2;
            grid-row-end: 2;
        }
        .centered_grid_six{
            grid-column-start: 3;
            grid-row-start: 2;
            grid-row-end: 2;
        }

    }

}
@media (max-width: 767px) {
    .page_awards .awards_images img {
        margin: 5px 10px;
    }
}