.cart-page {
    .product-quantity .quantity input.form-control {
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        font-weight: 400;
        border: 1px solid #b6b6b6;
        border-radius: 0;
        color: $black;
        padding: 10px;
        min-height: 59px;
        text-align: center;
        min-width: 75px;
        z-index: 1000;
    }
    .product-quantity .quantity {
        position: relative;
        max-width: 75px;
        flex-basis: 100%;
        display: inline-flex;
    }
    .btn-primary,
    .btn-primary:focus {
        background: no-repeat;
        color: $black;
        text-shadow: none;
        border: 0;
        padding: 0;
        box-shadow: none;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        text-decoration: underline;
    }
    .quantity-input-field .number-input {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;

        @media screen and (max-width:600px) {
            flex-direction: column;
        }
    }
    .mini-cart-checkout a {
        align-self: flex-end;
        max-width: 250px;
        width: 100%;
        border: 1px solid $black;
        transition: all 0.3s ease;
        text-align: center;
        padding: 10px 20px;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.1em;
        color: #fff;
        background-color: $black;
        text-transform: uppercase;
    }
    .mini-cart-checkout a:hover {
        background-color: transparent;
        color: $black;
        transition: all 0.3s ease;
    }
    .mini-cart-total {
        margin-top: 30px;
        justify-content: flex-end;
    }
    .mini-cart-checkout {
        background-color: transparent;
        justify-content: flex-end;
    }
    > ul > li {
        border-bottom: 1px solid #ddd;
    }
    > ul > li + li {
        margin-top: 10px;
    }
    .mini-cart-products {
        justify-content: space-between;
        align-items: center;
    }
    .remove-cart-item {
        background: none;
        border: none;
    }
    @media (max-width: 768px) {
        .mini-cart-products {
            // flex-direction: column;
            flex-wrap: wrap;
            position: relative;
            .product-img {
                max-width: unset;
                flex-basis: 100%;
                margin: 0 0 15px;
            }
            .prd-item,
            .product-quantity {
                flex-basis: 50%;
            }
            .product-remove {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
    }

    .quantity-nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    
        .quantity-button {
            padding: 12px;
            background-color: $dark_gray;
            color: white;
            border-radius: 30px;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.quantity-down {
                margin-left: -30px;
            }
    
            &.quantity-up {
                margin-right: -30px;
            }
    
            &:hover {
                color: #B3B3B3;
            }
        }
    }
}

.out-of-stok {
    .text-danger {
        font-size: 125%;
        font-weight: bold;
        color: #b10905;
    }
}