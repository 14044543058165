.search-container {
  padding: 100px 20px 100px;
  .category-wrapper {
    .search-block-wrapper {
      padding: 0 clamp(0px, 7.813vw, 150px) 35px;
      .section_serif_title {
        font-size: 2rem;
      }
      .search-choices {
        padding: 15px 0;
        .left {
          flex-basis: 33.333%;
          margin-right: 30px;
        }
        .right {
          flex-basis: 33.333%;
        }
      }
      .search-checkboxes {
        padding: 10px 0 30px;
        .search-checkbox-item {
          margin-right: 30px;
          align-items: center;
          label {
            font-family: $main_font;
            font-style: normal;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 172.5%;
            padding-left: 10px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .search-container {
    .category-wrapper {
      .search-block-wrapper {
        padding: 0 0 35px;
        .search-choices {
          .left {
            flex-basis: 50%;
          }
          .right {
            flex-basis: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .search-container {
    padding: 50px 20px;
    .category-wrapper {
      .search-block-wrapper {
        .search-choices {
          flex-wrap: wrap;
          .left {
            flex-basis: 100%;
            margin-right: 0;
          }
          .right {
            flex-basis: 100%;
          }
        }
        .search-checkboxes {
          flex-direction: column;
        }
      }
    }
  }
}

@media (max-width: 630px) {
  .search-container {
    .category-wrapper {
      .search-block-wrapper {
        .search-checkboxes {
          .search-checkbox-item {
            margin-bottom: 10px;
            margin-right: 0;
            label {
              padding-left: 0;
            }
          }
        }
      }
      .btn-default {
        &.medium-button {
          max-width: 100%;
        }
      }
    }
  }
}
