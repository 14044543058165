.welcome_section{
    max-width: 800px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    text-align: center;
    margin:0 auto;
   
    .big_title{
        margin:unset;
        margin-bottom:35px;
    }
    .main_text{
        margin:0 auto;
        text-align: justify;
    }
}
@media(max-width:$tablet-small){
    .welcome_section{
        padding-left:unset;
        padding-right: unset;
        margin-left:25px;
        margin-right:25px;
        .big_title{
            svg{
                height:30px;
            }
        }
        
    }
}