.page_navarino{
    &.page_category {
        background-color: $navarino;
    }
    .page-container{
        background-color: $navarino;
        *{
            color:$white;
        }
        .welcome_section{
            max-width: 750px;
            border-color: $white;
            .big_title{
                svg{
                    height:100px;
                }
            }

            .main_text {
                text-align: justify;
                max-width: none;
            }
            @media(max-width: $tablet){

                .big_title{
                    svg{
                        height:70px;
                    }
                }
            }
            @media(max-width: $tablet-small){
                margin-inline: 25px;
            }
        }
        .centered_img_section {
            max-width: 1400px;
            .centered_img {
                overflow: hidden;
                .img-responsive {
                    .mobile {
                        @media(max-width: $tablet-small) {
                            width: 100%;
                        }
                    }
                }
                img {
                    position: relative;
                    transform: scale(1);
                    top: 0;
                }
            }
            @media(max-width: $tablet){
                padding:0;
            }
            // margin:0 auto;
        }
        .product_section{
            * { color: $black; }
        }

        .photo_text_first_paragraph, .photo_text_second_paragraph {
            text-align: left;

            &_title {
                margin-bottom: 30px;
            }
            &_text {
                font-weight: normal;
            }
        }
    }     
}   