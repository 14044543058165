.our_values_section{
    text-align: center;
    margin-bottom: 200px;
    svg{
        height: fit-content;
        max-width:720px;
    }
    .center_abs{
        .small_border{
            width:75px;
            height:1px;
            background-color: $black; 
            margin:20px auto;
            border:none;
        }
        p{
            margin:unset;
        }
        .italic_text{
            max-width:350px;
            margin:0 auto;
        }
    }
    .golden_sans{
        display:none;
        margin-top:20px;
        word-spacing: 30px;
    }
    
}

@media(max-width: $laptop){
    .our_values_section{
        margin-bottom: 100px;
        padding-top:50px;
        svg{
            
        }
    }
}

@media(max-width: $tablet){
    .our_values_section{
        svg{
           // max-width:80%;
        }
    }
}

@media(max-width: $tablet-small){
    .our_values_section{
        svg{
            display:none;
        }
        .center_abs{
            position:static;
            transform: translate(0,0);
        }
        .golden_sans{
            display:block;
            line-height:normal;
        }
    }
}