$main_font: neue-haas-grotesk-display, sans-serif;
$secondary_font: 'Fedra Serif B Pro', sans-serif;

.big_title {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 5.5rem;
    line-height: 120%;
}
.section_serif_title {
    font-family: $secondary_font;
    font-style: italic;
    font-weight: 500;
    font-size: 4rem;
    line-height: 132%;
}
.italic_text {
    font-family: $secondary_font;
    font-style: italic;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 189%;
}
.secondary_title {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 145%;
}
.main_button {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 103%;
}
.big_text {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 146%;
}
.details {
    font-family: $main_font;
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 172.5%;
}
.main_text,
p {
    font-family: $main_font;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 172.5%;
}
.page_who_we_are, .page_navarino, .page_trea {
    .welcome_section {
        p.main_text {
            font-size: 1.8rem;
        }
    }
}
.golden_letters {
    font-family: $secondary_font;
    font-size: 2.6rem;
    line-height: 4.9rem;
    font-weight: 400;
    font-style: italic;
    color: $gold;
}
.golden_sans {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 165%;
    text-align: center;
    color: $gold;
}

.quote_text {
    font-family: Fedra Serif B Pro;
    font-style: italic;
    font-weight: normal;
    font-size: 7rem;
    line-height: normal;
}
.filter_txt {
    font-family: $main_font;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 47px;
}

@media (max-width: 1280px) {
    .main_text {
        font-size: 1.3rem;
    }
    .big_title {
        font-size: 3.5rem;
    }
    .section_serif_title {
        font-size: 3rem;
    }
    .secondary_title {
        font-size: 1.8rem;
        line-height: 2.7rem;
    }
    .quote_text {
        font-size: 3.5rem;
    }
}
