.category-container {
  padding: 120px 20px 100px;
  .category-info {
    max-width: 525px;
    margin: 0 auto;
    text-align: center;
    h1 {
      margin: 0 0 40px;
      line-height: 53px;
    }
  }
  .category-wrapper {
    .products-filters {
      padding: 50px 0;
      form {
        &.bf-with-counts {
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          .bf-attr-block {
            width: 100%;
            max-width: 270px;
            position: relative;
            .bf-attr-header {
              font-family: $main_font;
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 600;
              line-height: 21px;
              text-align: center;
              padding: 20px 15px;
              color: $black;
              background-color: $white;
              border: 1px solid #000;
              border-radius: 0 5px 5px 0;
              -webkit-font-smoothing: antialiased;
              .bf-arrow {
                display: inline-block;
                font-size: unset;
                padding: 0;
                float: unset;
                background-image: none!important;
                background-position: unset;
                vertical-align: bottom;
                width: 22px;
                height: 22px;
                svg {
                  display: inline-block;
                  width: 22px;
                  height: 22px;
                  .st0 {
                    fill: $black;
                  }
                  .st1 {
                    fill: $black;
                  }
                }
              }
            }
            .bf-sliding-cont {
              .bf-sliding {
                display: none;
              }
            }
            &:first-of-type{
              .bf-attr-header{
                border-radius: 5px 0 0 5px;
              }
            }
            &.child-is-open {
              &:first-of-type{
                .bf-attr-header{
                  border-radius: 5px 0 0 0;
                }
              }
              .bf-attr-header {
                border-radius: 0 5px 0 0;
              }
              .bf-sliding-cont {
                position: absolute;
                width: 100%;
                border: 1px solid $black;
                border-bottom: none;
                .bf-sliding {
                  display: none;
                  height: 100%!important;
                  color: #9D9D9D;
                  font-weight: 400;
                  z-index: 9;
                  position: relative;
                  &.bf-expanded {
                    display: block;
                    .bf-attr-block-cont {
                      padding: 0;
                      .bf-attr-filter {
                        display: block;
                        text-align: center;
                        background-color: $white;
                        border-bottom: 1px solid $black;
                        padding: 5px 15px;
                        position: relative;
                        .bf-cell {
                          display: inline-block;
                          border-bottom: none;
                          vertical-align: unset;
                          padding: 0;
                          width: 100%;
                          label {
                            font-family: $main_font;
                            font-size: 1.4rem;
                            font-weight: 600;
                            line-height: 21px;
                            color: $black;
                            padding: 20px 40px;
                          }
                          span.bf-cell.bf-c-2.bf-cascade-0 {
                            height: unset;
                          }
                          &.bf-c-1 {
                            display: none;
                          }
                          &.bf-c-3 {
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            top: 30px;
                            right: 15px;
                            .bf-cross {
                              display: inline-block;
                              content: '';
                              background-image: url('../../../icons/remove-filter.svg');
                              background-repeat: no-repeat;
                              background-size: cover!important;
                              width: 12px;
                              height: 12px;
                              padding: 0;
                              background-position: center;
                              vertical-align: unset;
                            }
                          }
                        }
                        &:hover {
                          background-color: $black;
                          .bf-cell.bf-c-3 {
                            .bf-cross {
                              background-image: url('../../../icons/remove-filter-hover.svg');
                            }
                          }
                          label {
                            color: $white;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.has-selected-item {
              .bf-attr-header {
                color: $white;
                background-color: $black;
                .bf-arrow {
                  svg {
                    .st0 {
                      fill: $white;
                      display: none;
                    }
                    .st1 {
                      fill: $white;
                    }
                  }
                }
              }
            }
          }
        }

        .bf-buttonclear-box {
          display: none;
        }
      }
    }
    .categories-choices {
      display: none;
    }
  }
  .category-pagination{
    justify-content: center;
    .products-loader {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 80px 0;
      a.main_button {
        border: 1px solid #000;
        width: 125px;
        height: 125px;
        border-radius: 50%;
        position: relative;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          text-align: center;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }

}

.category-grid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding: 55px clamp(0px, 7.813vw, 150px) 0;
  .product-col {
    flex-basis: 33.333%;
    border-left: 1px solid #EAEAEA;
    .product-thumb {
      border: none;
      max-width: 345px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      .top {
        align-items: center;
        justify-content: space-between;
        padding: 30px 0;
        .info {
          width: 100%;
          max-width: 170px;
          margin-right: 5px;
          .secondary_title {
            display: block;
            min-height: 100px;
            margin-bottom: 10px;
          }
          .italic_text {
            margin: 0;
          }
          .price {
            color: $black;
            .price-new {
              font-weight: 500;
            }
            .price-old {
              color: $black;
            }
          }
        }
        .main_button {
          cursor: pointer;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          max-width: 125px;
          height: 125px;
          border-radius: 50%;
          border: 1px solid $product_gray;
          background-color: $product_gray;
          -webkit-transition: all .3s ease;
          -moz-transition: all .3s ease;
          -o-transition: all .3s ease;
          transition: all .3s ease;
          @media (max-width: 375px) {
            width: 100%;
            max-width: 110px;
            height: 110px;
          }
          &:hover {
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            background-color: $main;
            border-color: $main;
          }
        }
      }
      .image {
        padding-bottom: 115px;
      }

    }
    &:nth-child(3n+1) {
      border-left: none;
    }
  }
}

@media (max-width: 1366px) {
    .category-grid {
      padding-left: clamp(0px, 3.646vw, 70px);
      padding-right: clamp(0px, 3.646vw, 70px);
    }
}
@media (max-width: 1280px) {
  .category-container {
    padding-top: 4.688vw;
    .category-info {
      h1 {
        line-height: 43px;
      }
    }
  }
  .category-grid {
    .product-col {
      .product-thumb {
        .product-link{
          .top {
            .info {
              max-width: 140px;
              margin-right: 5px;
              .secondary_title {
                min-height: 80px;
              }
            }
          }
        }
      }
    }
  }

  .third-provider-p {
    margin-top: 30px;
  }

  #product-category-usa.category-container{
    padding: 130px 20px !important;
  }
}

@media (max-width: 1024px) {
    .category-grid {
      .product-col {
        flex-basis: 50%;
        &:nth-child(3n+1) {
          border-left: 1px solid #EAEAEA;
        }
        &:nth-child(2n+1) {
          border-left: none;
        }
      }
    }

}

@media (max-width: 767px) {
  .category-grid {
    padding: 125px 0 0;
  }

  .third-provider-p {
    margin-top: 30px;
  }

  #product-category-usa.category-container{
    padding: 130px 20px !important;
  }
}

@media (max-width: 630px) {
  .category-container {
    padding: 50px 20px;
    .category-info {
      h1 {
        line-height: 40px;
      }
    }
  }
  .category-grid {
    padding: 20px 0 0;
    .product-col {
      flex-basis: 100%;
      border-left: none;
      .product-thumb {
        padding: 0;
        .product-link {
          .top {
            .info {
              .secondary_title {
                min-height: unset;
              }
            }
          }
          .image {
            padding-bottom: 20px;
          }
        }
      }
      &:nth-child(3n+1) {
        border-left: none;
      }
    }
  }
}

@media (max-width: 320px) {
  .category-grid {
    .product-col {
      .product-thumb {
        .top {
          .main_button {
            width: 95px;
            height: 95px;
          }
        }
      }
    }
  }
}

.clear-all-filters {
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  padding: 6px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  &>.fa {
    margin-left: 8px;
  }
  &:hover {
    background-color: $black;
    color: $white;
  }
}

.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter.filter-checked{
  background-color: #000;
  border-bottom: 1px solid;
}

.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter.filter-checked label{
  color: #fff;
}

.category-container .category-wrapper .products-filters form.bf-with-counts .bf-attr-block.child-is-open .bf-sliding-cont .bf-sliding.bf-expanded .bf-attr-block-cont .bf-attr-filter.filter-checked .bf-cross{
  background-image: url('../../../icons/remove-filter-hover.svg')!important;
}
.disabled_button{
  pointer-events: none;
  color:white;
  background-color: black !important;
}