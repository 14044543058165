/*========================================================*/
/*** CHECKOUT ***/
/*========================================================*/
.info-back-top {
    display: none;
}
.navbar {
    padding: 30px;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    background: #000000;
    border: 1px solid #000000;
}
.tabbable .nav-tabs > li.active {
    border-bottom: 1px solid #000000;
}
.tabbable .nav-tabs > li.active > a,
.tabbable .nav-tabs > li.active > a:focus,
.tabbable .nav-tabs > li.active > a:hover,
.tabbable .nav-tabs > li > a:hover {
    color: #000000;
}
.text-muted {
    color: #000000;
}
.btn.btn-success,
.btn.btn-success-alternate {
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    min-height: 45px;
    transition: linear 0.3s;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    border-radius: 0;
    padding: 10px 20px;
}
.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success-alternate:hover,
.btn.btn-success-alternate:focus {
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
    transition: linear 0.3s;
}
.group .inputMaterial {
    font-size: 1.3rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 400;
    border: 1px solid #ddd;
    border-radius: 0;
    color: #000;
    padding: 10px 15px;
    min-height: 45px;
}
.group.filled input:-webkit-autofill,
.group.filled input:-webkit-autofill:hover,
.group.filled input:-webkit-autofill:focus {
    border: 1px solid #000;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}
.group label {
    font-size: 1.3rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #a4a4a4;
}
.footer-separator a,
a.editAddress {
    color: #000;
    font-size: 1.1rem;
    line-height: initial;
    font-weight: 300;
}
.agreeText label span,
.form-group .checkbox.is_checkbox label {
    font-size: 1.3rem;
    line-height: initial;
    color: #000;
    font-weight: 400;
}
a.agree.pointer,
.agreeText label span a.agree.pointer {
    font-size: 1.4rem;
    line-height: initial;
    font-weight: 800;
    color: #000;
}
.group.focus label {
    background: #fff;
}
.group.focus label {
    color: #000;
}
.section-heading {
    font-size: 2rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #000;
}
.inlineaddress {
    background-color: #fff;
}
.inlineaddress h3.address_title {
    font-size: 2rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #000;
}
.info-back-checkout {
    box-shadow: none;
    color: #000000;
    background: #ececec;
    border: 1px solid #ececec;
    border-radius: 0;
}
.cartelement i.icon.ion-trash-b {
    color: #000;
}
.checkbox.is_checkbox.gift_wrapper label {
    font-size: 13px;
    padding-left: 2px;
    color: #000;
}
.panel-address .panel-address-heading,
.container_panel .heading {
    background: #ffffff;
    color: #000000;
}
.info-back-checkout .input-checkbox:checked:after {
    color: #000;
}
.info-back-checkout .checkbox.is_checkbox input[type='checkbox'].input-checkbox {
    background-color: transparent;
}
.info-back-checkout .input-checkbox:checked {
    box-shadow: none;
    background-color: transparent;
}
.panel-address {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #ddd;
}
.input-radio:checked {
    box-shadow: 0 0 0 10px #606c6f inset;
}
.selected-address {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: initial;
    color: #000;
}
.container_panel label[for='flat.flat'],
.container_panel textarea.form-control {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: initial;
    color: #000;
}
small.pull-right a {
    color: #000;
    font-size: 1.1rem;
    line-height: initial;
    font-weight: 400;
}
div#progress-continue {
    border-radius: 0;
    min-height: 45px;
}
.progress span {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    color: #fff;
    background-color: #000;
    padding: 10px 20px;
    border: 1px solid #000;
    box-shadow: none;
}
.progress-continue-disabled {
    border-radius: 0;
    min-height: 45px;
    border: none;
    background-color: #ececec;
    -webkit-box-shadow: none;
    box-shadow: none;
}
div#step_address_panel,
div#step_payment_panel {
    padding: 50px 0 70px;
}
#coupon-panel .input-group-addon,
#voucher-panel .input-group-addon {
    background-color: #000000;
    border: 1px solid #000000;
    border-radius: 0;
}
#coupon-panel .input-group.group input.inputMaterial,
#voucher-panel .input-group.group input.inputMaterial {
    border: 1px solid #ddd;
    border-radius: 0;
    font-size: 1.3rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #000;
    padding: 10px 15px;
}
.input-group.group i.fa.fa-scissors,
.input-group.group i.fa.fa-tag {
    display: none;
}
#coupon-panel .input-group.group label,
#voucher-panel .input-group.group label {
    font-size: 1.3rem;
    line-height: initial;
    letter-spacing: 0.025em;
    font-weight: 400;
    color: #a4a4a4;
    top: 12px;
}
#xcart .panel-green1 .panel-heading {
    color: #000;
}
.editcart.xwishlist {
    color: #000000;
}
#totals {
    color: #000;
}
.panel.panel-green1 .panel-heading {
    font-size: 1.4rem;
    line-height: initial;
    font-weight: 800;
    color: #000;
    border-bottom: none;
    padding: 15px 0;
}
footer.footer.footer-checkout {
    padding-top: 50px;
}
.footercontact,
.footercontact a {
    padding-top: 2px;
    margin-top: 0 !important;
    font-size: 1.4rem;
    line-height: initial;
    color: #000;
    font-weight: 600;
}
.xcart .media-heading a {
    color: #000;
}
.input-checkbox {
    box-shadow: 0 0 0 10px #ffffff inset;
    border: 1px solid #ddd;
    border-radius: 0;
}
.input-checkbox,
.input-checkbox:hover,
.input-checkbox:checked {
    box-shadow: none;
    border: 1px solid #000;
    border-radius: 0;
}
.input-checkbox:checked:after {
    color: #000;
    right: 0;
    top: 1px;
}
#xcart .media-body a {
    color: #000;
}
.youpaytext {
    font-size: 1.4rem;
    color: #000;
    line-height: initial;
}
#paymentPage .panel-group {
    border: 1px solid #ddd;
    border-radius: 0;
}
#paymentPage .panel-group label {
    color: #000;
}
a.heading-panel[aria-expanded='true']::before {
    box-shadow: 0 0 0 10px #606c6f inset;
}
.pchange a.underline {
    color: #666;
    font-size: 1.1rem;
    line-height: initial;
    font-weight: 400;
}
.pchange a.underline:hover {
    color: #666;
    text-decoration: none;
}
#undo1.fa-edit:before,
#click2 .fa.fa-edit:before {
    display: none;
}
.panel-address-heading.address-type i.fa.fa-money,
#shipping_method i.fa.fa-truck,
#order-comment i.fa.fa-comment,
.panel-heading.xcollapsable i.fa.fa-shopping-cart,
.panel.panel-green1.couponpanel i.fa.fa-tags,
.panel.panel-green1.totalspanel i.fa.fa-money {
    display: none;
}
.checkout-logo span.logo-icon svg,
.checkout-logo span.logo-icon {
    margin: 0 auto;
    display: block;
    width: 277px;
    height: 34px;
    position: relative;
    top: 7px;
}
.info-back-checkout .input-checkbox,
.info-back-checkout .input-checkbox:checked {
    border: none;
}
#button-payment-disabled i.fa.fa-lock,
#step1 .fa-lock:before {
    display: none;
}
.shippingS {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
    letter-spacing: 0.025em;
}
.login-panel-bg .login-section {
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.container_panel {
    box-shadow: none;
    border-radius: 0;
}
.container_panel .heading,
.panel-address .panel-address-body .address-list {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
#addressPage .text-justified {
    border-bottom: none;
}
.container_panel .shipping-table,
.container_panel textarea {
    border-radius: 0;
}
.lborder {
    border-color: #000000;
}
.inlineaddress {
    box-shadow: none;
    border: 1px solid #ddd;
}
.panel-address .panel-address-body .address-list label.address-label.selected {
    color: #000000;
    border-left: 4px solid #000000;
}
#form_edit_address .progress-continue-disabled {
    min-height: 46px;
}
.editcart.minus,
.editcart.xwishlist {
    border-radius: 0;
}
.editcart.minus:hover,
.editcart.plus:hover,
.editcart.xremove:hover,
.editcart.xwishlist:hover {
    background: #000;
    border-color: #000;
}
#nprogress .bar {
    background: #000;
}
.checkout-page input:-webkit-autofill + label,
.checkout-page input:focus + label,
.checkout-page input:valid + label {
    color: #000;
    top: -8px !important;
    background: #fff;
}
.panel-address .panel-address-body .address-list,
.panel-address .panel-address-body .address-list label.address-label:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.checkout-left {
    border-right: 1px solid #bcbcbc;
    padding-right: clamp(30px, 4.1667vw, 80px);
}
.checkout-right {
    padding-left: clamp(30px, 4.1667vw, 80px);
}
#d_quickcheckout #d_logo .hidden {
    display: inline-block !important;
}
#d_quickcheckout #d_logo .megamenu-style-dev {
    display: none !important;
}
#d_quickcheckout {
    padding: 0px clamp(30px, 4.1667vw, 80px) clamp(50px, 5.2083vw, 100px);
    max-width: 1800px;
    width: 100%;
}
#d_quickcheckout .section-title {
    margin: 0 0 clamp(100px, 7.8125vw, 150px);
    padding: 0 20px;
}
#d_quickcheckout a {
    color: #000;
    text-decoration: underline;
}
#shipping_method_list,
#shipping_method_list span,
#d_quickcheckout label,
#d_quickcheckout label.control-label,
#d_quickcheckout input[type='text'].form-control,
#d_quickcheckout input[type='password'].form-control,
#d_quickcheckout input[type='email'].form-control,
#d_quickcheckout input[type='tel'].form-control,
#d_quickcheckout textarea.form-control,
#d_quickcheckout select.form-control,
#d_quickcheckout .alert {
    font-size: 1.6rem;
    line-height: 2.4rem;
    letter-spacing: 1px;
}
#d_quickcheckout #shipping_address_form label {
    display: none;
}
#confirm_coupon,
#qc_confirm_order {
    width: 100%;
    height: unset;
    padding: 15px;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.45em;
}
#coupon {
    width: 100%;
    height: unset;
}
#confirm_coupon {
    display: block;
    max-width: 135px;
    width: 100%;
    padding: 18px 30px;
}
#confirm_coupon:hover #qc_confirm_order:hover,
#qc_confirm_order:disabled {
    color: #232323;
    background-color: #fff;
    border-color: #232323;
    transition: linear 0.3s;
}
#d_quickcheckout .text-danger {
    margin: 0 !important;
}
#d_quickcheckout button.decrease,
#d_quickcheckout button.increase {
    // display: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0;
    margin: 5px;
    height: 20px;
    width: 20px;
    transition: all .3s ease-in-out;

    .fa {
        font-size: 10px;
    }
}
#d_quickcheckout button.delete {
    padding: 5px;
    border-radius: 0;
    /* color: #ffffff; */
    text-shadow: unset;
    background-color: unset;
    background-image: none;
    background-repeat: no-repeat;
    border: none;
    border-color: unset;
}
#d_quickcheckout button.delete .fa-times:before {
    content: '';
}
#d_quickcheckout button.delete span {
    display: block;
    width: 12px;
}
#d_quickcheckout .qc-product-qantity {
    max-width: 40px;
    min-height: 35px;
    padding: 0;
}
#d_quickcheckout .panel-body {
    padding: 15px 0;
}
#d_quickcheckout .panel-title,
#payment_view > h2,
#payment_address_heading_heading {
    font-family: $main_font;
    font-weight: 600;
    font-style: normal;
    font-size: 3.2rem;
}

#d_quickcheckout .panel-default > .panel-heading {
    background-color: unset;
    border-radius: 0;
    border: none;
    padding: 10px 0;
}
#d_quickcheckout .panel {
    border-radius: 0;
    border: none;
    background: none;
}
.qc-checkout-product table.table tbody td {
    vertical-align: middle;
}
#payment_address_heading_heading > i {
    display: none;
}
#d_quickcheckout .btn {
    box-shadow: none !important;
    text-shadow: none !important;
}
/* Login / Register */
#login_view .guest-login-register {
    display: flex;
    flex-wrap: wrap;
}
#login_view .guest-login-register div .btn {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.45em;
    text-align: center;
    border: 1px solid #c5c5c5;
    padding: 20px;
    height: 60px;
    margin-bottom: clamp(40px, 3.125vw, 60px);
}
#login_view .guest-login-register div:nth-child(1),
#login_view .guest-login-register div:nth-child(2) {
    flex-basis: 50%;
    border: none;
}
#login_view .guest-login-register div:nth-child(1) .btn:hover,
#login_view .guest-login-register div:nth-child(2) .btn:hover {
    background-color: #d2b976 !important;
    color: #fff !important;
    border: none !important;
}
#login_view .guest-login-register div:nth-child(1) .btn.active,
#login_view .guest-login-register div:nth-child(2) .btn.active {
    background-color: #d2b976 !important;
    color: #fff !important;
    border: none !important;
}
#login_view .guest-login-register div:nth-child(3) {
    flex-basis: 100%;
    width: 100%;
}
#login_view .guest-login-register div:nth-child(3) .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6e6e6 !important;
    padding: clamp(40px, 3.6458vw, 70px) 20px;
    border: none;
    color: #000;
}
#login_view .guest-login-register div:nth-child(3) .btn:hover {
    color: #000 !important;
}
#login_view .guest-login-register div:nth-child(3) .btn br {
    display: none;
}
#d_quickcheckout .login-btn-group .btn {
    padding: 5px;
    font-weight: 400;
    width: 100%;
    font-size: 1.6rem;
    line-height: 2.4rem;
}
#payment_view > p > b {
    font-weight: 500;
}
#d_quickcheckout.container #d_logo {
    padding: clamp(0px, 2.6042vw, 50px) 15px;
}
#d_quickcheckout.container #d_logo a {
    display: inline-block;
    margin: 0 auto;
}
#d_quickcheckout #d_logo {
    display: none;
}
body.checkout-page-template #main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
#cart_view .qc-name a {
    text-decoration: none;
}
#cart_view .table,
#cart_view .table td {
    font-size: 1.4rem;
    border: none;
}
#cart_view .table thead {
    border-bottom: 1px solid #ddd;
}
#cart_view .qc-coupon {
    margin: 0 0 60px;
}
#cart_view .qc-coupon .col-sm-12 {
    padding: 0;
}
#cart_view #coupon {
    min-height: 35px;
}
#d_quickcheckout #cart_view .qc-totals .row {
    margin: 0;
    &:last-child {
        border-top: none;
    }
}
#cart_view .qc-totals label,
#cart_view .qc-totals .text-right {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 5px;
}
#payment_address_form,
#shipping_address_form {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
#payment_address_form label,
#confirm_form label {
    display: none;
}
#confirm_form .form-control:focus {
    border-color: #000;
    box-shadow: none;
}
#payment_address_form > div,
#shipping_address_form > div {
    flex-basis: calc(50% + 15px);
}
#payment_address_email_input,
#payment_address_email_confirm_input,
#payment_address_company_input,
#payment_address_address_1_input,
#payment_address_city_input,
#payment_address_postcode_input,
#payment_address_customer_group_id_input,
#payment_address_custom_field\.account\.1_input {
    flex-basis: calc(100% + 30px) !important;
}
#payment_address_customer_group_id_input .col-xs-12:first-child {
    display: none;
}
#payment_address_customer_group_id_input {
    label {
        display: inline-block;
    }
}
#payment_address_shipping_address_input label,
#payment_address_agree_input label,
#confirm_agree_input label {
    display: flex;
    align-items: center;
}
#confirm_form {
    margin-bottom: clamp(40px, 3.125vw, 60px);
}
#confirm_comment {
    min-height: 140px;
}
#confirm_agree_input label {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
    text-align: left;
}
#payment_address_form .checkbox-input.form-group,
#payment_address_heading_heading {
    flex-basis: 100% !important;
    input {
        margin: 0 10px 0 0;
    }
}
#payment_address_heading_heading {
    margin: 15px 0 15px;
}
#payment_address_heading_heading > hr {
    display: none;
}
#d_quickcheckout #login_model {
    top: 50%;
    height: fit-content;
    transform: translateY(-50%);
}
#payment_address_shipping_address_input label,
#payment_address_agree_input label,
#confirm_agree_input label {
    text-align: left;
}
.checkout-product {
    align-items: center;
    margin-bottom: clamp(30px, 2.0833vw, 40px);
}
.checkout-product .product-img {
    padding: 20px;
    background-color: #e6e6e6;
    max-width: 125px;
    width: 100%;
    margin-right: 30px;
}
.checkout-product .product-img img {
    mix-blend-mode: darken;
}
.order-account-product .product-img {
    background: unset;
}
.checkout-product .product-info {
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.checkout-product .product-name .product-title,
.checkout-product .product-name .product-title a {
    font-family: 'Blacker Pro Display';
    font-size: clamp(2rem, 1.3542vw, 2.6rem);
    font-style: italic;
    font-weight: 200;
    line-height: 143%;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 15px;
    color: #000;
    text-decoration: none !important;
}
.checkout-product .product-name > button {
    display: inline-block;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.45em;
    text-align: left;
    color: #898989;
    text-decoration: underline;
    padding-bottom: 10px;
    border: none;
}
.checkout-product .product-price > p {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: 0.45em;
}
.qc-totals label {
    text-align: left !important;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    text-transform: uppercase;
}
.qc-totals .row {
    font-size: 1.6rem;
    font-style: normal;
    line-height: 2rem;
    text-align: right;
    border-bottom: 1px solid #c4c4c4;
    padding: 20px 0;
}
.qc-totals .row:last-child {
    border: 0;
}
#login_form label {
    display: none;
}
/* Gift */
.checkout-gift-banner {
    border: 1px solid #c5c5c5;
    background-color: #fff;
    margin-bottom: clamp(30px, 2.0833vw, 40px);
}
.checkout-gift-banner .left {
    max-width: 220px;
    width: 100%;
}
.checkout-gift-banner .left img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.checkout-gift-banner .right {
    padding: 45px;
    position: relative;
}
.checkout-gift-banner .right > .main-title {
    margin: 0 0 15px;
}
.checkout-gift-banner .right > p {
    margin: 0 0 35px;
}
.checkout-gift-banner .right .gift-add-to-cart {
    text-decoration: none !important;
}
.checkout-gift-banner .right .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
}
#d_quickcheckout #payment_method .radio label {
    width: unset !important;
}
/* cart page */
#checkout-cart .mini-cart-total {
    font-size: 1.4rem;
    text-align: right;
}
#checkout-cart .flex-row.mini-cart-checkout a {
    padding: 20px;
    max-width: 300px;
}
/* Radio / Checkboxes */
#d_quickcheckout {
    input[type='checkbox'],
    input[type='radio'] {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        margin: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $footer_link;
        border-radius: 50%;
        position: relative;
    }
    input[type='radio'] {
        top: 3px;
    }
    #confirm_agree_input {
        input[type='checkbox'] {
            margin: 0 10px 0 0;
        }
    }
    input[type='checkbox']::before,
    input[type='radio']::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: scale(0) translate(-50%, -50%);
        background-color: $white;
        transform-origin: bottom left;
    }
    input[type='checkbox']::before {
        -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    input[type='radio']::before {
        width: 8px;
        height: 8px;
        background-color: $white;
        border-radius: 50%;
    }
    input[type='checkbox']:checked,
    input[type='radio']:checked {
        background-color: $black;
        border: 2px solid $black;
    }
    input[type='checkbox']:checked::before,
    input[type='radio']:checked::before {
        transform: scale(1) translate(-50%, -50%);
    }
    input[type='checkbox']:disabled,
    input[type='radio']:disabled {
        border-color: $product_gray;
        cursor: not-allowed;
    }
    .radio label,
    .radio-inline,
    .radio-input {
        // display: inline-flex;
        // width: 100%;
        // align-items: center;
        padding: 0;
        input {
            margin: 0 10px 0 0;
        }
        label {
            margin: 0;
        }
    }
}
@media (max-width: 1440px) {
    #login_view .guest-login-register div:nth-child(3) .btn br {
        display: block;
    }
}
@media (max-width: 991px) {
    #d_quickcheckout.container #d_logo {
        padding: 15px 15px clamp(30px, 5.2083vw, 100px) 15px;
    }
    .checkout-left {
        border: none;
        padding: 0;
    }
    .checkout-right {
        padding: 0;
    }
    .checkout-product .product-img {
        padding: 10px;
        max-width: 100px;
        margin-right: 20px;
    }
    .checkout-product .product-name > h4 {
        margin: 0 0 10px;
        font-size: 2rem;
    }
}
@media (max-width: 767px) {
    #payment_address_form > div,
    #shipping_address_form > div {
        flex-basis: 100%;
    }
    #payment_address_form > div > div,
    #shipping_address_form > div > div,
    #confirm_comment_input > div,
    #confirm_agree_input > div {
        padding: 0;
    }
    .form-horizontal .form-group {
        margin-right: 0;
        margin-left: 0;
    }
    #d_quickcheckout .panel-body,
    #d_quickcheckout .panel-default > .panel-heading,
    #payment_view {
        padding: 10px;
    }
    .checkout-gift-banner .right .close-btn {
        top: 10px;
        right: 10px;
    }
}
@media (max-width: 660px) {
    #shipping_method_list,
    #shipping_method_list span,
    #d_quickcheckout label,
    #d_quickcheckout label.control-label,
    #d_quickcheckout input[type='text'].form-control,
    #d_quickcheckout input[type='password'].form-control,
    #d_quickcheckout input[type='email'].form-control,
    #d_quickcheckout input[type='tel'].form-control,
    #d_quickcheckout textarea.form-control,
    #d_quickcheckout select.form-control,
    #d_quickcheckout .alert,
    #confirm_coupon,
    #qc_confirm_order {
        font-size: 1.4rem;
    }
    #cart_view .table,
    #cart_view .table td {
        font-size: 1.2rem;
    }
    #d_quickcheckout input[type='radio'],
    #d_quickcheckout input[type='checkbox'] {
        width: 16px;
        height: 16px;
        max-width: 16px;
    }
    #d_quickcheckout input[type='radio']:checked::before,
    #d_quickcheckout input[type='checkbox']:checked::before {
        background-size: 3px 3px;
        width: 6px;
        height: 6px;
    }
    .radio input[type='radio'],
    .checkbox input[type='checkbox'],
    .checkbox-inline input[type='checkbox'],
    .radio input[type='radio'],
    .radio-inline input[type='radio'],
    input[type='checkbox'] {
        margin: 0 10px 0 0;
    }
    // .form-horizontal .checkbox,
    // .form-horizontal .checkbox-inline,
    // .form-horizontal .radio,
    // .form-horizontal .radio-inline,
    // #shipping_method .radio-input,
    // #payment_method .radio-input {
    //     padding: 20px 10px;
    // }
    #d_quickcheckout label.control-label span {
        display: block;
        flex-basis: 80%;
    }
    .checkout-gift-banner {
        flex-direction: column;
    }
    .checkout-gift-banner .left {
        max-width: unset;
    }
    .checkout-gift-banner .right {
        padding: 25px;
    }
    #login_view .guest-login-register div .btn {
        font-size: 1.4rem;
    }
    #login_view .guest-login-register div:nth-child(1),
    #login_view .guest-login-register div:nth-child(2) {
        flex-basis: 50%;
    }
    #confirm_coupon {
        padding: 20px;
        height: 60px;
    }
    #coupon {
        height: 60px;
    }
    #confirm_agree_input {
        span {
            display: inline;
        }
        .control-label::after {
            content: '';
        }
    }
}
