$black: #000;
$white: #fff;
$gray: #DFDFDF;
$light_gray: #F2F2F2;
$dark_gray: #1B1B1B;
$product_gray: #EAEAEA;
$footer_link: #818181;
$yellow: #FFECC3;
$gold: #D3B675;



$secondary_01: #9B775D;
$secondary_01_dark: #805D45;
$secondary_02: #9B8B67;
$main: #FFECC3;
$main_darker: $gold;

$navarino: #7B7161;
$navarino_darker: #6A6151;
$about_bg: $yellow;
$navarino_partners: #DECFB6;
$trea: #1B1B1B;
$recipes_bg: $secondary_01;

//breakpoints
$mobile: 440px;
$tablet-small: 768px;
$tablet: 1024px;
$laptop-small: 1200px;
$laptop: 1500px;