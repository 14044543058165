/*========================================================*/
/*** Modals ***/
/*========================================================*/
body.modal-open {
    overflow: hidden;
}
.modal-title {
    font-family: $main_font;
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 143.3%;
    letter-spacing: 0;
    text-transform: capitalize;
}
.modal-body h2,
.modal-body h3,
.modal-body h4 {
    font-family: $main_font;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 143.3%;
    letter-spacing: 0;
}
.modal-header {
    position: relative;
}
.modal-header .close {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
}
.modal-content {
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
}
.modal-body #remeber_password {
    display: inline-block;
    font-size: 1.4rem;
    margin: 10px 0 0;
}
/*========================================================*/
/*** Buttons ***/
/*========================================================*/
.btn-default {
    background-color: $dark_gray;
}
.btn-default:hover {
    color: $white;
    background-color: $dark_gray;
    border-color: $dark_gray;
    transition: linear 0.3s;
}
.btn-default.reverse {
    color: $white;
    background-color: $dark_gray;
}
.btn-default.reverse:hover {
    background-color: transparent;
    color: $black;
}
/*========================================================*/
/*** Account ***/
/*========================================================*/
.common-account {
    #d_social_login {
        display: none;
    }
}
.common-account,
#account-login {
    padding-top: 50px;
    padding-bottom: 50px;
    .section_serif_title {
        font-family: $main_font;
        font-weight: 600;
        font-style: normal;
        font-size: 3.2rem;
    }
    input[type='checkbox'],
    input[type='radio'] {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        margin: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $footer_link;
        border-radius: 50%;
        position: relative;
    }

    input[type='checkbox']::before,
    input[type='radio']::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        transform: scale(0) translate(-50%, -50%);
        background-color: $white;
        transform-origin: bottom left;
    }
    input[type='checkbox']::before {
        -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    input[type='radio']::before {
        width: 8px;
        height: 8px;
        background-color: $white;
        border-radius: 50%;
    }
    input[type='checkbox']:checked,
    input[type='radio']:checked {
        background-color: $black;
        border: 2px solid $black;
    }
    input[type='checkbox']:checked::before,
    input[type='radio']:checked::before {
        transform: scale(1) translate(-50%, -50%);
    }
    input[type='checkbox']:disabled,
    input[type='radio']:disabled {
        border-color: $product_gray;
        cursor: not-allowed;
    }
    .radio label,
    .radio-inline {
        font-size: 1.4rem;
        display: inline-flex;
        align-items: center;
        padding: 0;
        input {
            margin: 0 10px 0 0;
        }
    }
}
#account-login .breadcrumb {
    display: none;
}
#account-login #content {
    padding-top: 80px;
}
#account-login .well {
    padding: 20px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.well.new-customer {
    text-align: center;
}
.well.new-customer-form:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
}
#account-login .new-customer-form h2 {
    text-align: center;
}
#account-login .well p {
    margin-bottom: 30px;
}
#account-login .register-btn {
    width: 100%;
}
#account-login .register-btn a.btn:hover {
    color: #000;
    background-color: #fff;
    transition: linear 0.3s;
}
#account-login .btn-default {
    width: 100%;
}
#account-login a.forgotten-pass {
    display: block;
    margin-top: 5px;
    color: #000;
    font-size: 1.4rem;
    font-weight: 300;
}
#account-login label {
    font-size: 1.3rem;
    line-height: 2.4rem;
    letter-spacing: 0.025em;
    font-weight: 400;
}
.common-account .breadcrumb {
    display: none;
}
.container.common-account.small-container {
    max-width: 500px;
}
.container.common-account {
    padding-bottom: 100px;
    float: none;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}
#account-order {
    max-width: 900px;
}
#account-order .btn-default {
    max-width: 600px;
    margin: 0 auto;
}
#account-order thead > tr > td {
    font-size: 1.6rem;
}
#account-order tbody > tr > td {
    font-size: 1.4rem;
}
.common-account legend,
.search-container legend {
    display: none;
    font-size: 1.4rem;
    letter-spacing: 0.025em;
    font-weight: 500;
    color: #000;
    border-bottom: none;
    text-transform: uppercase;
}
.common-account ul > li > a {
    font-size: 1.6rem;
    line-height: 3rem;
    letter-spacing: 0.025em;
    font-weight: 400;
    text-decoration: none;
}
.common-account .agree-policy {
    align-items: center;
    margin: 0 0 15px;
    input {
        margin: 0 10px 0 0;
    }
}
.common-account .agree-policy > p {
    margin: 0;
}
.common-account .default-address,
.common-account .register-newsletter {
    display: block !important;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 1.6rem;
}
.common-account .btn-default {
    display: block;
    width: 100%;
}
.common-account a {
    color: #000000;
    text-decoration: underline;
}
.common-account .btn-default {
    color: #ffffff;
    text-decoration: none;
}
#account-login .main-title,
.common-account .main-title {
    margin-bottom: 20px;
}
.common-account .table-bordered,
.common-account .table-bordered > tbody > tr > td,
.common-account .table-bordered > tbody > tr > th,
.common-account .table-bordered > tfoot > tr > td,
.common-account .table-bordered > tfoot > tr > th,
.common-account .table-bordered > thead > tr > td,
.common-account .table-bordered > thead > tr > th {
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 20px 15px;
}
.common-account .buttons {
    margin-top: 10px;
}
.common-account td.text-left a {
    color: #000;
    text-decoration: none;
}
.common-account td.text-right > a {
    text-decoration: none;
}
span.add-to-cart-icon svg,
span.add-to-cart-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.common-account .remove-item-icon svg .st0 {
    fill: none;
    stroke: #000000;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}
.common-account .remove-item-icon svg,
.common-account .remove-item-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
}
.common-account a.btn-primary {
    padding: 10px 20px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    letter-spacing: 0.025em;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 0;
    background-image: none;
    text-transform: uppercase;
    width: 50%;
    transition: linear 0.3s;
    min-height: 45px;
    text-decoration: none;
}
.common-account a.btn-primary:hover {
    color: #000;
    background-color: #fff;
    border-color: #000;
    transition: linear 0.3s;
}
.common-account .col-sm-2.control-label {
    //display: none;
    width: 100%;
    text-align: left;
}
.common-account .col-sm-10 {
    display: block;
    width: 100%;
}
.common-account thead > tr > td {
    font-size: 1.4rem;
    letter-spacing: 0.025em;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}
.common-account tbody > tr > td {
    font-size: 1.3rem;
    font-weight: 400;
    color: #000;
}
.common-account .table-hover > tbody > tr {
    opacity: 0.7;
    background-color: transparent;
}
.common-account .table-hover > tbody > tr:hover {
    opacity: 1;
}
.common-account td.text-right > a.edit-address-btn,
.common-account td.text-right > a.remove-address-btn,
.common-account td.text-right > a.btn-view-item {
    font-size: 1.1rem;
    line-height: initial;
    font-weight: 600;
    letter-spacing: 0.025em;
    color: #000;
    border: none;
    border-radius: 0;
    background-image: none;
    text-transform: uppercase;
    text-decoration: underline;
}
.alert-success {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000;
    font-size: 1.3rem;
    line-height: initial;
    font-weight: 400;
    border-radius: 0;
    text-align: center;
}
.input-group.date button.btn.btn-default {
    padding: 9px 20px;
}
label.col-sm-3.control-label.newsletter-text,
label.col-sm-4.control-label.newsletter-text {
    text-align: left;
}
.agree-text {
    margin-bottom: 20px;
}
