.contact_page {
    p {
        margin: 0;
        padding: 0;
    }
    .gridy_contact {
        display: grid;
        grid-template-columns: 2fr 3fr;
    }
    /*get in touch*/
    .get_in_touch {
        max-width: 800px;
        padding-top: 275px;
        padding-bottom: 375px;
        background-color: $yellow;
        display: grid;
        justify-content: center;
    }

    .get_in_touch .main_button {
        font-size: 1.4rem;
        line-height: 2rem;
        padding-top: 80px;
    }
    .get_in_touch .main_button .address_contact {
        display: block;
        padding-bottom: 30px;
        max-width: 200px;
    }
    .get_in_touch .tel_contact {
        display: block;
        max-width: 375px;
    }
    .get_in_touch .section_serif_title {
        font-weight: 400;
        display: block;
        max-width: 150px;
    }

    /*contact form*/

    .contact_form {
        max-width: 1115px;
        margin: 0 auto;
        padding-top: 270px;
        padding-bottom: 370px;
        background-color: $white;
    }

    .contact_form input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $black;
        width: 315px;
        padding-left: 25px;
        padding-bottom: 20px;
        border-radius: 0;
        box-shadow: none;
    }
    .contact_form input:focus {
        border-bottom: 2px solid $black;
        margin-bottom: -1px;
    }
    .contact_form textarea:focus {
        border-bottom: 2px solid $black;
        margin-bottom: -1px;
    }

    .gridy_contact_form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-width: 715px;
        grid-gap: 40px;
    }
    .contact_form textarea {
        grid-column-start: 1;
        grid-column-end: 3;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $black;
        padding: 0px 0px 70px 20px;
        resize: none;
        border-radius: 0;
        box-shadow: none;
        width: 690px;
    }

    ::placeholder {
        opacity: 0.5;
    }

    .terms_and_submit {
        display: grid;
        grid-template-columns: max-content max-content max-content;
        padding-top: 30px;
    }
    .terms_and_submit input[type='checkbox'] {
        width: 25px;
        height: 25px;
        margin-top: -1.5px;
        border-radius: 50%;
        border: 1px solid $black;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        pointer-events: auto;
    }

    input[type='checkbox']:checked {
        background-color: $black;
    }
    .section_cta {
        display: flex;
        justify-content: end;
        background: none;
        color: inherit;
        border: none;
        padding-top: 10px;
        padding-left: 0;
        padding-right: 15px;
        padding-bottom: 0;
    }

    .terms_and_submit .submit_contact {
        padding-left: 210px;
    }

    .terms_and_submit .main_text {
        margin-left: 10px;
    }
    .email-underl {
        text-decoration: underline;
    }
    .form-group {
        margin: 0;
    }
    .gridy_form_item {
        width: 335px;
    }
    .textarea_danger {
        margin-top: -145px;
    }

    /*========================================================*/
    /*** CONTACT ***/
    /*========================================================*/
    #information-contact .breadcrumb {
        display: none;
    }
    #information-contact #content {
        padding-bottom: 70px;
    }
    #information-contact h1 {
        text-align: center;
        font-size: 2.4rem;
        line-height: initial;
        letter-spacing: 0.025em;
        font-weight: 600;
        color: #000;
        margin: 0;
        padding: 35px 0 30px;
    }
    #information-contact h3 {
        font-size: 1.4rem;
        line-height: initial;
        letter-spacing: 0.025em;
        font-weight: 600;
        color: #000;
        margin: 0;
        text-transform: uppercase;
    }
    #information-contact form h3 {
        padding-bottom: 20px;
    }
    ul.contact-info > li,
    ul.contact-info > li > a {
        font-size: 1.3rem;
        line-height: 2.4rem;
        letter-spacing: 0.025em;
        font-weight: 500;
        color: #838383;
    }
    ul.contact-info > li > a.email-address {
        color: #000;
        text-decoration: underline;
    }
    #information-contact .stores-info {
        padding-top: 50px;
    }
    #information-contact .addresses {
        padding-bottom: 40px;
    }
    @media only screen and (max-width: 1280px) {
        .contact_form {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    @media only screen and (max-width: 1028px) {
        .get_in_touch .main_button {
            padding-top: 50px;
        }
        .gridy_contact {
            grid-template-columns: 1fr;
        } 
        .get_in_touch {
            max-width: none;
            padding-top: 150px;
            padding-bottom: 150px;
        }
        .contact_form {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    @media only screen and (max-width: 767px) {
     
        
        .textarea_danger {
            margin-top: -270px;
        }
        .contact_form input:hover {
            border-bottom: 2px solid $black;
            margin-bottom: 0;
        }
        .contact_form textarea:hover {
            border-bottom: 2px solid $black;
            margin-bottom: 0;
        }
        .contact_form input {
            padding-top: 20px;
        }
        .contact_form textarea {
            padding-top: 20px;
        }
        .terms_and_submit .main_text {
            margin-left: 30px;
            margin-top: -24px;
        }
        .contact_form {
            padding-top: 80px;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
        }
        .gridy_contact {
            grid-template-columns: 1fr;
        }

        .get_in_touch .main_button {
            padding-top: 60px;
        }
        .get_in_touch {
            padding: 100px 0 90px 0;
            text-align: center;
        }
        .get_in_touch .main_button .address_contact {
            display: block;
            padding-bottom: 30px;
            max-width: 375px;
            padding-left: 85px;
            padding-right: 85px;
        }
        .get_in_touch .section_serif_title {
            max-width: none;
        }
        .get_in_touch .section_serif_title {
            display: inline;
        }
        
        .gridy_contact_form,
        .contact_form textarea {
            grid-template-columns: 1fr;
            max-width: 315px;
            grid-gap: 0;
        }
        .contact_form textarea {
            grid-column-start: 1;
            grid-column-end: 1;
            padding: 20px 0px 10px 20px;
        }
        .terms_and_submit {
            grid-template-columns: 1fr;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .terms_and_submit .radio_terms {
            text-align: left;
        }
        .terms_and_submit .submit_contact {
            padding-left: 0px;
            text-align: center;
            padding-top: 40px;
        }
        .section_cta {
            display: flex;
            justify-content: center;
            padding-bottom: 80px;
        } 
        .terms_and_submit input[type='checkbox'] {
           padding: 0px;
           pointer-events: auto;
        }
       
      
    } 
   
}
